
export default {
  data() {
    return {
      accessDetails: null,
      onloader: "none",
    };
  },
  methods: {
    ValidateToken() {
      this.onloader = "flex";
      this.$store
        .dispatch("auth/ValidateToken")
        .then(resp => {        
          if (resp.responseCode == '00') {
            const userDetails = resp.data;
            this.accessDetails = userDetails;             
            this.$store.dispatch("auth/MutateUsername",userDetails.userName);
            this.onloader = "none";
          } else {
            this.onloader = "none";
            this.$store.dispatch("auth/Logout");
            this.$router.push("/login");
          }
        })
        .catch(() => {
          this.onloader = "none";
          this.$store.dispatch("auth/Logout");
          this.$router.push("/login");
        });
    },
  },
  mounted() {
    this.ValidateToken()
  }
}