<template>
  <div v-if="accessDetails">
    <Staffportal
      :userName="accessDetails.fullName"
      :userPhoneNo="accessDetails.phoneNumber"
      :userEmailAddress=accessDetails.emailAddress
      v-if="accessDetails.isSchoolStaff == true"
    ></Staffportal>
    <Studentportal
    :userName="accessDetails.fullName"
      :userPhoneNo="accessDetails.phoneNumber"
      :userEmailAddress=accessDetails.emailAddress
      v-if="
        accessDetails.isSchoolStudent == true ||
          accessDetails.isStudentGuardian == true
      "
    ></Studentportal>
    <Adminportal
    :userName="accessDetails.fullName"
      :userPhoneNo="accessDetails.phoneNumber"
      :userEmailAddress=accessDetails.emailAddress
      v-if="
        accessDetails.isSuperAdmin == true ||
          accessDetails.isAdmin == true ||
          accessDetails.isSupportAdmin == true ||
           accessDetails.isAffliateMarketer == true
      "
    ></Adminportal>
    
  </div>
</template>
<script>
import Staffportal from "./Dashboardcomponent/Staffportal";
import Studentportal from "./Dashboardcomponent/Studentportal";
import Adminportal from "./Dashboardcomponent/Adminportal";
export default { 
  components: {
    Staffportal,
    Studentportal,
    Adminportal,
  },  
  data() {
      return {
        onloader: "none",
        accessDetails:null,       
      };
    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
    },
};
</script>
<style></style>
