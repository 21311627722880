<template v-if="accessDetails">
    <div v-if="staffDetails">
        <div class="row" v-if="accessDetails.isSchoolStaff==true">
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isRegistrar == true">
                <router-link to="/school/registrar">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-id-card"></i>
                            </div>
                            <h5 class="card-title text-muted text-white mb-0">Registrar</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink">
                <router-link to="/school/academic">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-chalkboard-teacher"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Academic</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink">
                <router-link to="/school/student-record">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-clipboard"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Student Record</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isHostelMaster == true">
                <router-link to="/school/hostel">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-bed"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Hostel</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isLibraryAdmin == true">
                <router-link to="/school/library">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-book-reader"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Library</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isBursal == true">
                <router-link to="/school/finance">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-cash-register"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Finance</h5>

                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isPortalAdmin == true">
                <router-link to="/school/notifications">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-bell"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Notification</h5>

                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink">
                <router-link to="/school/notifications">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fab fa-rocketchat"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Chat</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isPortalAdmin == true">
                <router-link to="/school/sms-sender">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fas fa-sms"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">SMS</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isPortalAdmin == true">
                <router-link to="/school/website-setup">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="ni ni-pin-3"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Website</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink">
                <router-link to="/school/report">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-chart-bar"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Report</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isPortalAdmin == true">
                <router-link to="/school/environment-settings">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-sliders-h"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Setting</h5>

                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isPortalAdmin == true">
                <router-link to="/school/subscription">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-money-check"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Subscription</h5>

                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink">
                <router-link to="/user-profile">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-user"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Profile</h5>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-xs-3 m-2 shrink">
                <router-link to="/dashboard">
                    <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                            <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                <i class="fa fa-question"></i>
                            </div>
                            <h5 class="card-title text-muted mb-0 text-white">Help</h5>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            accessDetails: null,
            staffDetails: null,
            onloader: "none",
        };
    },   
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        this.staffDetails = this.$store.getters['SchoolStaff/_schoolStaff'];
    },
};
</script>