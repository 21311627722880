<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <card header-classes="bg-transparent">
            <div class="row">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-header">
                            <h4>Full Term Attendance</h4>
                        </div>
                        <div class="card-body bg-secondary">
                            <div class="mb-2">
                                <label>Sesssion</label>
                                <select v-model="SessionIdval" class="form-control" @change="getregterm()">
                                    <option value="0">Select Session</option>
                                    <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                        {{ item.SchSession }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-2" v-if="SessionIdval > 0">
                                <label>Term</label>
                                <select v-model="TermIdval" class="form-control" @change="getstudents()">
                                    <option value="0">Select Term</option>
                                    <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                        {{ item.SchTerm }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-2" v-if="TermIdval > 0">
                                <div class="form-group">
                                    <label>Class</label>
                                    <select v-model="ClassIdval" @change="getoption()" class="form-control">
                                        <option value="0"></option>
                                        <option v-for="item in schclass" :key="item.Id" v-bind:value="item.Id">
                                            {{ item.Sch_Class }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2" v-if="ClassIdval > 0">
                                <div class="form-group">
                                    <label>Option</label>
                                    <select v-model="OptionIdval" @change="getarm()" class="form-control">
                                        <option value="0"></option>
                                        <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                            {{ item.ClassOption }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2" v-if="OptionIdval > 0">
                                <div class="form-group">
                                    <label>Arm</label>
                                    <select v-model="ArmIdval" class="form-control" @change="getstudents()">
                                        <option value="0"></option>
                                        <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                                            {{ item.SectionName }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2" v-if="TermIdval > 0">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="btn btn-secondary"><i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                    <input v-model="searchval" class="form-control" placeholder="Search here..." />
                                </div>
                                <div v-for="item in searchedResultQuery" :key="item.Id">
                                    <button class="btn btn-secondary btn-block shrink my-2"
                                        v-on:click="getreport(item.Id)">
                                        <div class="row">
                                            <div class="col-sm-4 text-center">
                                                <img v-if="item.Passport" :src="item.Passport" class="rounded" alt=""
                                                    height="30" />
                                                <img v-else src="img/brand/loguser.png" class="rounded" alt=""
                                                    height="30" />
                                            </div>
                                            <div class="col-sm-8 text-left">
                                                <h5><small> {{ item.StudentName }}</small> </h5>
                                                <h6><small>Current Class: {{ item.Sch_Class }}</small> </h6>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div v-if="reportcard != null">
                        <div id="printContainer">
                            <div v-html="reportcard"></div>
                        </div>
                        <div v-if="isopencomment">
                            <div class="card  bg-secondary-gradient my-3">
                                <div class=" card-body">
                                    <label>Head Teacher Comment</label>
                                    <textarea v-model="headteachercommval" class="form-control"
                                        placeholder="Enter Comment"></textarea>
                                    <div class="text-center card-footer my-3">
                                        <button class="btn btn-secondary" @click.prevent="closecomment()">
                                            Close
                                        </button>
                                        <button class="btn btn-default" @click.prevent="processheadteacherrepcomment()">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="isopenclassteachercomment">
                            <div class="card bg-secondary-gradient my-3">
                                <div class=" card-body">
                                    <label>Class Teacher Comment</label>
                                    <textarea v-model="classteachercommval" class="form-control"
                                        placeholder="Enter Comment"></textarea>
                                    <div class="text-center card-footer my-3">
                                        <button class="btn btn-secondary" @click.prevent="closeclassteachercomment()">
                                            Close
                                        </button>
                                        <button class="btn btn-default"
                                            @click.prevent="processclassteacherrepcomment()">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center my-3">
                            <button class="btn btn-default" @click.prevent="opencomment()">
                                <i class="ni ni-chat-round"></i> Head Teacher Comment
                            </button>
                            <button class="btn btn-default" @click.prevent="openclassteachercomment()">
                                <i class="ni ni-chat-round"></i> Class Teacher Comment
                            </button>
                            <button class="btn btn-default" @click.prevent="printreport()">
                                <i class="fa fa-print"></i> Print
                            </button>
                            <button class="btn btn-default" @click.prevent="sendreport()">
                                <i class="fa fa-print"></i> Send to Guardian
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'classattendance',
    components: {
        PageSpinner
    },
    data() {
        return {
            dataid: 1,
            SessionIdval: "",
            TermIdval: "",
            StudIdval: "",
            ClassIdval: 0,
            OptionIdval: 0,
            ArmIdval: 0,
            SectionIdval: 0,
            schclass: null,
            optionitems: null,
            armitems: null,
            headteacherval: "",
            headteachercommval: "",
            dateheadteachercomment: null,
            classteacherval: "",
            classteachercommval: "",
            dateclassteachercomment: null,
            gradeimagepath: "img/brand/mark.png",
            Sessionitems: null,
            termitems: null,
            studentitems: null,
            schooldetailsitem: null,
            reportheaderrep: null,
            studrep: null,
            reportcard: null,
            currdate: null,
            searchval: "",
            process: "N",
            onloader: "none",
            isopencomment: false,
            isopenclassteachercomment: false
        };
    },
    methods: {
        opencomment() {
            this.isopencomment = true;
        },
        closecomment() {
            this.isopencomment = false;
        },
        openclassteachercomment() {
            this.isopenclassteachercomment = true;
        },
        closeclassteachercomment() {
            this.isopenclassteachercomment = false;
        },
        processheadteacherrepcomment() {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let Comment = this.headteachercommval;
           let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                Comment,
                username,
                accesskey
            };
            this.onloader = "flex";
            this.$store
                .dispatch("processheadteacherrepcomment", userdata)
                .then(() => {
                    this.getreport(StudId);
                    this.closecomment();
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: "Submitted successfully",
                        icon: "success",
                        button: "Ok"
                    });
                })
                .catch(err => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        processclassteacherrepcomment() {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let Comment = this.classteachercommval;
           let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                Comment,
                username,
                accesskey
            };
            this.onloader = "flex";
            this.$store
                .dispatch("processclassteacherrepcomment", userdata)
                .then(() => {
                    this.getreport(StudId);
                    this.closeclassteachercomment();
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: "Submitted successfully",
                        icon: "success",
                        button: "Ok"
                    });
                })
                .catch(err => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        sendreport() {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
           let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                username,
                accesskey
            };
            this.onloader = "flex";
            this.$store
                .dispatch("sendreport", userdata)
                .then(() => {
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: "Sent successfully",
                        icon: "success",
                        button: "Ok"
                    });
                })
                .catch(err => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getcurendate() {
            var today = new Date();
            this.currdate =
                today.getDate() +
                "/" +
                (today.getMonth() + 1) +
                "/" +
                today.getFullYear();
        },
        async printreport() {
            // Get HTML to print from element
            await this.$htmlToPaper('printContainer');
        },
        getreport(Id) {
            this.reportcard = null;
            this.StudIdval = Id;
            let StudId = Id;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                username,
                accesskey
            };
            this.onloader = "flex";
            this.$store
                .dispatch("getreport", userdata)
                .then(resp => {
                    this.reportcard = resp;
                    this.onloader = "none";
                })
                .catch(err => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getregsession() {
            this.reportcard = null;
            this.termitems = null;
            this.Sessionitems = null;
            this.SessionIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("getregsession")
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = "none";
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = "none";
                    this.$store.dispatch("logout");
                    this.$router.push("/home");
                });
        },
        getregterm() {
            this.reportcard = null;
            this.termitems = null;
            this.TermIdval = 0;
            let SessionId = this.SessionIdval;
            if (SessionId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("getregterm", SessionId)
                    .then(resp => {
                        this.termitems = resp;
                        this.onloader = "none";
                    })
                    .catch(err => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getclass: function () {
            this.$store
                .dispatch("getclass")
                .then((resp) => {
                    this.schclass = resp;
                })
                .catch((err) => {
                    this.checkresponse(err.response.data);
                });
        },
        getoption: function () {
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("getoptionbyclass", ClassId)
                    .then((resp) => {
                        this.optionitems = resp;
                        this.getstudents();
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getarm: function () {
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let SchArm = "Search";
            let Id = 1;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            this.onloader = "flex";
            let payload = {
                Id,
                ClassId,
                OptionId,
                SchArm,
                processop,
                username,
                accesskey,
            };
            this.$store
                .dispatch("getarmbyoption", payload)
                .then((resp) => {
                    this.armitems = resp;
                    this.getstudents();
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getstudents() {
            this.reportcard = null;
            this.studentitems = null;

            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let ClassId = this.ClassIdval;
            let SectionId = this.SectionIdval
            let userData = {
                SessionId, TermId, ClassId, SectionId
            }
            if (this.TermIdval > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("GetStudentForReportCard", userData)
                    .then(resp => {
                        this.studentitems = resp;
                        this.onloader = "none";
                    })
                    .catch(err => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getstudentbyid() {
            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("getstudentbyid", StudId)
                    .then(resp => {
                        this.studrep = resp;
                        this.onloader = "none";
                    })
                    .catch(err => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        frontEndDateFormat(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
        checkresponse(resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok"
                });
            }
        }
    },
    computed: {
        searchedResultQuery() {
            if (this.searchval) {
                return this.studentitems.filter(item => {
                    return this.searchval
                        .toLowerCase()
                        .split(" ")
                        .every(
                            v =>
                                item.StudentName.toLowerCase().includes(v) ||
                                item.Sch_Class.toLowerCase().includes(v)
                        );
                });
            } else {
                return this.studentitems;
            }
        }
    },
    mounted() {
        this.getregsession();
        this.getcurendate();
        this.getclass();
    }
};
</script>

<style>
.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 11pt;
    width: 100%;
}

.bodytext {
    font-size: 11pt;
}
</style>

