<template>
    <div>
        <div class="justify-content-end form-inline my-3">
            <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
                v-model="searchval">
            </base-input>
        </div>
        <div class="table-responsive" v-if="ReportItems">
            <table class="table table-dark table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Created By</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in ReportItems" :key="item.Id">

                        <td>{{ item.generatedReportFileTitle }}</td>
                        <td>{{ item.generatedReportFileStatus }}</td>
                        <td>{{ item.createdByUserName }}</td>
                        <td>{{ frontEndDateFormat(item.dateCreated) }}</td>
                        <td>
                            <button class="btn btn-secondary btn-sm"
                                v-on:click="downloadreport(item.generatedReportFilePath)">
                                <i class="fa fa-file-excel"></i> Download
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr>
        <div class="text-center">
            <button class="btn-success m-1" @click="prevPage" :disabled="currentPage === 1">Previous</button>
            <span v-for="pageNumber in paginationNumbers" :key="pageNumber">
                <button class="btn-success m-1" v-if="pageNumber === '...'" disabled>...</button>
                <button class="btn-success m-1" v-else @click="goToPage(pageNumber)"
                    :class="{ 'active': pageNumber === currentPage }">{{ pageNumber
                    }}</button>
            </span>
            <button class="btn-success m-1" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "Report",
    data() {
        return {
            ReportItems: [],
            currentPage: 1,
            pageSize: 10,
            pageTotal: 10,
            searchval: '',
            onloader: "none",
        };
    },
    methods: {
        GetReportGenerated() {
            this.onloader = "flex";
            let PageNumber = this.currentPage
            let PageSize = this.pageSize
            let SearchValue = this.searchval
            if (SearchValue === '') {
                SearchValue = 'all'
            }
            let userdata = {
                PageNumber,
                PageSize,
                SearchValue
            }
            this.$store
                .dispatch("GetAllGeneratedReportFile", userdata)
                .then((resp) => {                   
                    this.ReportItems = resp.data;
                    this.pageTotal = resp.totalPages;
                    this.onloader = "none";
                })
                .catch((err) => {
                    console.log(err);
                    this.onloader = "none";
                });
        },
        downloadreport(filePath) {
            if (filePath != null) {
                window.open(filePath, "_blank");
            }
        },
        async goToPage(pageNumber) {
            if (pageNumber !== '...') {
                this.currentPage = pageNumber;
                await this.GetReportGenerated();
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.GetReportGenerated();
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.GetReportGenerated();
            }
        },
        frontEndDateFormat(date) {
            return moment(date).format("DD-MM-YYYY");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
    },
    computed: {
        totalPages() {
            // Assuming totalPages is fetched from the server
            return this.pageTotal;
        },
        paginationNumbers() {
            const paginationRange = 3; // Adjust as needed
            const startPage = Math.max(1, this.currentPage - paginationRange);
            const endPage = Math.min(this.totalPages, this.currentPage + paginationRange);

            const paginationNumbers = [];
            for (let i = startPage; i <= endPage; i++) {
                paginationNumbers.push(i);
            }

            if (startPage > 1) {
                paginationNumbers.unshift('...');
            }
            if (endPage < this.totalPages) {
                paginationNumbers.push('...');
            }

            return paginationNumbers;
        }
    },
    mounted() {
        setInterval(() => {
            // Refresh component logic here
            this.GetReportGenerated();
        }, 5000); // Refresh every 5 seconds      

    },
};
</script>

<style>
.active {
    font-weight: bold;
}

.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 9pt;
    width: 100%;
}

.bodytext {
    font-size: 9pt;
}
</style>