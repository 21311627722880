import swal from "sweetalert";
export default {
    methods: {
        checkresponse (resp) {
          swal({
            title: "Oops!",
            text: resp,
            icon: "error",
            button: "Ok",
          });
          },
    },    
  }