<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link><i class="ni ni-bold-right text-white"></i>
            <router-link to="/school/sms-sender" class="text-white">
                <i class="ni ni-bold-right"></i> SMS
            </router-link>
            <span class="text-white"><i class="ni ni-bold-right"></i> Track SMS</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <card header-classes="bg-transparent">
                <div class="form-inline">
                    <div class=" input-group">
                        <input v-model="searchval" class="form-control" placeholder="Search...">
                        <div class=" input-group-append">
                            <button type="button" class="btn btn-secondary"><i class="fa fa-search"></i> Search
                            </button>
                        </div>
                    </div>

                </div>
                <div class="mt-2">
                    <div v-for="item in SMS" :key="item.Id">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title"> <span class="btn btn-success btn-sm">{{ item.SentDate }}</span>
                                </h5>
                                <p> {{ item.smsmessage }}</p>
                            </div>
                            <div class="card-footer">
                                {{ item.smsdetail }}
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>

    </div>
</template>

<script>

import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'Psycomotor',
    components: {
        PageSpinner
    },
    data() {
        return {
            accessDetails: null,
            dataid: 1,
            SMS: null,
            searchval: '',
            process: 'N',
            onloader: 'none'
        }
    },

    methods: {

        tracksms: function () {
            this.onloader = 'flex'
            this.$store.dispatch('tracksms')
                .then((resp) => {
                    this.SMS = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                })
        },
        getsearch: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getconductsearch', this.searchval)
                .then((resp) => {
                    this.conducts = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        } else {
            this.tracksms();
        }

    }
};
</script>

<style></style>
