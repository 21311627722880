<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashboard</router-link
      >
      <router-link to="/school/notifications" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Notification</router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i>Read News</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-8">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="mx-3 my-5" v-if="currentnews">
                    <div>
                      <img
                        v-if="currentnews.ImagePath"
                        :src="
                          $store.state.auth.baseurl +
                          currentnews.ImagePath.substr(1)
                        "
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="text-left">
                      <h1>{{ currentnews.NewsHeading }}</h1>
                      <div v-html="currentnews.NewsBody"></div>
                      <hr />
                      <div v-for="item in comment" :key="item.Id">
                        <div class="row">
                          <div class="col-md-3">
                            <img
                              v-if="item.Passport"
                              :src="
                                $store.state.auth.baseurl +
                                item.Passport.substr(1)
                              "
                              class="img-fluid"
                              alt=""
                            />
                            <img
                              v-else
                              src="img/brand/loguser.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                          <div class="col-md-9">
                            <h6>{{ item.CommentedBy }}</h6>
                            <p>{{ item.NewsComment }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
               <div
                    class="mx-3 my-5"
                    v-for="item in news"
                    :key="item.Id"
                  >
                    <button
                      class="btn btn-secondary"
                      v-on:click="readnews(item.NewsHeading)"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <img
                            v-if="item.ImagePath"
                            :src="
                              $store.state.auth.baseurl +
                              item.ImagePath.substr(1)
                            "
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div class="col-md-8 text-left">
                          <h2>{{ item.NewsHeading }}</h2>
                        <div v-html="item.Newspreview"></div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div class="text-center mb-3" v-if="news">
                    <router-link to="/moreschnews" class="btn btn-secondary">
                      Read More News <i class="fa fa-arrow-right"></i>
                    </router-link>
                  </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "news",
  components: {
    PageSpinner,
  },
  data() {
    return {
      accessDetails: null,
      NewsHeadingval: localStorage.getItem("NewsHeading") || "",
      news: null,
      comment: null,
      currentnews: null,
      onloader:"none"
    };
  },

  methods: {
    readnews: function (heading) {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsschbyheading", heading)
        .then((resp) => {
          this.getnewscomment(resp.Id);
          this.currentnews = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
    getnewscomment: function (NewsId) {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewscomment", NewsId)
        .then((resp) => {
          this.comment = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
    getnews: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsschtop10")
        .then((resp) => {
          this.news = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      if (this.NewsHeadingval.length > 0) {
        this.readnews(this.NewsHeadingval);
      } else {
        this.$router.push("/moreschnews");
      }
      this.getnews();
    }
  },
};
</script>

<style></style>
