<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
      <router-link to="/school/finance" class="text-white">
        Finance
      </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i> School Bill Payment</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select v-model="SessionIdval" class="form-control" @change="getregterm()">
                                  <option value=""></option>
                                  <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="SessionIdval">
                              <div class="form-group">
                                <label class="text-white">Term</label>
                                <select v-model="TermIdval" class="form-control" @change="getclass()">
                                  <option value="0"></option>
                                  <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="TermIdval">
                              <div class="form-group">
                                <label class="text-white">Class</label>
                                <select v-model="ClassIdval" class="form-control" @change="getstudent()">
                                  <option value="0"></option>
                                  <option v-for="item in classitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="ClassIdval">
                              <div class="form-group">
                                <label class="text-white">Student</label>
                                <select v-model="StudentIdval" class="form-control" @change="getSchoolBillPayment()">
                                  <option value="0"></option>
                                  <option v-for="item in studentItems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.StudentName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="StudentIdval">
                              <div class="form-group">
                                <label class="text-white">Amount Paid</label>
                                <input v-model="AmountPaidval" class="form-control text-right" required />
                              </div>
                            </div>
                            <div class="col-md-6" v-if="AmountPaidval">
                              <div class="form-group">
                                <label class="text-white mr-4">Payment Date</label>
                                <input v-model="PaymentDateval" class="form-control" type="date" required />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3" v-if="PaymentDateval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="resultItems">
              <span class=" badge badge-primary ml-3">Total Bill Amount = {{ resultItems.TotalBillAmount }}</span>
              <span class=" badge badge-success ml-3">Total Amount Paid = {{ resultItems.TotalAmount }}</span>

              <span class=" badge badge-danger ml-3">Balance = {{ resultItems.AmountBalance }}</span>
              <div class="table-responsive mt-2">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Amount Paid</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultItems.AmountPaidDetails" :key="item.Id">
                      <td>{{ item.AmountPaid }}</td>
                      <td>{{ item.PaymentDate | formatDate }}</td>
                      <td>
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "SchooBillPayment",
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      ClassIdval: 0,
      SessionIdval: 0,
      TermIdval: 0,
      StudentIdval: 0,
      AmountPaidval: "",
      PaymentDateval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      Passportval: null,
      studentItems: null,
      resultItems: null,
      process: "N",
      onloader: "none",
    };
  },

  methods: {
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getByIdSchoolBillPayment", Id)
        .then((resp) => {
          this.Idval = Id;
          this.ClassIdval = resp.ClassId;
          this.SessionIdval = resp.SessionId;
          this.getregterm();
          this.TermIdval = resp.TermId;
          this.AmountPaidval = resp.AmountPaid;
          this.PaymentDateval = this.frontEndDateFormat(resp.PaymentDate);
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let Id = this.Idval;
      let StudentId = this.StudentIdval;
      let AmountPaid = this.AmountPaidval;
      let PaymentDate = this.PaymentDateval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        StudentId,
        AmountPaid,
        PaymentDate,
        SessionId,
        TermId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processSchoolBillPayment", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.AmountPaidval = "";
          this.PaymentDateval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getSchoolBillPayment: function () {
      this.resultItems = null;
      if (this.StudentIdval) {
        let StudentId = this.StudentIdval;
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let userdata = {
          StudentId,
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getSchoolBillPayment", userdata)
          .then((resp) => {
            this.resultItems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getregsession: function () {
      this.termitems = null;
      this.Sessionitems = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("GetSessionInSchoolBill")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm: function () {
      this.termitems = null;
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("GettermInSchoolBill", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclass: function () {
      this.classitems = null;
      if (this.TermIdval > 0) {
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let Userdata = { SessionId, TermId };
        this.ClassIdval = 0;
        this.onloader = "flex";
        this.$store
          .dispatch("GetClassInSchoolBill", Userdata)
          .then((resp) => {
            this.classitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getstudent: function () {
      if (this.ClassIdval > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getByClassIdStudent", this.ClassIdval)
          .then((resp) => {
            this.studentItems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getregsession();
    }
  },
};
</script>

<style></style>
