<template>
  <div v-if="userinfo">
    <PageSpinner :onloader="onloader" />
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="
        min-height: 600px;
        background-image: url(img/brand/profile.png);
        background-size: cover;
        background-position: center top;
      ">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h3 class="display-4 text-white">
              Hello <span v-if="userinfo">{{ userinfo.FullName }}</span>
            </h3>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-5 order-xl-2 mb-5 mb-xl-0">
          <b-card no-body class="card-profile" alt="Image placeholder" img-top>
            <b-row class="justify-content-center">
              <b-col lg="3" class="order-lg-2">
                <div class="card-profile-image">
                  <img v-if="userinfo.Passport" alt="" :src="userinfo.Passport" class="circle" />
                  <img v-else src="img/brand/loguser.png" alt="" class="circle" />

                </div>
              </b-col>
            </b-row>

            <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <a href="#" class="btn btn-sm btn-default mr-4">S</a>
                <a href="#" class="btn btn-sm btn-default float-right">D</a>
              </div>
            </b-card-header>

            <b-card-body class="pt-5">
              <div class="text-center">
                <h5 class="h3">
                  <span v-if="userinfo">{{ userinfo.FullName }} </span>
                </h5>
                <h5>
                  <span v-if="userinfo"><i class="fa fa-envelope pr-1"></i> {{ userinfo.EmailAddress }}</span>
                </h5>
                <h5>
                  <span v-if="userinfo"><i class="fa fa-phone pr-1"></i>{{ userinfo.PhoneNo }}</span>
                </h5>
              </div>
            </b-card-body>
          </b-card>
        </div>

        <div class="col-xl-7 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="align-items-center">
                <h3 class="mb-0">Change Password</h3>
              </div>
            </div>
            <template>
              <form @submit.prevent="changepassword">
                <div class="pl-lg-4">
                  <div class="form-horizonal">
                    <div class="form-group">
                      <base-input alternative="" label="New Password" placeholder="New Password"
                        input-classes="form-control-alternative" type="password" v-model="NewPasswordval" />
                    </div>
                    <div class="form-group">
                      <base-input alternative="" label="Retype Password" placeholder="Retype Password"
                        input-classes="form-control-alternative" type="password" v-model="ReTypePasswordval" />
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary btn-block my-3">
                    Submit
                  </button>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "user-profile",
  components: {
        PageSpinner
    },
  data() {
    return {
      userinfo: null,
      OldPasswordval: "",
      NewPasswordval: "",
      ReTypePasswordval: "",
      onloader: "none",
    };
  },
  methods: {
    changepassword: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("changepassword", this.NewPassword)
        .then(() => {
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Changed Successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getuser: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getuser")
        .then((resp) => {
          this.userinfo = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch('auth/Logout')
          this.$router.push('/login')
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch('auth/Logout')
        this.$router.push('/login')
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getuser();
  },
};
</script>
<style></style>
