<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <div class="row">
      <div class="col-lg-8 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-4">
        <div class="px-5 py-5 text-white bg-dark" v-if="isSuccessful == false">
          <div class="text-center text-muted my-3">
            <p class="mt-3">Submit details below to change your password</p>
          </div>
          <form role="form" @submit.prevent="userdata">
            <base-input class="input-group-alternative" placeholder="New Password" type="password"
              addon-left-icon="ni ni-lock-circle-open" v-model="newpasswordval">
            </base-input>

            <base-input class="input-group-alternative" placeholder="Retype Password" type="password"
              addon-left-icon="ni ni-lock-circle-open" v-model="retypepasswordval">
            </base-input>

            <div class="text-center">
              <button type="submit" class="my-4 btn btn-primary">
                Submit <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
        <div class="px-5 py-5 text-white bg-success" v-else>
          <div class="text-center text-muted my-3">
            <p class="mt-3">Your password has been changed successfully</p>
          </div>
          <div class="text-center pt-2">
            <router-link to="/login" class="btn btn-outline-dark"><i class="fa fa-arrow-left"></i> Back to Login</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSpinner from "@/components/PageSpinner"
import RandomWord from "@/mixins/random-word-generator.mixin"
import Alert from "@/mixins/Alert.mixins"
export default {
  name: "change-default-password",
  mixins: [RandomWord, Alert],
  components: { PageSpinner },
  data() {
    return {
      username: "",
      retypepasswordval: "",
      newpasswordval: "",
      isSuccessful: false,
      onloader: "none",
    };
  },
  methods: {
    userdata() {
      let retypepassword = this.retypepasswordval;
      let newpassword = this.newpasswordval;
      if (retypepassword == newpassword) {
        let username = this.$store.state.auth.username;
        let password = `${this.RandomStringNumber(30)}${this.$store.state.auth.PwdPad}${btoa(newpassword)}`;
        let payload = {
          username,
          password
        };
        this.onloader = "flex";
        this.$store
          .dispatch("auth/ChangeDefaultPassword", payload)
          .then((resp) => {
            this.onloader = "none";
            if (resp.responseCode == '00') {
              this.isSuccessful = true;
              this.ShowAlertSuccess(resp.responseMessage, '');
            }else{
              this.isSuccessful = false;
              this.ShowAlertError(resp.responseMessage, '');
            }
          })
          .catch((err) => {
            this.onloader = "none";
            this.isSuccessful = false;
            this.ShowAlertError(err.response.data, '');
          });
      } else {
        this.isSuccessful = false;
        this.ShowAlertError('Password Mismatched', '');
      }
    },
  },
};
</script>

<style></style>
