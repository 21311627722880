<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard </router-link>
      <router-link to="/school/student-registration" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Student
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Deleted Student
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="justify-content-end form-inline">
           <router-link to="/studentreg" class="btn btn-default"
                > <i class="fa fa-users"></i> Back to Student</router-link
              >
          <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
            v-model="searchval">
          </base-input>
        </div>
        <div class="row">
          <div class="col-md-3 col bg-secondary" v-for="item in searchedResultQuery" :key="item.Id">
            <div class="card text-center m-2">
              <div class="card-body bg-secondary shrink">
                <img v-if="item.Passport" :src="item.Passport" class="rounded" alt="" height="50" />
                <img v-else src="/img/brand/loguser.png" class="rounded" alt="" height="50" />
                <h5><small>{{ item.StudentName }}</small> </h5>
                <h6> <small>{{ item.AdmNo }} | {{ item.Sch_Class }} | {{ item.ClassOption }} | {{ item.SectionName
                }}</small> </h6>
                <div>
                  <button v-if="item.IsDeleted" class="btn btn-default btn-sm" v-on:click="deletestudent(item.Id)">
                    <i class="fa fa-trash-restore"></i>
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
  name: "DeletedStudent",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails:null,
      students: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.students.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.StudentName.toLowerCase().includes(v) ||
                item.Sch_Class.toLowerCase().includes(v) ||
                item.AdmNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.students;
      }
    }
  },
  methods: {
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentbyid", Id)
        .then(resp => {
          this.dataid = resp.Id;
          if (resp.Passport != null) {
            this.uploadedimage =
              this.$store.state.auth.baseurl + resp.Passport.substr(1);
          }
          this.Passportval = resp.Passport;
          this.ContactIdval = resp.ContactId;
          this.GContactIdval = resp.GContactId;
          this.GUserIdval = resp.GUserId;
          this.GPhoneNoval = resp.GPhoneNo;
          this.GTitleval = resp.GTitle;
          this.GFirstnameval = resp.GFirstName;
          this.GMiddlenameval = resp.GMiddlename;
          this.GLastNameval = resp.GLastName;
          this.GEmailval = resp.GEmaillAdd;
          this.GIdval = resp.GId;
          this.FullAddressval = resp.FullAddress;
          this.FirstNameval = resp.FirstName;
          this.Addressval = resp.AddressOne;
          this.AdmissionNoval = resp.AdmNo;
          this.Admissiondateval = this.frontEndDateFormat(resp.DateAdmited);
          this.ClassIdval = resp.ClassId;
          this.getoption();
          this.OptionIdval = resp.OptionId;
          this.getarm();
          this.ArmIdval = resp.SectionId;
          this.MiddleNameval = resp.MiddleName;
          this.LastNameval = resp.LastName;
          this.SexIdval = resp.SexId;
          this.UserIdval = resp.UserId;
          if (resp.DateofBirth != null || resp.DateofBirth != "") {
            this.DOBval = this.frontEndDateFormat(resp.DateofBirth);
          }

          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    deletestudent: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletestudent", Id)
        .then(resp => {
          this.students = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Processed successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          if (
            err.response.data === "Access Expired" ||
            err.response.data === "Invalid Credential"
          ) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok"
            });
          }
          //this.$store.dispatch('logout')
        });
    },
    getstudents: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentsdeleted")
        .then(resp => {
          this.students = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      this.$store
        .dispatch("getstudentsdeletedsearch", this.searchval)
        .then(resp => {
          this.students = resp;
          //console.log(resp)
        })
        .catch(err => {
          this.checkresponse(err.response.data);
        });
    },

    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getstudents();
    }    
  }
};
</script>

<style>
</style>
