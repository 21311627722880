<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/school/environment-settings" class="text-white">
         <i class="fa fa-ellipsis-v px-2"></i>Settings
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i> Grade Scale</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary mb-0">
              <div class="card-header">
                <h3 class="card-title">Registration</h3>
              </div>
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label>Category</label>
                      <select
                        v-model="categoryval"
                        @change="getgradescale($event)"
                        class="form-control"
                      >
                        <option
                          v-for="item in classcategory"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.CategoryName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="categoryval">
                      <label>Grade</label>
                      <input v-model="gradeval" class="form-control" />
                    </div>
                    <div class="form-group" v-if="gradeval">
                      <label>Title</label>
                      <input v-model="gradetitleval" class="form-control" />
                    </div>
                    <div class="form-group" v-if="gradetitleval">
                      <label>Mark From</label>
                      <input v-model="markfromval" class="form-control" />
                    </div>
                    <div class="form-group" v-if="markfromval">
                      <label>Mark To</label>
                      <input v-model="marktoval" class="form-control" />
                    </div>
                    <div class="text-center mb-3" v-if="marktoval">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card bg-secondary">
              <div class="card-body">
                <div class="justify-content-end form-inline">
              <base-input
                class="input-group-alternative"
                placeholder="Search here..."
                addon-left-icon="fa fa-search"
                v-model="searchval"
              >
              </base-input>
            </div>
            <div class="table-responsive mt-2">
              <table
                class="table table-dark table-bordered table-striped table-hover"
              >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Grade</th>
                        <th>Title</th>
                        <th>Mark From</th>
                        <th>Mark To</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in searchedResultQuery" :key="item.Id">
                        <td>
                          <button
                            class="btn btn-secondary btn-sm"
                            v-on:click="edititem(item.Id)"
                          >
                            <i class="fa fa-edit"></i> Edit
                          </button>
                        </td>
                        <td>{{ item.Grade }}</td>
                        <td>{{ item.Title }}</td>
                        <td>{{ item.Markfrom }}</td>
                        <td>{{ item.Markto }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "class",
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      dataid: 1,
      categoryval: "",
      gradescaleitems: "",
      gradeval: "",
      gradetitleval: "",
      markfromval: "",
      marktoval: "",
      classcategory: null,
      searchval:'',
      process: "N",
      onloader: "none",
    };
  },
computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.gradescaleitems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.Grade.toLowerCase().includes(v) 
                || item.Title.toLowerCase().includes(v)               
            );
        });
      } else {
        return this.gradescaleitems;
      }
    }
  },
  methods: {
    newterm: function () {
      this.gradeval = "";
      this.gradetitleval = "";
      this.markfromval = "";
      this.marktoval = "";
      this.process = "N";
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getgradescalebyid", Id)
        .then((resp) => {
          this.dataid = Id;
          this.categoryval = resp.CategoryId;
          this.gradeval = resp.Grade;
          this.gradetitleval = resp.Title;
          this.markfromval = resp.Markfrom;
          this.marktoval = resp.Markto;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let CategoryId = this.categoryval;
      let Grade = this.gradeval;
      let GradeTitle = this.gradetitleval;
      let MarkFrom = this.markfromval;
      let MarkTo = this.marktoval;
      let Id = this.dataid;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        CategoryId,
        Grade,
        GradeTitle,
        MarkFrom,
        MarkTo,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("gradescaleregistration", payload)
        .then((resp) => {
          this.gradescaleitems = resp;
          this.gradeval = "";
          this.gradetitleval = "";
          this.markfromval = "";
          this.marktoval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getgradescale: function (event) {
      this.onloader = "flex";
      let id = event.target.value;
      this.$store
        .dispatch("getgradescale", id)
        .then((resp) => {
          this.gradescaleitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getgradescalelanding: function (catid) {
      this.onloader = "flex";
      this.$store
        .dispatch("getgradescale", catid)
        .then((resp) => {
          this.gradescaleitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getclasscategory: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getclasscategory")
        .then((resp) => {
          this.classcategory = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.onloader = "none";
          this.$router.push("/login");
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
    if (
      userDetails.isSchoolStaff == false
    ) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    } else {
      this.getclasscategory();
    }
  },
};
</script>

<style></style>
