<template>
<div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            Dashboard</router-link><i class="fa fa-ellipsis-v px-2"></i>
        <router-link to="/student/home-work" class="text-white">
           Home Work
        </router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>Home Works Answer</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div class="form-horizontal">
                            <form role="form" @submit.prevent="submitdata">
                                <div class="card-body">
                                    <div v-if="homeworkitem" v-html="homeworkitem.Descrip"></div>
                                    <div class="form-group ">
                                        <label><b>Descrisbe your answer below</b></label>
                                        <ckeditor v-model="Descripval" :editorUrl="$store.state.appdatas.editorUrl"></ckeditor>
                                    </div>
                                    <div class="form-group">
                                        <div v-if="uploadedimage">
                                            <a :href="$store.state.auth.baseurl + uploadedimage.substr(1)" target="_blank" class="btn btn-dark btn-sm"><i class="fa fa-download fa-2x mr-3"></i> Download </a>

                                        </div>
                                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"> <br> <button class="btn btn-secondary" @click.prevent="submitFile()" v-if="$store.state.auth.user_status_id==2"><i class="ni ni-cloud-upload-96"></i>Upload</button>
                                    </div>
                                </div>
                                <div class="text-center mb-3">
                                    <button v-if="$store.state.auth.user_status_id==2" type="submit" class="btn btn-primary my-4">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'homeworkanswer',
    components: {
        PageSpinner
    },
    data() {
        return {
            accessDetails: null,
            HomeworkIdval: localStorage.getItem('HomeworkId') || '',
            StudIdval: localStorage.getItem('StudId') || '',
            Descripval: '',
            DocPathval: '',
            uploadedimage: '',
            homeworkitem: null,
            homeworanswerkitem: null,
            onloader: 'none'
        }
    },
    methods: {
        submitdata: function () {
            let HomeworkId = this.HomeworkIdval;
            let StudId = this.StudIdval;
            let Descrip = this.Descripval;
            let DocPath = this.DocPathval;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                HomeworkId,
                StudId,
                Descrip,
                DocPath,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('processhomeworkanswer', userdata)
                .then((resp) => {
                    this.homeworanswerkitem = resp;
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        gethomework: function () {
            this.onloader = 'flex'
            this.$store.dispatch('gethomeworkbyid', this.HomeworkIdval)
                .then((resp) => {
                    this.homeworkitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        gethomeworkanswer: function () {
            this.onloader = 'flex';
            let HomeworkId = this.HomeworkIdval;
            let StudId = this.StudIdval;
            this.$store.dispatch('gethomeworkanswerbystudid', {
                    HomeworkId,
                    StudId
                })
                .then(resp => {
                    if (resp != null) {
                        this.Descripval = resp.Descrip;
                        this.uploadedimage = resp.DocPath;
                    }
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        handleFileUpload: function () {
            this.file = this.$refs.file.files[0];
        },
        submitFile: function () {
            this.onloader = 'flex'
            var form = new FormData();
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let formalurl = this.DocPathval;
            let doctype = 'doc';
            form.append('username', username)
            form.append('accesskey', accesskey)
            form.append('formalurl', formalurl)
            form.append('doctype', doctype)
            form.append('file', this.file)
            this.$store.dispatch('uploadfile', form)
                .then((resp) => {
                    this.DocPathval = resp;
                    this.uploadedimage = this.$store.state.auth.baseurl + resp.substr(1)
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Uploaded successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },

        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStudent == true || this.accessDetails.isStudentGuardian == true) {
            this.gethomework();
            this.gethomeworkanswer();           
        } else {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        }  
    }
};
</script>

<style></style>
