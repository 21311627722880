<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-5">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body text-center">
                <img v-if="ExamIdval == 2" src="img/brand/Jamb.jpg" alt="Jamb" class="img-fluid" />
                <img v-if="ExamIdval == 1" src="img/brand/Waec.jpg" alt="Jamb" class="img-fluid" />
                <img v-if="ExamIdval == 3" src="img/brand/Neco.jpg" alt="Jamb" class="img-fluid" />

                <h3 class="my-2">Access {{ ExamName }} Past Questions and solutions!</h3>
                <h5>How well will you perform in {{ ExamName }} </h5>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="mt-4">
              <div class="card bg-secondary">
                <div class="card-body">
                  <div class="input-group">
                    <label class="input-group-text bg-success">Subject</label>
                    <select v-model="SubjectIdval" @change="getcbtyearforpractice()"
                      class="form-control btn btn-outline-success">
                      <option value="0">Select Subject</option>
                      <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.Id">
                        {{ item.ExamSubject }}
                      </option>
                    </select>
                  </div>
                  <div class="input-group" v-if="SubjectIdval">
                    <label class="input-group-text bg-success">Year</label>
                    <select v-model="ExamYearIdval" class="form-control btn btn-outline-success">
                      <option value="0">Select Year</option>
                      <option v-for="item in cbtyearsItems" :key="item.Id" v-bind:value="item.Id">
                        {{ item.ExamYear }}
                      </option>
                    </select>
                  </div>


                  <div class="input-group" v-if="ExamYearIdval > 0">
                    <label class="input-group-text bg-success">How many question?</label>
                    <input type="number" min="1" v-model="MaxNoval" class="form-control text-right"
                      placeholder="How many question?" />
                  </div>
                  <div class="input-group" v-if="ExamYearIdval > 0">
                    <label class="input-group-text bg-success">Exam Time (S)?</label>
                    <input v-if="SubjectIdval > 0" type="number" min="1" v-model="ExamTimeval"
                      class="form-control text-right" placeholder="Exam Time (In Seconds)?" />
                  </div>
                  <div class="text-center">
                    <button v-if="ExamYearIdval > 0" class="btn btn-default" @click.prevent="openquestion">
                      TEST YOURSELF NOW <i class="fa fa-long-arrow-alt-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "cbtexamsubject",
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      ExamIdval: localStorage.getItem("ExamId") || "",
      ExamLogo: "",
      ExamName: "",
      SubjectIdval: 0,
      ExamYearIdval: 0,
      MaxNoval: 40,
      ExamCode: "",
      ExamTimeval: 40,
      searchval: "",
      subjectitems: "",
      cbtyearsItems: '',
      selectedsubjectitems: null,
      process: "N",
      onloader: "none",
    };
  },
  methods: {
    getexam: function () {
      this.onloader = "flex";
      let ExamId = this.ExamIdval;
      this.$store
        .dispatch("getcbtexamid", ExamId)
        .then((resp) => {
          this.ExamName = resp.ExamName;
          if (resp.ExamLogo != null) {
            this.ExamLogo =
              this.$store.state.auth.baseurl + resp.ExamLogo.substr(1);
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getcbtexamsubject: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getcbtexamsubject", this.ExamIdval)
        .then((resp) => {
          this.subjectitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getcbtyearforpractice: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getcbtyearforpractice", this.SubjectIdval)
        .then((resp) => {
          this.cbtyearsItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },

    openquestion: function () {
      if (this.MaxNoval < 1) {
        swal({
          title: "Oops!",
          text: "Number of question must be greater than 0",
          icon: "error",
          button: "Ok",
        });
      } else if (this.ExamTimeval < 1) {
        swal({
          title: "Oops!",
          text: "Exam time must be greater than 0",
          icon: "error",
          button: "Ok",
        });
      } else {
        let Id = 1;
        let ExamId = this.ExamIdval;
        let SubjectId = this.SubjectIdval;
        let ExamYearId = this.ExamYearIdval;
        let ExamTime = this.ExamTimeval;
        let MaxNo = this.MaxNoval;
        let username = this.$store.state.auth.username;
        let accesskey = this.$store.state.auth.accesskey;
        let userData = {
          Id,
          ExamId,
          SubjectId,
          ExamYearId,
          ExamTime,
          MaxNo,
          username,
          accesskey,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("processcbtpractice", userData)
          .then((resp) => {
            this.ExamCode = resp;
            console.log(resp);
            this.onloader = "none";
            localStorage.setItem("ExamCode", this.ExamCode);
            this.$router.push("/cbtprep");
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStudent == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getexam();
      this.getcbtexamsubject();
    }
  },
};
</script>

<style></style>
