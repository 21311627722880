<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/school/hostel" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Hostel
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Hostel Room</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary">
                <h3 class=" card-header">Registration</h3>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="form-group">
                        <label>Hostel</label>
                        <select v-model="HostelIdval" @change="GetHostelFloor()" class="form-control">
                          <option value="0"></option>
                          <option v-for="item in HostelItems" :key="item.Id" v-bind:value="item.Id">
                            {{ item.HostelName }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="HostelIdval">
                        <label>Floor</label>
                        <select v-model="FloorIdval" @change="GetHostelRoom()" class="form-control">
                          <option value="0"></option>
                          <option v-for="item in HostelFloorItems" :key="item.Id" v-bind:value="item.Id">
                            {{ item.FloorName }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="FloorIdval">
                        <label>Room Number</label>
                        <input v-model="RoomNoval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="RoomNoval">
                        <label>Number of Bed in the Floor</label>
                        <input type="number" min="1" v-model="NoofBedval" class="form-control" required />
                      </div>
                      <div class="text-center card-footer mb-3" v-if="NoofBedval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="justify-content-end form-inline mt-3">
                <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
                  v-model="searchval">
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table class="table table-dark table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Room No.</th>
                      <th>Floor Name</th>
                      <th>Hostel Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in searchedResultQuery" :key="item.Id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.RoomNo }}</td>
                      <td>{{ item.FloorName }}</td>
                      <td>{{ item.HostelName }}</td>
                      <td>
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import HostelMixin from "@/mixins/Hostel.mixin";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "Hostel",
  mixins: [HostelMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      HostelIdval: 0,
      FloorIdval: 0,
      RoomNoval: "",
      NoofBedval: "",
      HostelItems: null,
      HostelFloorItems: null,
      HostelRoomItems: null,
      searchval: "",
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.HostelRoomItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.HostelName.toLowerCase().includes(v) ||
                item.FloorName.toLowerCase().includes(v) ||
                item.RoomNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.HostelRoomItems;
      }
    }
  },
  methods: {
    edititem(Id) {
      this.onloader = "flex";
      let FloorId = this.FloorIdval;
      let userdata = { Id, FloorId };
      this.$store
        .dispatch("hostelRoom/GetByIdHostelRoom", userdata)
        .then(resp => {
          this.Idval = Id;
          this.HostelIdval = resp.HostelId;
          this.FloorIdval = resp.FloorId;
          this.RoomNoval = resp.RoomNo;
          this.NoofBedval = resp.NoofBed;
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let FloorId = this.FloorIdval;
      let RoomNo = this.RoomNoval;
      let NoofBed = this.NoofBedval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        FloorId,
        RoomNo,
        NoofBed,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("hostelRoom/ProcessHostelRoom", userdata)
        .then(resp => {
          this.HostelRoomItems = resp;
          this.FloorIdval = 0;
          this.RoomNoval = "";
          this.NoofBedval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetHostelFloor() {
      this.HostelRoomItems = null
      this.onloader = "flex";
      this.$store
        .dispatch("hostelFloor/GetHostelFloor", this.HostelIdval)
        .then(resp => {
          this.HostelFloorItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetHostelRoom() {
      this.onloader = "flex";
      this.$store
        .dispatch("hostelRoom/GetHostelRoom", this.FloorIdval)
        .then(resp => {
          this.HostelRoomItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    }
  }
};
</script>

<style></style>
