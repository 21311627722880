<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/school/library" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Library
      </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Library Book Return</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div v-if="isFormOpen">
            <div class="card">
              <div class="card-body">
                <form>
                  <div class=" form-group">
                    <span>Book</span>
                    <input v-model="BookNameval" class=" form-control" disabled />
                  </div>
                  <div class=" form-group">
                    <span>Borrowed By</span>
                    <input v-model="Borrowerval" class=" form-control" disabled />
                  </div>
                  <div class=" form-group">
                    <span>Return Date</span>
                    <input type="date" v-model="ReturnDateval" class="form-control" required />
                  </div>
                  <div class="card-footer text-center">
                    <base-button type="secondary" v-on:click.prevent="closeForm()">Close</base-button>
                    <base-button type="success" v-on:click.prevent="submitdata()">Submit</base-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="justify-content-end form-inline mt-3">
              <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
                v-model="searchval">
              </base-input>
            </div>
            <div class="table-responsive mt-2">
              <table class="table table-dark table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>Book</th>
                    <th>Book Nnumber</th>
                    <th>Borrowed By</th>
                    <th>Date Issue</th>
                    <th>Date Due</th>
                    <th>Return Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchedResultQuery" :key="item.Id">
                    <td>{{ item.BookTitle }}</td>
                    <td>{{ item.BookNo }}</td>
                    <td>{{ item.BorrowedByUser }}</td>
                    <td>{{ item.DateIssue | formatDate }}</td>
                    <td>{{ item.DateDue | formatDate }}</td>
                    <td>{{ item.ReturnDate | formatDate }}</td>
                    <td>
                      <button class="btn btn-secondary btn-sm" v-on:click="SelectBook(item.Id)">
                        <i class="fa fa-reply"></i> Return Book
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import DateFormaterMixin from "@/mixins/DateFormater.mixin";
import PageSpinner from "@/components/PageSpinner";

export default {
  name: "LibraryBookReturn",
  mixins: [DateFormaterMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      BookNameval: "",
      Borrowerval: "",
      ReturnDateval: "",
      searchval: "",
      LibraryBookIssueItems: null,
      isFormOpen: false,
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.LibraryBookIssueItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.BookNo.toLowerCase().includes(v) ||
                item.BookTitle.toLowerCase().includes(v) ||
                item.BorrowedByUser.toLowerCase().includes(v)
            );
        });
      } else {
        return this.LibraryBookIssueItems;
      }
    }
  },
  methods: {
    openForm() {
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
      this.BookNameval = "";
      this.Borrowerval = "";
    },
    SelectBook(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookIssue/GetByIdLibraryBookIssue", Id)
        .then(resp => {
          this.Idval = Id;
          this.BookNameval = resp.BookNo;
          this.Borrowerval = resp.BorrowedByUser;
          this.openForm();
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      if (this.ReturnDateval) {
        let Id = this.Idval;
        let ReturnDate = this.ReturnDateval;
        let userdata = {
          Id,
          ReturnDate
        };
        this.onloader = "flex";
        this.$store
          .dispatch("libraryBookIssue/ReturnLibraryBookIssue", userdata)
          .then(resp => {
            this.LibraryBookIssueItems = resp;
            this.ReturnDateval = "";
            this.closeForm();
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Submitted successfully",
              icon: "success",
              button: "Ok"
            });
          })
          .catch(err => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    GetLibraryBookIssueNotReturn() {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookIssue/GetLibraryBookIssueNotReturn")
        .then(resp => {
          this.LibraryBookIssueItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.GetLibraryBookIssueNotReturn();
    }
  }
};
</script>

<style></style>
