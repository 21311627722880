<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/school/environment-settings" class="text-white">
         <i class="fa fa-ellipsis-v px-2"></i>Settings
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v"></i> Subject</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary mb-0">
              <div class="card-header">
                <h3 class="card-title">Registration</h3>
              </div>
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitsubject">
                    <div class="form-group">
                      <label>Class</label>
                      <select
                        v-model="classval"
                        @change="getoption()"
                        class="form-control"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in classitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.Sch_Class }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="classval">
                      <label>Option</label>
                      <select
                        v-model="optionval"
                        @change="getschsubject()"
                        class="form-control"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in optionitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.ClassOption }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="optionval">
                      <label>Available Subject</label>
                      <select v-model="subjectval" class="form-control">
                        <option value="0"></option>
                        <option
                          v-for="item in subjectitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.Title }}
                        </option>
                      </select>
                    </div>
                    <div class="text-center mb-3" v-if="subjectval">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card bg-secondary">
              <div class="card-body">
                <div class="justify-content-end form-inline">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Search here..."
                    addon-left-icon="fa fa-search"
                    v-model="searchval"
                  >
                  </base-input>
                </div>
                <div class="table-responsive mt-2">
                  <table
                    class="table table-dark table-bordered table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in searchedResultQuery" :key="item.Id">
                        <td>{{ item.SchSubject }}</td>
                        <td>
                          <button
                            class="btn btn-secondary btn-sm"
                            v-on:click="deleteitem(item.Id)"
                          >
                            <i class="ni ni-fat-remove"></i> Delete
                          </button>
                          <button
                            class="btn btn-secondary btn-sm"
                            v-on:click="opentopic(item.Id)"
                          >
                            <i class="ni ni-collection"></i> View Topic
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "subject",
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      dataid: 1,
      subjectval: "",
      subjectitems: null,
      schsubjectitems: null,
      optionval: "",
      optionitems: [],
      classitems: null,
      classval: "",
      searchval: "",
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.schsubjectitems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(v => item.SchSubject.toLowerCase().includes(v));
        });
      } else {
        return this.schsubjectitems;
      }
    }
  },
  methods: {
    opentopic: function(Id) {
      localStorage.setItem("SubjectTopicId", Id);
      this.$router.push("/subjecttopic");
    },
    deleteitem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getschsubjectbyid", Id)
        .then(resp => {
          this.classval = resp.ClassId;
          this.optionval = resp.OptionId;
          this.subjectval = resp.SchsubId;
          this.process = "D";
          this.submitsubject();
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err);
          //this.$store.dispatch('logout')
        });
    },
    submitsubject: function() {
      let SchsubId = this.subjectval;
      let ClassId = this.classval;
      let OptionId = this.optionval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        SchsubId,
        ClassId,
        OptionId,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("registerschsubject", payload)
        .then(() => {
          this.getschsubjectnotselected();
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getschsubject: function() {
      this.schsubjectitems = null;
      this.subjectitems = null;
      let SchsubId = 1;
      let ClassId = this.classval;
      let OptionId = this.optionval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        SchsubId,
        ClassId,
        OptionId,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getavailablesubject", payload)
        .then(resp => {
          this.subjectitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
      this.$store
        .dispatch("getschsubject", payload)
        .then(resp => {
          this.schsubjectitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
      this.onloader = "none";
    },
    getschsubjectnotselected: function() {
      this.schsubjectitems = null;
      this.subjectitems = null;
      let SchsubId = 1;
      let ClassId = this.classval;
      let OptionId = this.optionval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        SchsubId,
        ClassId,
        OptionId,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getavailablesubject", payload)
        .then(resp => {
          this.subjectitems = resp;
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
      this.$store
        .dispatch("getschsubject", payload)
        .then(resp => {
          this.schsubjectitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoption: function() {
      this.onloader = "flex";
      this.schsubjectitems = null;
      this.subjectitems = null;
      this.optionval = 0;
      let val = this.classval;
      this.$store
        .dispatch("getoptionbyclass", val)
        .then(resp => {
          this.optionitems = resp;
          if (this.optionval != "" || this.optionval != null) {
            this.getschsubjectnotselected();
          }
          this.onloader = "none";
          //console.log(resp)
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getclass: function() {
      this.onloader = "flex";
      this.classval = 0;
      this.$store
        .dispatch("getclass")
        .then(resp => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
    if (
      userDetails.isSchoolStaff == false
    ) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    } else {
      this.getclass();
    }
  }
};
</script>

<style></style>
