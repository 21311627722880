<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                <i class="ni ni-tv-2"></i> Dashborad</router-link>
            <span class="text-white"><i class="ni ni-bold-right"></i> Privacy</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <card header-classes="bg-transparent">
                <form role="form" @submit.prevent="submitdata">
                    <ckeditor v-model="policyval" :editorUrl="$store.state.appdatas.editorUrl"></ckeditor>

                    <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">Submit</button>
                    </div>
                </form>
            </card>
        </div>

    </div>
</template>

<script>
import {
    mapState
} from 'vuex'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'privacy',
    components: {
        PageSpinner
    },
    data() {
        return {
            policyval: '',
            accessDetails: null,
            onloader: 'none'
        }
    },
    computed: {
        ...mapState({
            privacypolicy: state => state.appdatas.privacypolicy
        })
    },
    methods: {
        submitdata: function () {
            let item = this.policyval;
            this.onloader = 'flex';
            this.$store.dispatch('processprivacypolicy', item).then((resp) => {
                this.policyval = resp;
                this.onloader = 'none'
                swal({
                    title: "Success!",
                    text: 'Submitted successfully',
                    icon: "success",
                    button: "Ok",
                });
            })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getprivacypolicy: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getprivacypolicy')
                .then((resp) => {
                    this.policyval = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none';
                    this.checkresponse(err.response.data);
                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (
            this.accessDetails.isSuperAdmin == true ||
            this.accessDetails.isAdmin == true
        ) {
            //this.getprivacypolicy();
        } else {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        }
    }
};
</script>

<style></style>
