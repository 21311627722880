<template>
<div class="flex-column-reverse">
    <PageSpinner :onloader="onloader" />
    <div class="flex-row-reverse">

        <div class="flex-column-reverse">
            <card header-classes="bg-transparent">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div v-if="Openexam==false" class="form-horizontal">
                            <form role="form">
                                <div class="card bg-gradient-success">
                                    <div class="card-body">
                                        <div class="input-group">
                                            <input type="text" placeholder="Enter Registration Code" class="form-control" v-model="RegCodeval">
                                            <button type="submit" class="btn btn-primary input-group-append" @click.prevent="verifyentrancestudent">Verify</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <div v-if="Openquestion==false" class="form-horizontal">
                                <form role="form">
                                    <div class="card bg-gradient-success">
                                        <div class="card-body">
                                            <div class="input-group">
                                                <input type="text" placeholder="Enter Access Code" class="form-control" v-model="AccessCodeval">
                                                <button type="submit" class="btn btn-primary input-group-append" @click.prevent="verifyaccesscode">Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-else class="row">
                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6> Name : {{LastNameval}} {{FirstNameval}} {{MiddleNameval}}</h6>                                           
                                            <h1 class=" h1"><span class="badge badge-danger">{{ExamTimeval}} </span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="alert alert-secondary">
                                                <p v-html="CautionNoteval"> </p>
                                            </div>
                                            <div v-if="StartExam==false" class="pt-3 text-center">
                                                <button class="btn btn-dark" @click.prevent="processschquestionforexam">Start Exam</button>
                                            </div>
                                            <div v-if="StartExam" class="pt-3">
                                                <h1 class="text-center"><b class="badge badge-dark">Question {{CurrentSNo}}</b></h1>
                                                <div class="pt-2" v-html="CurrentQuestion"> </div>
                                                <div class="row" v-if="CurrentImage">
                                                    <img :src="$store.state.auth.baseurl + CurrentImage.substr(1)">
                                                </div>
                                                <div class="row">
                                                    <table class="table table-light table-hover table-sm table-borderless table-striped table-responsive">
                                                        <tr>
                                                            <td><b class="badge badge-dark">A. </b> <input type="checkbox" class="ml-3 display-1" @change="processsanswerA()" v-model="ChkOptionA"></td>
                                                            <td v-html="CurrentOptionA" class="display-1"></td>
                                                        </tr>
                                                        <tr>
                                                            <td><b class="badge badge-dark">B. </b> <input type="checkbox" class="ml-3 display-1" @change="processsanswerB()" v-model="ChkOptionB"></td>
                                                            <td v-html="CurrentOptionB" class="display-1"></td>
                                                        </tr>
                                                        <tr>
                                                            <td><b class="badge badge-dark">C. </b> <input type="checkbox" class="ml-3 display-1" @change="processsanswerC()" v-model="ChkOptionC"></td>
                                                            <td v-html="CurrentOptionC" class="display-1"></td>
                                                        </tr>
                                                        <tr>
                                                            <td><b class="badge badge-dark">D. </b> <input type="checkbox" class="ml-3 display-1" @change="processsanswerD()" v-model="ChkOptionD"></td>
                                                            <td v-html="CurrentOptionD" class="display-1"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div>
                                                    <div class="p-3 text-center">
                                                        <button v-if="nextbtn" class="btn btn-default" @click.prevent="nextqtn"><i class="ni ni-bold-right"></i> Next </button>
                                                        <button class="btn btn-default" @click.prevent="submitqtn"><i class="ni ni-cloud-upload-96"></i> Submit </button>
                                                        <button v-if="backbtn" @click.prevent="prevqtn" class="btn btn-default"><i class="ni ni-bold-left"></i> Previous </button>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-body bg-light">
                                                        <div v-if="Question" class="row pt-3">
                                                            <div class="col-sm-1" v-for="item in Question" :key="item.Id">
                                                                <button class="btn btn-success" v-on:click="getschquestionforexambyid(item.SNo)"> {{item.SNo }}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</div>
</template>

<script>

import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'entranceexam',
    components: {
    PageSpinner
  },
    data() {
        return {
            accessDetails: null,
            dataid: 1,
            RegCodeval: '',
            AccessCodeval: '',
            FirstNameval: '',
            MiddleNameval: '',
            LastNameval: '',
            HeaderIdVal: '',
            ExamPeriodval: '',
            Timerval: '',
            NoofQuestiontoDisplayval: '',
            CautionNoteval: '',
            SubjectNameval: '',
            SchSessionval: '',
            SchoolTermval: '',
            Sch_Classval: '',
            ClassOptionval: '',
            ExamTimeval: '',
            CurrentQuestion: '',
            CurrentSNo: '',
            CurrentQuestionId: '',
            CurrentImage: '',
            CurrentOptionA: '',
            CurrentOptionAId: '',
            CurrentOptionB: '',
            CurrentOptionBId: '',
            CurrentOptionC: '',
            CurrentOptionCId: '',
            CurrentOptionD: '',
            CurrentOptionDId: '',
            SelectedAnswerId: '',
            ChkOptionA: false,
            ChkOptionB: false,
            ChkOptionC: false,
            ChkOptionD: false,
            IsClosedval: false,
            ClosedDateval: null,
            QuestionSelectedId: '',
            Openquestion: false,
            Openexam: false,
            StartExam: false,
            Question: null,
            StudentInfo:null,
            nextbtn: true,
            backbtn: false,
            alertmsg: false,
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {
        nextqtn() {
            let SNo = this.CurrentSNo;
            SNo++;
            let MaxNo = this.NoofQuestiontoDisplayval;
            if (SNo > MaxNo) {
                SNo = MaxNo;
            }
            if (SNo == MaxNo) {
                this.nextbtn = false;
                this.backbtn = true;
            }
            if (SNo >= 1 && SNo < MaxNo) {
                if (SNo == 1) {
                    this.nextbtn = true;
                    this.backbtn = false;
                } else {
                    this.nextbtn = true;
                    this.backbtn = true;
                }

            }
            this.CurrentSNo = SNo;
            this.getschquestionforexambyid(SNo);
        },
        prevqtn() {
            let SNo = this.CurrentSNo;
            SNo--;
            let MaxNo = this.NoofQuestiontoDisplayval;
            if (SNo < 1) {
                SNo = 1;
            }
            if (SNo == MaxNo) {
                this.nextbtn = false;
                this.backbtn = true;
            }
            if (SNo >= 1 && SNo <= MaxNo) {
                if (SNo == 1) {
                    this.nextbtn = true;
                    this.backbtn = false;
                } else {
                    this.nextbtn = true;
                    this.backbtn = true;

                }
                this.CurrentSNo = SNo;
                this.getschquestionforexambyid(SNo);
            }
        },
        submitqtn() {
            this.Openexam = false;
            this.StartExam = false;
            this.Openquestion = false;
            this.AccessCodeval = "";
            this.RegCodeval="";
            this.Question = null;
            this.Timerval = 0;
            swal({
                title: "Success!",
                text: 'Submitted successfully',
                icon: "success",
                button: "Ok",
            });
        },
        verifyentrancestudent: function () {
            this.onloader = 'flex'
            this.$store.dispatch('verifyentrancestudent', this.RegCodeval)
                .then(resp => {
                    if (resp != null) {
                        this.FirstNameval = resp.FirstName;
                        this.MiddleNameval = resp.MiddleName;
                        this.LastNameval = resp.LastName;
                        this.Openexam = true;                        
                    } else {
                        swal({
                            title: "Oops!",
                            text: 'Invalid Registration Code',
                            icon: "error",
                            button: "Ok",
                        });
                    }
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        verifyaccesscode: function () {
            this.onloader = 'flex'
            this.$store.dispatch('verifyentranceaccesscode', this.AccessCodeval)
                .then(resp => {
                    if (resp != null) {
                        this.Sch_Classval = resp.Sch_Class;
                        this.ClassOptionval = resp.ClassOption;
                        this.IsClosedval = resp.IsClosed;
                        this.ClosedDateval = resp.ClosedDate;
                        this.HeaderIdVal = resp.Id;
                        this.ExamPeriodval = this.Timerval = resp.ExamPeriod;
                        this.ExamTimeval = this.formattime(this.ExamPeriodval);
                        this.NoofQuestiontoDisplayval = resp.NoofQuestiontoDisplay;
                        this.CautionNoteval = resp.CautionNote;
                        this.SubjectNameval = resp.SubjectName;
                        this.SchSessionval = resp.SchSession;
                        this.SchoolTermval = resp.SchoolTerm;
                        this.Openquestion = true;                        
                    } else {
                        swal({
                            title: "Oops!",
                            text: 'Invalid Access Code',
                            icon: "error",
                            button: "Ok",
                        });
                    }
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        processschquestionforexam: function () {
            let HeaderId = this.HeaderIdVal;
            let Maxno = this.NoofQuestiontoDisplayval;
            let username = this.RegCodeval;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                HeaderId,
                Maxno,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('processschquestionforentranceexam', userdata)
                .then(() => {
                    this.getschquestionforexam();
                    this.StartExam = true;
                    this.examtimer();
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none';
                   
                })
        },
        getschquestionforexam: function () {
            let HeaderId = this.HeaderIdVal;
            let Maxno = this.NoofQuestiontoDisplayval;
            let username = this.RegCodeval;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                HeaderId,
                Maxno,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getschquestionforentranceexam', userdata)
                .then((resp) => {
                    if (resp != null) {
                        this.Question = resp;
                        this.getschquestionforexambyid(1);
                    }
                })
                .catch(err => {
                    console(err);
                    this.onloader = 'none'
                   
                })
        },
        getschquestionforexambyid: function (Id) {
            this.ChkOptionA = false;
            this.ChkOptionB = false;
            this.ChkOptionC = false;
            this.ChkOptionD = false;
            let HeaderId = this.HeaderIdVal;
            let RegCode = this.RegCodeval;
            let userdata = {
                Id,
                HeaderId,
                RegCode
            }
            this.onloader = 'flex'
            this.$store.dispatch('getschquestionforentranceexambyid', userdata)
                .then((resp) => {
                    if (resp != null) {
                        this.QuestionSelectedId = resp.Id;
                        this.CurrentQuestionId = resp.QuestionId;
                        this.CurrentQuestion = resp.Question;
                        this.CurrentImage = resp.ImagePath;
                        this.CurrentOptionA = resp.OptionA;
                        this.CurrentOptionAId = resp.OptionAId;
                        this.CurrentOptionB = resp.OptionB;
                        this.CurrentOptionBId = resp.OptionBId;
                        this.CurrentOptionC = resp.OptionC;
                        this.CurrentOptionCId = resp.OptionCId;
                        this.CurrentOptionD = resp.OptionD;
                        this.CurrentOptionDId = resp.OptionDId;
                        this.CurrentSNo = resp.SNo;
                        this.SelectedAnswerId = resp.SelectedAnswer;
                        if (this.SelectedAnswerId != null) {
                            if (this.CurrentOptionAId == this.SelectedAnswerId) {
                                this.ChkOptionA = true;
                                this.ChkOptionB = false;
                                this.ChkOptionC = false;
                                this.ChkOptionD = false;
                            }
                            if (this.CurrentOptionBId == this.SelectedAnswerId) {
                                this.ChkOptionA = false;
                                this.ChkOptionB = true;
                                this.ChkOptionC = false;
                                this.ChkOptionD = false;
                            }
                            if (this.CurrentOptionCId == this.SelectedAnswerId) {
                                this.ChkOptionA = false;
                                this.ChkOptionB = false;
                                this.ChkOptionC = true;
                                this.ChkOptionD = false;
                            }
                            if (this.CurrentOptionDId == this.SelectedAnswerId) {
                                this.ChkOptionA = false;
                                this.ChkOptionB = false;
                                this.ChkOptionC = false;
                                this.ChkOptionD = true;
                            }
                        }

                    }
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                   
                })
        },
        processsanswerA: function () {
            let AnswerId = 0;
            if (this.ChkOptionA == true) {
                AnswerId = this.CurrentOptionAId;
                this.ChkOptionB = false;
                this.ChkOptionC = false;
                this.ChkOptionD = false;
            }
            let Id = this.QuestionSelectedId;
            let HeaderId = this.HeaderIdVal;
            let QuestionId = this.CurrentQuestionId;
            let TimeSpent = (this.ExamPeriodval - this.Timerval);
            if (TimeSpent < 1) {
                TimeSpent = 1;
            }
            let username = this.RegCodeval;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                HeaderId,
                QuestionId,
                AnswerId,
                TimeSpent,
                username,
                accesskey
            }

            this.onloader = 'flex'
            this.$store.dispatch('processsentranceanswer', userdata)
                .then(() => {
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    let SNo = this.CurrentSNo;
                    this.getschquestionforexambyid(SNo);
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    this.onloader = 'none'
                   
                })
        },
        processsanswerB: function () {
            let AnswerId = 0;
            if (this.ChkOptionB == true) {
                AnswerId = this.CurrentOptionBId;
                this.ChkOptionA = false;
                this.ChkOptionC = false;
                this.ChkOptionD = false;
            }
            let Id = this.QuestionSelectedId;
            let HeaderId = this.HeaderIdVal;
            let QuestionId = this.CurrentQuestionId;
            let TimeSpent = (this.ExamPeriodval - this.Timerval);
            if (TimeSpent < 1) {
                TimeSpent = 1;
            }
            let username = this.RegCodeval;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                HeaderId,
                QuestionId,
                AnswerId,
                TimeSpent,
                username,
                accesskey
            }

            this.onloader = 'flex'
            this.$store.dispatch('processsentranceanswer', userdata)
                .then(() => {
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    let SNo = this.CurrentSNo;
                    this.getschquestionforexambyid(SNo);
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    this.onloader = 'none'
                   
                })
        },
        processsanswerC: function () {
            let AnswerId = 0;
            if (this.ChkOptionC == true) {
                AnswerId = this.CurrentOptionCId;
                this.ChkOptionB = false;
                this.ChkOptionA = false;
                this.ChkOptionD = false;
            }
            let Id = this.QuestionSelectedId;
            let HeaderId = this.HeaderIdVal;
            let QuestionId = this.CurrentQuestionId;
            let TimeSpent = (this.ExamPeriodval - this.Timerval);
            if (TimeSpent < 1) {
                TimeSpent = 1;
            }
            let username = this.RegCodeval;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                HeaderId,
                QuestionId,
                AnswerId,
                TimeSpent,
                username,
                accesskey
            }

            this.onloader = 'flex'
            this.$store.dispatch('processsentranceanswer', userdata)
                .then(() => {
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    let SNo = this.CurrentSNo;
                    this.getschquestionforexambyid(SNo);
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    this.onloader = 'none'
                    
                })
        },
        processsanswerD: function () {
            let AnswerId = 0;
            if (this.ChkOptionD == true) {
                AnswerId = this.CurrentOptionDId;
                this.ChkOptionB = false;
                this.ChkOptionC = false;
                this.ChkOptionA = false;
            }
            let Id = this.QuestionSelectedId;
            let HeaderId = this.HeaderIdVal;
            let QuestionId = this.CurrentQuestionId;
            let TimeSpent = (this.ExamPeriodval - this.Timerval);
            if (TimeSpent < 1) {
                TimeSpent = 1;
            }
            let username = this.RegCodeval;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                HeaderId,
                QuestionId,
                AnswerId,
                TimeSpent,
                username,
                accesskey
            }

            this.onloader = 'flex'
            this.$store.dispatch('processsentranceanswer', userdata)
                .then(() => {
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    let SNo = this.CurrentSNo;
                    this.getschquestionforexambyid(SNo);
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.ChkOptionA = false;
                    this.ChkOptionB = false;
                    this.ChkOptionC = false;
                    this.ChkOptionD = false;
                    this.onloader = 'none'
                    
                })
        },
        formattime: function (time) {
            let hrs = ~~(time / 3600);
            let mins = ~~((time % 3600) / 60);
            let secs = ~~time % 60;
            let ret = "";
            if (hrs > 0) {
                ret += "" + hrs + " Hrs : " + (mins < 10 ? "0" : "");
            }
            ret += "" + mins + " Min : " + (secs < 10 ? "0" : "");
            ret += "" + secs + " Sec";
            return ret;
        },
        examtimer: function () {
            this.Timerval = this.ExamPeriodval;
            if (this.StartExam == false) {
                this.Timerval = 0;
            }
            var timer = null;
            clearInterval(timer);
            timer = setInterval(() => {
                if (this.Timerval > 0) {
                    if (this.Timerval == 30) {
                        swal({
                            title: "Oops!",
                            text: 'You have less than 30 seconds',
                            icon: "error",
                            button: "Ok",
                        });
                    }
                    this.Timerval--
                    this.ExamTimeval = this.formattime(this.Timerval);
                } else {
                    if (this.Timerval == true) {
                        this.submitqtn();
                    }
                    clearInterval(timer);
                }
            }, 1000)

        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    },
    mounted() {

    }
};
</script>

<style></style>
