<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/school/environment-settings" class="text-white">
         <i class="fa fa-ellipsis-v px-2"></i>Settings
      </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i> Session</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary">
              <div class="card-header">Registration</div>
              <div class="card-body">
                <form role="form" @submit.prevent="submitdata">
                  <div class="form-inline">
                    <div class="text-center">
                      <base-input v-model="schsessionval" placeholder="Enter Session"></base-input>
                      <button type="submit" class="btn btn-success">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="justify-content-end form-inline">
              <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
                v-model="searchval">
              </base-input>
            </div>
            <div class="table-responsive mt-2">
              <table class="table table-dark table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>Session</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchedResultQuery" :key="item.Id">
                    <td>{{ item.SchSession }}</td>
                    <td>
                      <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import SessionMixin from "@/mixins/Session.mixin"
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "Session",
  mixins: [SessionMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      schsessionval: "",
      process: "N",
      SessionItems: null,
      openform: false,
      searchval: '',
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.SessionItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.SchSession.toLowerCase().includes(v)
            );
        });
      } else {
        return this.SessionItems;
      }
    }
  },
  methods: {
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getsessionbyid", Id)
        .then(resp => {
          this.schsessionval = resp.SchSession;
          this.process = "U";
          this.Idval = resp.Id;
          this.openform = true;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
    submitdata: function () {
      let Id = this.Idval;
      let schsession = this.schsessionval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        Id,
        schsession,
        processop,
        username,
        accesskey
      };

      this.onloader = "flex";
      this.$store
        .dispatch("sessionregistration", payload)
        .then(resp => {
          this.SessionItems = resp;
          this.schsessionval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted Successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },

  },
  mounted() {
    const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
    if (
      userDetails.isSchoolStaff == false
    ) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    } else {
      this.getsession();
    }
  }
};
</script>

<style></style>
