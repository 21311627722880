<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/school/library" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Library
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Library Book</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary">
                <h3 class=" card-header">Registration</h3>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="form-group">
                        <label>Enter Book No</label>
                        <input v-model="BookNoval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="BookNoval">
                        <label>Enter Title</label>
                        <input v-model="BookTitleval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="BookTitleval">
                        <label>Enter Book Author</label>
                        <input v-model="BookAuthorval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="BookAuthorval">
                        <label>Enter Publisher Name</label>
                        <input v-model="BookPublisherval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="BookPublisherval">
                        <label>Enter ISBN No.</label>
                        <input v-model="BookIBNNoval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="BookIBNNoval">
                        <label>Enter Book Edition</label>
                        <input v-model="BookEditionval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="BookEditionval">
                        <label>Enter Shelf No.</label>
                        <input v-model="ShelfNoval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="ShelfNoval">
                        <label>Select Book Category</label>
                        <select v-model="BookCategoryIdval" class="form-control">
                          <option value="0"></option>
                          <option v-for="item in LibraryCategoryItems" :key="item.Id" v-bind:value="item.Id">
                            {{ item.CategoryName }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="BookCategoryIdval">
                        <img v-if="uploadedimage" height="100" :src="uploadedimage" />
                        <img v-else height="100" src="img/brand/empty.png" />
                        <input type="file" id="file" ref="file" class="form-control" v-on:change="handleFileUpload()" />
                        <br />
                        <button class="btn btn-outline-dark" @click.prevent="submitFile()">
                          <i class="ni ni-cloud-upload-96"></i>Upload
                        </button>
                      </div>
                      <div class="text-center card-footer mb-3" v-if="BookCategoryIdval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="justify-content-end form-inline mt-3">
                <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
                  v-model="searchval">
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table class="table table-dark table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Book No</th>
                      <th>Book Title</th>
                      <th>Book Publisher</th>
                      <th>Category</th>
                      <th>ISBN No</th>
                      <th>Author</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in searchedResultQuery" :key="item.Id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.BookNo }}</td>
                      <td>{{ item.BookTitle }}</td>
                      <td>{{ item.BookPublisher }}</td>
                      <td>{{ item.CategoryName }}</td>
                      <td>{{ item.BookISBNNo }}</td>
                      <td>{{ item.BookAuthor }}</td>
                      <td>
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import LibraryCategoryMixin from "@/mixins/LibraryCategory.mixin"
import LibraryBookMixin from "@/mixins/LibraryBook.mixin"
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "LibraryBook",
  mixins: [LibraryCategoryMixin, LibraryBookMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      BookIBNNoval: "",
      BookNoval: "",
      BookTitleval: "",
      BookAuthorval: "",
      BookEditionval: "",
      BookCategoryIdval: 0,
      BookPublisherval: "",
      ShelfNoval: "",
      ImagePathval: null,
      searchval: "",
      LibraryCategoryItems: null,
      LibraryBookItems: null,
      file: "",
      uploadedimage: "",
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.LibraryBookItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.BookNo.toLowerCase().includes(v) ||
                item.BookISBNNo.toLowerCase().includes(v) ||
                item.BookTitle.toLowerCase().includes(v) ||
                item.BookAuthor.toLowerCase().includes(v) ||
                item.BookPublisher.toLowerCase().includes(v) ||
                item.CategoryName.toLowerCase().includes(v)
            );
        });
      } else {
        return this.LibraryBookItems;
      }
    }
  },
  methods: {
    edititem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBook/GetByIdLibraryBook", Id)
        .then(resp => {
          this.Idval = Id;
          this.BookIBNNoval = resp.BookISBNNo;
          this.BookNoval = resp.BookNo;
          this.BookTitleval = resp.BookTitle;
          this.BookAuthorval = resp.BookAuthor;
          this.BookEditionval = resp.BookEdition;
          this.BookCategoryIdval = resp.BookCategoryId;
          this.BookPublisherval = resp.BookPublisher;
          this.ShelfNoval = resp.ShelfNo;
          if (resp.ImagePath != null) {
            this.uploadedimage =
              resp.ImagePath;
          }
          this.ImagePathval = resp.ImagePath;
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let BookIBNNo = this.BookIBNNoval;
      let BookNo = this.BookNoval;
      let BookTitle = this.BookTitleval;
      let BookAuthor = this.BookAuthorval;
      let BookEdition = this.BookEditionval;
      let BookCategoryId = this.BookCategoryIdval;
      let BookPublisher = this.BookPublisherval;
      let ShelfNo = this.ShelfNoval;
      let ImagePath = this.ImagePathval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        BookIBNNo,
        BookNo,
        BookTitle,
        BookAuthor,
        BookEdition,
        BookCategoryId,
        BookPublisher,
        ShelfNo,
        ImagePath,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBook/ProcessLibraryBook", userdata)
        .then(resp => {
          this.LibraryBookItems = resp;
          this.BookIBNNoval = "";
          this.BookNoval = "";
          this.BookTitleval = "";
          this.BookAuthorval = "";
          this.BookEditionval = "";
          this.BookCategoryIdval = "";
          this.BookPublisherval = "";
          this.ShelfNoval = "";
          this.ImagePathval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.ImagePathval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then(resp => {
          this.ImagePathval = resp;
          this.uploadedimage = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    }
  }
};
</script>

<style></style>
