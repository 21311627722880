<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <card header-classes="bg-transparent">
            <div class="row">
                <div class="col-md-6">
                    <div class="card bg-secondary">
                        <div class="card-header">
                            <h4>Full Term Record</h4>
                        </div>
                        <div class="card-body">
                            <div class="form-horizontal">
                                <form role="form" @submit.prevent="submitdata">
                                    <div class="card bg-gradient-success">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Session</label>
                                                        <select v-model="SessionIdval" class="form-control"
                                                            @change="getregterm()">
                                                            <option value=""></option>
                                                            <option v-for="item in Sessionitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SchSession }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Term</label>
                                                        <select v-model="TermIdval" class="form-control"
                                                            @change="getclassinsubjectassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in termitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SchTerm }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Class</label>
                                                        <select v-model="ClassIdval" class="form-control"
                                                            @change="getoptioninsubjectassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in classitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.Sch_Class }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Option</label>
                                                        <select v-model="OptionIdval" class="form-control"
                                                            @change="getarminsubjectassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in optionitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.ClassOption }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Arm</label>
                                                        <select v-model="ArmIdval" class="form-control"
                                                            @change="getsubjectinsubjectassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in armitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SectionName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Subject</label>
                                                        <select v-model="SubjectIdval" class="form-control"
                                                            @change="GetStudentByArm()">
                                                            <option value="0"></option>
                                                            <option v-for="item in subjectitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SchSubject }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Student</label>
                                                        <select v-model="StudentIdval" class="form-control"
                                                            @change="GetMarkRecordByStudentId()">
                                                            <option value="0"></option>
                                                            <option v-for="item in studentItem" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.StudentName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">CA</label>
                                                        <input class="form-control" v-model="CAval">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Exam</label>
                                                        <input class="form-control" v-model="Examval">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center mb-3">
                                            <button type="submit" class="btn btn-primary my-4">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6" v-if="resultItem">
                    <div class="table-responsive mt-2">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Student Name</th>
                                    <th>CA</th>
                                    <th>Exam</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in resultItem" :key="item.Id">
                                    <td> <img v-if="item.Passport" :src="item.Passport" class="rounded" alt="" height="30" />
                      <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="30" /></td>
                                    <td>{{ item.StudentName }}</td>
                                    <td>{{ item.CA }}</td>
                                    <td>{{ item.Exam }}</td>
                                    <td>{{ item.Exam + item.CA }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <b-editable-table bordered class="editable-table" :editMode="'row'" v-model="resultItem"
                            :fields="fields" @input-change="handleInput">
                        </b-editable-table> -->
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
// import BEditableTable from "bootstrap-vue-editable-table";


export default {
    name: 'MarkRecord',
    mixins: [CheckApiResponse],
    components: {
        PageSpinner, //BEditableTable
    },
    data() {
        return {
            dataid: 1,
            ClassIdval: '',
            OptionIdval: '',
            ArmIdval: '',
            SessionIdval: '',
            TermIdval: '',
            SubjectIdval: '',
            StudentIdval: '',
            CAval: '',
            Examval: '',
            Sessionitems: null,
            termitems: null,
            classitems: null,
            optionitems: null,
            armitems: null,
            subjectitems: null,
            studentItem: null,
            onloader: 'none',
            resultItem: null,
           
        }
    },
    methods: {       
        edititem() {
            let Id = 1;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let SubjectId = this.SubjectIdval;
            let processop = 'U';
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            this.Examval = '';
            this.CAval = '';
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudId,
                SessionId,
                TermId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmarkrecordbyid', userdata)
                .then(resp => {
                    if (resp != null) {
                        this.CAval = resp.CA;
                        this.Examval = resp.Exam;
                    }
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    console.log(err);
                    //this.$store.dispatch('logout')
                })
        },
        submitdata() {
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let SubjectId = this.SubjectIdval;
            let StudentId = this.StudentIdval;
            let CA = this.CAval;
            let Exam = this.Examval;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudentId,
                CA,
                Exam,
                SessionId,
                TermId,
                Comment,
                username,
                accesskey
            }           
            this.onloader = 'flex'
            this.$store.dispatch('markrecordregistration', userdata)
                .then(() => {
                    this.GetMarkRecordBySubject();
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregsession() {
            this.studentItem = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.subjectitems = null;
            this.resultItem = null;
            this.CAval = '';
            this.Examval = '';
            this.SessionIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getregsession')
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/home')
                })
        },
        getregterm() {
            this.CAval = '';
            this.Examval = '';
            this.classitems = null;
            this.studentItem = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.resultItem = null;
            this.CAval = '';
            this.Examval = '';
            this.TermIdval = 0;
            let SessionId = this.SessionIdval;
            if (SessionId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getregterm', SessionId)
                    .then(resp => {
                        this.termitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getclassinsubjectassigned() {
            this.CAval = '';
            this.Examval = '';
            this.classitems = null;
            this.studentItem = null;
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.resultItem = null;
            this.ClassIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getclassinsubjectassigned')
                .then(resp => {
                    this.classitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getoptioninsubjectassigned() {
            this.CAval = '';
            this.Examval = '';
            this.studentItem = null;
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.resultItem = null;
            this.OptionIdval = 0;
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getoptioninsubjectassigned', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getarminsubjectassigned() {
            this.CAval = '';
            this.Examval = '';
            this.studentItem = null;
            this.armitems = null;
            this.subjectitems = null;
            this.resultItem = null;
            this.ArmIdval = 0;
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getarminsubjectassigned', OptionId)
                    .then(resp => {
                        this.armitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getsubjectinsubjectassigned() {
            this.CAval = '';
            this.Examval = '';
            this.studentItem = null;
            this.subjectitems = null;
            this.resultItem = null;
            this.SubjectIdval = 0;
            let ArmId = this.ArmIdval;
            if (ArmId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getschsubjectinsubjectassigned', ArmId)
                    .then(resp => {
                        this.subjectitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        GetStudentByArm() {
            this.CAval = '';
            this.Examval = '';
            this.resultItem = null;
            this.GetMarkRecordBySubject();
            let ArmId = this.ArmIdval;
            let ClassId = this.ClassIdval;
            if (ArmId > 0) {
                let userdata = {
                    ArmId,
                    ClassId
                }
                this.onloader = 'flex'
                this.$store.dispatch('getstudentbyarm', userdata)
                    .then(resp => {
                        this.studentItem = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        GetMarkRecordByStudentId() {
            this.CAval = '';
            this.Examval = '';
            let StudentId = this.StudentIdval;
            let SubjectId = this.SubjectIdval;
            let ArmId = this.ArmIdval;
            let TermId = this.TermIdval;
            let SessionId = this.SessionIdval;
            if (StudentId > 0) {
                let userdata = {
                    StudentId,
                    SubjectId,
                    ArmId,
                    TermId,
                    SessionId
                }
                this.onloader = 'flex'
                this.$store.dispatch('GetMarkRecordByStudentId', userdata)
                    .then(resp => {
                        if (resp != null) {
                            this.Examval = resp.Exam;
                            this.CAval = resp.CA;
                        }
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        GetMarkRecordBySubject() {
            this.resultItem = null;
            let SubjectId = this.SubjectIdval;
            let ArmId = this.ArmIdval;
            let TermId = this.TermIdval;
            let SessionId = this.SessionIdval;
            if (SubjectId > 0) {
                let userdata = {
                    SubjectId,
                    ArmId,
                    TermId,
                    SessionId
                }
                this.onloader = 'flex'
                this.$store.dispatch('GetMarkRecordBySubject', userdata)
                    .then(resp => {
                        this.resultItem = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        frontEndDateFormat(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat(date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
    },
    mounted() {
        this.getregsession();
    }
};
</script>

<style>
table.editable-table {
    margin: auto;
}

table.editable-table td {
    vertical-align: middle;
}

.editable-table .data-cell {
    padding: 8px;
    vertical-align: middle;
}


.ca-col {
    width: 100px;
}

.exam-col {
    width: 100px;
}
</style>