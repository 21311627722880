<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/school/library" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Library
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Library Book Stock</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary">
                <h3 class=" card-header">Registration</h3>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form">
                      <div class="form-group">
                        <label>Search and select Book</label>
                        <input v-model="searchBookval" @keyup="openBookItem" class="form-control"
                          placeholder="Search Here..." required />
                        <div class="table-responsive mt-2" v-if="IsBookItemOpen">
                          <table class="table table-light table-bordered table-striped table-hover">
                            <tbody>
                              <tr v-for="item in searchedBookResultQuery" :key="item.Id">
                                <td>
                                  <button class="text-left btn btn-block" v-on:click.prevent="selectBook(item.Id)">
                                    <div class="row">
                                      <div class="col">
                                        <img v-if="item.ImagePath" :src="$store.state.auth.baseurl +
      item.ImagePath.substr(1)
      " alt="" height="40" />
                                        <img v-else src="img/brand/empty.png" class="img-fluid" alt="" height="40" />
                                      </div>
                                      <div class="col">
                                        {{ item.BookTitle }} <br />
                                        ({{ item.BookAuthor }})
                                      </div>
                                    </div>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-group" v-if="BookIdval">
                        <label>How many</label>
                        <input type="number" v-model="NoofCopiesval" class="form-control" required />
                      </div>
                      <div class="form-group" v-if="NoofCopiesval">
                        <label>Date</label>
                        <input type="date" v-model="RegDateval" class="form-control" required />
                      </div>
                      <div class="text-center card-footer mb-3" v-if="NoofCopiesval">
                        <base-button type="success" v-on:click.prevent="submitdata()">Submit</base-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="justify-content-end form-inline mt-3">
                <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
                  v-model="searchval">
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table class="table table-dark table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Book Title</th>
                      <th>Author</th>
                      <th>Quantity</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in searchedResultQuery" :key="item.Id">
                      <td>{{ item.BookTitle }}</td>
                      <td>{{ item.BookAuthor }}</td>
                      <td>{{ item.NoofCopies }}</td>
                      <td>{{ item.RegDate | formatDate }}</td>
                      <td>
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import LibraryBookMixin from "@/mixins/LibraryBook.mixin";
import DateFormaterMixin from "@/mixins/DateFormater.mixin";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "LibraryBookStock",
  mixins: [LibraryBookMixin, DateFormaterMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      NoofCopiesval: "",
      BookIdval: 0,
      RegDateval: "",
      searchval: "",
      searchBookval: "",
      LibraryBookItems: null,
      LibraryBookStockItems: null,
      IsBookItemOpen: false,
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.LibraryBookStockItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.BookTitle.toLowerCase().includes(v) ||
                item.BookAuthor.toLowerCase().includes(v)
            );
        });
      } else {
        return this.LibraryBookStockItems;
      }
    },
    searchedBookResultQuery() {
      if (this.searchBookval) {
        return this.LibraryBookItems.filter(item => {
          return this.searchBookval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.BookTitle.toLowerCase().includes(v) ||
                item.BookAuthor.toLowerCase().includes(v)
            );
        });
      } else {
        return this.LibraryBookItems;
      }
    }
  },
  methods: {
    openBookItem() {
      this.IsBookItemOpen = false;
      if (this.searchBookval) {
        this.IsBookItemOpen = true;
      }
    },
    selectBook(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBook/GetByIdLibraryBook", Id)
        .then(resp => {
          this.BookIdval = Id;
          this.searchBookval = resp.BookTitle;
          this.IsBookItemOpen = false;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    edititem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookStock/GetByIdLibraryBookStock", Id)
        .then(resp => {
          this.Idval = Id;
          this.NoofCopiesval = resp.NoofCopies;
          this.BookIdval = resp.BookId;
          this.RegDateval = this.frontEndDateFormat(resp.RegDate);
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let NoofCopies = this.NoofCopiesval;
      let BookId = this.BookIdval;
      let RegDate = this.RegDateval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        NoofCopies,
        BookId,
        RegDate,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookStock/ProcessLibraryBookStock", userdata)
        .then(resp => {
          this.LibraryBookStockItems = resp;
          this.NoofCopiesval = "";
          this.BookIdval = 0;
          this.RegDateval = null;
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetLibraryBookStock() {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookStock/GetLibraryBookStock")
        .then(resp => {
          this.LibraryBookStockItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.GetLibraryBookStock();
    }
  }
};
</script>

<style></style>
