<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link>
            <router-link to="/school/exam-setup" class="text-white">
                <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>CBT </span></router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>CBT Result</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">

            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="table-responsive mt-2">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Total Attempt</th>
                                    <th>Score</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in resultitem" :key="item.Id">
                                    <td>{{ item.StudentName }}</td>
                                    <td>{{ item.TotalAttempt }}</td>
                                    <td>
                                        {{ item.CorrectAnswer }}
                                    </td>
                                    <td>
                                        <button class="btn btn-secondary btn-sm"
                                            v-on:click="retakeExam(item.StudId)">Allow to Retake</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </div>

            <!-- End charts-->

        </div>

    </div>
</template>

<script>
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'studentanswer',
    components: {
    PageSpinner
  },
    data() {
        return {
            accessDetails:null,
            HeaderId: localStorage.getItem('SchcbtheaderId') || '',
            resultitem: null,
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {
        getresult: function () {
            let Id = this.HeaderId;
            this.onloader = 'flex'
            this.$store.dispatch('schexamresult', Id)
                .then((resp) => {
                    this.resultitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        retakeExam: function (StudId) {
            let Id = this.HeaderId;
            let userdata = {
                HeaderId: Id,
                StudentId: StudId
            }
            this.onloader = 'flex'
            this.$store.dispatch('RetakeExamforStudent', userdata)
                .then(() => {
                    this.getresult()
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted for retake successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch("auth/Logout");
            this.$router.push("/login");
        } else {
            if (this.$store.state.schooldata.schoolinfo.StatusId == 10) {
                this.$router.push("/tccbtheader");
            } else {
                this.getresult();
            }
        }

    }
};
</script>

<style></style>
