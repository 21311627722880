<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link><i class="ni ni-bold-right text-white"></i>
            <router-link to="/school/sms-sender" class="text-white">
               SMS
            </router-link>
            <span class="text-white"><i class="ni ni-bold-right"></i>SMS to Guradian</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">

            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="row">
                        <div class="col-md-6">
                            <div slot="header" class="row align-items-center">
                                <h6 class="text-uppercase text-muted ls-1 mb-1"></h6>
                            </div>
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-body">
                                    <div class="form-horizontal">
                                        <div class="card bg-gradient-success">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Class</label>
                                                            <select v-model="ClassIdval" class="form-control"
                                                                @change="getoption()">
                                                                <option value="0"></option>
                                                                <option v-for="item in classitems" :key="item.Id"
                                                                    v-bind:value="item.Id">
                                                                    {{ item.Sch_Class }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Option</label>
                                                            <select v-model="OptionIdval" class="form-control"
                                                                @change="getarm()">
                                                                <option value="0"></option>
                                                                <option v-for="item in optionitems" :key="item.Id"
                                                                    v-bind:value="item.Id">
                                                                    {{ item.ClassOption }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Arm</label>
                                                            <select v-model="ArmIdval" class="form-control"
                                                                @change="getstudentbyarm()">
                                                                <option value="0"></option>
                                                                <option v-for="item in armitems" :key="item.Id"
                                                                    v-bind:value="item.Id">
                                                                    {{ item.SectionName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Student</label>
                                                            <select v-model="StudIdval" class="form-control"
                                                                @change="getsmsguardianbystudent()">
                                                                <option value="0"></option>
                                                                <option v-for="item in studentitems" :key="item.Id"
                                                                    v-bind:value="item.Id">
                                                                    {{ item.StudentName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Guardian</th>
                                            <th>Phone No.</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in ssmsitem" :key="item.Id">
                                            <td>{{ item.ContactName }}</td>
                                            <td v-html="item.PhoneNo"></td>
                                            <td><button class="btn btn-danger btn-sm"
                                                    v-on:click="deletesmstempbyid(item.Id)"><i class="fa fa-remove"></i>
                                                    Delete</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-body">
                                    <div class="form-horizontal">
                                        <form role="form" @submit.prevent="submitdata">
                                            <div class="card bg-gradient-success">
                                                <div class="card-body">
                                                    <label class="text-white">Enter Message</label>
                                                    <textarea v-model="MessageBodyval" @keyup="countchar()"
                                                        @keydown="countchar()" @keypress="countchar()"
                                                        class="form-control"></textarea>
                                                    <span class="text-white">{{ Detailval }}</span>
                                                </div>
                                            </div>
                                            <div class="text-center mb-3">
                                                <button type="submit" class="btn btn-primary my-4">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </card>
            </div>

            <!-- End charts-->

        </div>

    </div>
</template>

<script>
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'classattendance',
    components: {
        PageSpinner
    },
    data() {
        return {
            accessDetails: null,
            dataid: 1,
            ClassIdval: '',
            OptionIdval: '',
            ArmIdval: '',
            StudIdval: '',
            MessageBodyval: '',
            Detailval: '',
            optionitems: null,
            armitems: null,
            classitems: null,
            studentitems: null,
            ssmsitem: null,
            process: 'N',
            onloader: 'none'
        }
    },

    methods: {
        countchar: function () {
            this.Detailval = '';
            var n = this.MessageBodyval.length;
            var smspage = 1;
            var md = Math.floor(n / 160);

            if (md > 0) {
                smspage = (smspage * md) + 1;
            }
            if (smspage > 2) {
                this.Detailval = 'ALERT => : Maximum pages to be sent through this app is 2';
                swal({
                    title: "Oops!",
                    text: 'Maximum pages to be sent through this app is 2',
                    icon: "error",
                    button: "Ok",
                });

            } else {
                this.Detailval = n + ' Total characters, ' + smspage + ' Total page(s)';
            }
        },
        deletesmstempbyid: function (Id) {
            this.onloader = 'flex'
            this.process = 'D';
            this.$store.dispatch('deletesmstempbyid', Id)
                .then(resp => {
                    this.ssmsitem = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let PhoneNos = '00';
            let Description = 'Message to guardian';
            let MessageBody = this.MessageBodyval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                PhoneNos,
                Description,
                MessageBody,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('processsms', userdata)
                .then((resp) => {
                    this.ssmsitem = resp;
                    this.studentitems = null;
                    this.armitems = null;
                    this.optionitems = null;
                    this.process = 'N';
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Sent successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getsmsguardianbyschool: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getsmsguardianbyschool')
                .then((resp) => {
                    this.ssmsitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })

        },
        getsmsguardianbyclass: function () {

            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getsmsguardianbyclass', ClassId)
                    .then((resp) => {
                        this.ssmsitem = resp;
                        this.onloader = 'none'
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getsmsguardianbyoption: function () {

            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getsmsguardianbyoption', OptionId)
                    .then((resp) => {
                        this.ssmsitem = resp;
                        this.onloader = 'none'
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getsmsguardianbyarm: function () {

            let ArmId = this.ArmIdval;
            if (ArmId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getsmsguardianbyarm', ArmId)
                    .then((resp) => {
                        this.ssmsitem = resp;
                        this.onloader = 'none'
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getsmsguardianbystudent: function () {

            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getsmsguardianbystudent', StudId)
                    .then((resp) => {
                        this.ssmsitem = resp;
                        this.onloader = 'none'
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getclass: function () {
            this.classitems = null;
            this.studentitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.studentarchievementitem = null;
            this.onloader = 'flex'
            this.$store.dispatch('getclass')
                .then(resp => {
                    this.classitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getoption: function () {
            this.studentitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.studentarchievementitem = null;
            this.OptionIdval = 0;
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.onloader = 'flex'
                this.getsmsguardianbyclass();
                this.$store.dispatch('getoption', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getarm: function () {
            this.studentitems = null;
            this.armitems = null;
            this.studentarchievementitem = null;
            this.ArmIdval = 0;
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.onloader = 'flex'
                this.getsmsguardianbyoption();
                this.$store.dispatch('getarm', OptionId)
                    .then(resp => {
                        this.armitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getstudentbyarm: function () {
            this.studentitems = null;
            this.studentarchievementitem = null;
            this.StudIdval = 0;
            let Id = 1;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let SubjectId = 1;
            let StudId = 1;
            let SessionId = 1;
            let TermId = 1;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            if (ArmId > 0) {
                this.getsmsguardianbyarm();
                let userdata = {
                    Id,
                    ClassId,
                    OptionId,
                    ArmId,
                    SubjectId,
                    StudId,
                    SessionId,
                    TermId,
                    processop,
                    username,
                    accesskey
                }
                this.onloader = 'flex'
                this.$store.dispatch('getstudentbyarm', userdata)
                    .then(resp => {
                        this.studentitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        } else {
            this.getclass();
            this.getsmsguardianbyschool();
        }

    }
};
</script>

<style></style>
