<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i> Dashboard</router-link>
        <router-link to="/school/environment-settings" class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Settings </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i> Class</span>
    </base-header>
    <modal :show.sync="modals.newModal" modal-classes="modal-dialog-centered modal-xl">
      <PageSpinner :onloader="onloader" />
      <div class="card bg-secondary border-0 mb-0">
        <div class="card-body">
          <div class="card bg-secondary mb-0">
            <div class="card-header">
              <h3 class="card-title">Registration</h3>
            </div>
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitclass" class="row">
                  <div class="form-group col-md-3">
                    <label>Category</label>
                    <select v-model="CategoryIdval" class="form-control">
                      <option v-for="item in classcategory" :key="item.Id" v-bind:value="item.Id">
                        {{ item.CategoryName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Class Name</label>
                    <input v-model="SchoolClassval" class="form-control" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Exam Score</label>
                    <input v-model="ExamScoreval" class="form-control" type="number" min="0" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>CA Score</label>
                    <input v-model="CAScoreval" class="form-control" type="number" min="0" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Mid Term Score</label>
                    <input v-model="MidTermScoreval" class="form-control" type="number" min="0" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Topic Score</label>
                    <input v-model="TopicScoreval" class="form-control" type="number" min="0" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="default" @click="modals.newModal = false">Cancel
        </base-button>
        <base-button type="success" @click.prevent="submitClassRegistration()">Submit
        </base-button>
      </template>
    </modal>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="justify-content-end form-inline">
          <button class="btn btn-success" v-on:click="openNew()">
            <i class="fa fa-edit"></i> New
          </button>
          <select v-model="pageSize" class="btn btn-secondary">
            <option>5</option>
            <option>10</option>
            <option>50</option>
            <option>100</option>
          </select>
          <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
            v-model="searchval" @keyup.enter="GetAllClass">
          </base-input>
        </div>
        <div class="table-responsive mt-2">         
          <table class="table table-dark table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Category</th>
                <th>Class</th>
                <th>Exam Score</th>
                <th>CA Score</th>
                <th>Mid Term Score</th>
                <th>Topic Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in classitems" :key="item.Id">
                <td>
                  <button class="btn btn-secondary btn-sm" v-on:click="GetByIdClass(item.classId)">
                    <i class="fa fa-edit"></i> Edit
                  </button>
                </td>
                <td>{{ item.categoryName }}</td>
                <td>{{ item.className }}</td>
                <td>{{ item.examScore }}</td>
                <td>{{ item.caScore }}</td>
                <td>{{ item.midTermScore }}</td>
                <td>{{ item.topicScore }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <div class="text-center">
          <button class="btn-success m-1" @click="prevPage" :disabled="currentPage === 1">Previous</button>
          <span v-for="pageNumber in paginationNumbers" :key="pageNumber">
            <button class="btn-success m-1" v-if="pageNumber === '...'" disabled>...</button>
            <button class="btn-success m-1" v-else @click="goToPage(pageNumber)"
              :class="{ 'active': pageNumber === currentPage }">{{ pageNumber
              }}</button>
          </span>
          <button class="btn-success m-1" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </card>
    </div>
  </div>
</template>

<script>

import PageSpinner from "@/components/PageSpinner"
import Alert from "@/mixins/Alert.mixins"
import ValidateAccessToken from "@/mixins/ValidateAccessToken.mixins"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
import modal from '@/components/Modal'
export default {
  name: "class",
  mixins: [ValidateAccessToken, CheckStaffUser, Alert],
  components: {
    PageSpinner, modal
  },
  data() {
    return {
      accessDetails: null,
      ClassIdval: 0,
      SchoolClassval: "",
      ExamScoreval: "",
      CAScoreval: "",
      MidTermScoreval: "",
      TopicScoreval: "",
      CategoryIdval: "",
      classitems: null,
      classcategory: null,
      currentPage: 1,
      pageSize: 10,
      pageTotal: 10,
      searchval: '',
      IsModified: false,
      onloader: "none",
      modals: {
        newModal: false
      }
    };
  },

  methods: {
    openNew() {
      this.modals.newModal = true
    },
    ClearForm() {
      this.CategoryIdval = 0
      this.SchoolClassval = "";
      this.ExamScoreval = ""
      this.CAScoreval = ""
      this.MidTermScoreval = ""
      this.TopicScoreval = ""
      this.IsModified = false;
    },
    GetByIdClass(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("GetByIdClass", Id)
        .then(resp => {
          let data = resp.data
          this.ClassIdval = Id;
          this.CategoryIdval = data.categoryId;
          this.SchoolClassval = data.className;
          this.ExamScoreval = data.examScore
          this.CAScoreval = data.caScore
          this.MidTermScoreval = data.midTermScoreval
          this.TopicScoreval = data.topicScore
          this.IsModified = true;
          this.onloader = "none";
          this.modals.newModal = true
        })
        .catch(err => {
          this.onloader = "none";
          this.ShowAlertError(err.response.data, '');
        });
    },
    submitClassRegistration() {
      this.onloader = "flex";
      let CategoryId = this.CategoryIdval;
      let SchoolClass = this.SchoolClassval;
      let ExamScore = this.ExamScoreval;
      let CAScore = this.CAScoreval;
      let MidTermScore = this.MidTermScoreval;
      let TopicScore = this.TopicScoreval;
      var Operation = "CreateClass"
      let ClassId = this.ClassIdval;
      let payload = {
        SchoolClass,
        CategoryId,
        ExamScore,
        CAScore,
        MidTermScore,
        TopicScore
      };
      if (this.IsModified === true) {
        payload = {
          SchoolClass,
          CategoryId,
          ExamScore,
          CAScore,
          MidTermScore,
          TopicScore,
          ClassId
        };
        Operation = "UpdateClass"
      }
      this.$store
        .dispatch(Operation, payload)
        .then((resp) => {
          this.GetAllClass()
          this.onloader = "none";
          if (resp.responseCode === "00") {
            this.ClearForm();
            this.modals.newModal = false
            this.ShowAlertSuccess("Submitted successfully", '');
          } else {
            this.ShowAlertError(resp.responseMessage, '');
          }

        })
        .catch(() => {
          this.onloader = "none";
          this.ShowAlertError('Unable to process this operation at moment', '');
        });
    },
    GetAllClass() {
      let PageNumber = this.currentPage
      let PageSize = this.pageSize
      let SearchValue = this.searchval
      if (SearchValue === '') {
        SearchValue = 'all'
      }
      let userdata = {
        PageNumber,
        PageSize,
        SearchValue
      }
      this.onloader = "flex";
      this.$store
        .dispatch("GetAllClass", userdata)
        .then(resp => {
          this.classitems = resp.data;
          this.pageTotal = resp.totalPages;
          this.onloader = "none";
        })
        .catch(err => {
          console.log(err)
          console.log(err)
          this.ShowAlertError('Unable to process this operation at moment', '');
        });
    },
    getclasscategory() {
      this.onloader = "flex";
      this.$store
        .dispatch("getclasscategory")
        .then(resp => {
          this.classcategory = resp;
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err)
          this.ShowAlertError('Unable to process this operation at moment', '');
        });
    },
    async goToPage(pageNumber) {
      if (pageNumber !== '...') {
        this.currentPage = pageNumber;
        await this.GetAllClass();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.GetAllClass();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.GetAllClass();
      }
    },

  },
  computed: {
    totalPages() {
      // Assuming totalPages is fetched from the server
      return this.pageTotal;
    },
    paginationNumbers() {
      const paginationRange = 3; // Adjust as needed
      const startPage = Math.max(1, this.currentPage - paginationRange);
      const endPage = Math.min(this.totalPages, this.currentPage + paginationRange);

      const paginationNumbers = [];
      for (let i = startPage; i <= endPage; i++) {
        paginationNumbers.push(i);
      }

      if (startPage > 1) {
        paginationNumbers.unshift('...');
      }
      if (endPage < this.totalPages) {
        paginationNumbers.push('...');
      }

      return paginationNumbers;
    }
  },
  mounted() {
    const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
    if (
      userDetails.isSchoolStaff == false
    ) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    } else {
      this.GetAllClass();
      this.getclasscategory();
    }
  }
};
</script>

<style></style>
