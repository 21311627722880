<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/school/finance" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Finance
      </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>School Bill</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label>Bill Name</label>
                      <input v-model="BillNameval" class="form-control" />
                    </div>

                    <div class="text-center mb-3">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-inline">
              <div class="input-group">
                <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search..." />
                <div class="input-group-append">
                  <button type="button" @click.prevent="getsearch" class="btn btn-secondary">
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive mt-2">
              <table class="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bill Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.BillName }}</td>
                    <td>
                      <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
  name: "BillName",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      BillNameval: "",
      resultItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },

  methods: {
    newterm: function () {
      this.BillNameval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.BillNameval = "";
      this.openform = false;
      this.process = "N";
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getbyIdschoolbill", Id)
        .then((resp) => {
          this.dataid = Id;
          this.BillNameval = resp.BillName;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let BillName = this.BillNameval;
      let Id = this.Idval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        BillName,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("processschoolbill", payload)
        .then((resp) => {
          this.resultItems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getallschoolbill: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getallschoolbill")
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getsearch: function () {
      if (this.searchval) {
        this.onloader = "flex";
        this.$store
          .dispatch("searchschoolbill", this.searchval)
          .then((resp) => {
            this.resultItems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
          });
      }
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getallschoolbill();
    }
  },
};
</script>

<style>
</style>
