<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <card header-classes="bg-transparent">
            <div class="row">
                <div class="col-md-6">
                    <div class="card bg-secondary">
                        <div class="card-header">
                            <h4>Full Term Record</h4>
                        </div>
                        <div class="card-body">
                            <div class="form-horizontal">
                                <form role="form" @submit.prevent="submitdata">
                                    <div class="card bg-gradient-success">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Session</label>
                                                        <select v-model="SessionIdval" class="form-control"
                                                            @change="getregterm()">
                                                            <option value=""></option>
                                                            <option v-for="item in Sessionitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SchSession }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Term</label>
                                                        <select v-model="TermIdval" class="form-control"
                                                            @change="getclassinclassassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in termitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SchTerm }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Class</label>
                                                        <select v-model="ClassIdval" class="form-control"
                                                            @change="getoptioninclassassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in classitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.Sch_Class }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Option</label>
                                                        <select v-model="OptionIdval" class="form-control"
                                                            @change="getarminclassassigned()">
                                                            <option value="0"></option>
                                                            <option v-for="item in optionitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.ClassOption }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Arm</label>
                                                        <select v-model="ArmIdval" class="form-control"
                                                            @change="getstudentbyarm()">
                                                            <option value="0"></option>
                                                            <option v-for="item in armitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SectionName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Conduct</label>
                                                        <select v-model="ConductIdval" class="form-control"
                                                            @change="getstudentconduct()">
                                                            <option value="0"></option>
                                                            <option v-for="item in conducts" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.ConductName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Student</label>
                                                        <select v-model="StudIdval" class="form-control"
                                                            @change="edititem()">
                                                            <option value="0"></option>
                                                            <option v-for="item in studentitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.StudentName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-white">Grade</label>
                                                        <select v-model="GradeIdval" class="form-control">
                                                            <option value="0"></option>
                                                            <option v-for="item in othersgrades" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.Grade }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center mb-3">
                                        <button type="submit" class="btn btn-primary my-4">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="table-responsive mt-2">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Admission Number</th>
                                    <th>Student Name</th>
                                    <th>Grade</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in studentconductitem" :key="item.Id">
                                    <td>{{ item.AdmNo }}</td>
                                    <td>{{ item.StudentName }}</td>
                                    <td>{{ item.ConductGrade }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
export default {
    name: 'MarkRecord',
    mixins: [CheckApiResponse],
    components: {
        PageSpinner
    },
    data() {
        return {
            dataid: 1,
            ClassIdval: '',
            OptionIdval: '',
            ArmIdval: '',
            SessionIdval: '',
            TermIdval: '',
            StudIdval: '',
            ConductIdval: '',
            GradeIdval: '',
            Sessionitems: null,
            termitems: null,
            classitems: null,
            optionitems: null,
            armitems: null,
            studentitems: null,
            conducts: null,
            othersgrades: null,
            studentconductitem: null,
            process: 'N',
            onloader: 'none'
        }
    },

    methods: {
        edititem: function () {
            this.GradeIdval = 0;
            let Id = this.ConductIdval;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let SubjectId = 1;
            let processop = 'U';
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            this.TimePresentval = '';
            this.Commentval = '';
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudId,
                SessionId,
                TermId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getstudentconductbyid', userdata)
                .then(resp => {
                    if (resp != null) {
                        this.GradeIdval = resp.GradeId;
                    }
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let Id = this.dataid;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let ConductId = this.ConductIdval;
            let GradeId = this.GradeIdval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                StudId,
                SessionId,
                TermId,
                ConductId,
                GradeId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('studentconductregistration', userdata)
                .then((resp) => {
                    this.studentconductitem = resp;
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getstudentconduct: function () {
            let Id = this.ConductIdval;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let StudId = 1;
            let SubjectId = 1;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudId,
                SessionId,
                TermId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getstudentconduct', userdata)
                .then((resp) => {
                    this.studentconductitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregsession: function () {
            this.studentitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.studentconductitem = null;
            this.SessionIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getregsession')
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getconduct: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getconduct')
                .then(resp => {
                    this.conducts = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getothersgrade: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getothersgrade')
                .then(resp => {
                    this.othersgrades = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregterm: function () {
            this.classitems = null;
            this.studentitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.studentconductitem = null;
            this.TermIdval = 0;
            let SessionId = this.SessionIdval;
            if (SessionId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getregterm', SessionId)
                    .then(resp => {
                        this.termitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getclassinclassassigned: function () {
            this.classitems = null;
            this.studentitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.studentconductitem = null;
            this.ClassIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getclassinclassassigned')
                .then(resp => {
                    this.classitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getoptioninclassassigned: function () {
            this.studentitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.studentconductitem = null;
            this.OptionIdval = 0;
            this.onloader = 'flex'
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.$store.dispatch('getoptioninclassassigned', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getarminclassassigned: function () {
            this.studentitems = null;
            this.armitems = null;
            this.studentconductitem = null;
            this.ArmIdval = 0;
            this.onloader = 'flex'
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.$store.dispatch('getarminclassassigned', OptionId)
                    .then(resp => {
                        this.armitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getstudentbyarm: function () {
            this.studentitems = null;
            this.studentconductitem = null;
            this.StudIdval = 0;
            let Id = 1;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let SubjectId = 1;
            let StudId = 1;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            if (ArmId > 0) {
                let userdata = {
                    Id,
                    ClassId,
                    OptionId,
                    ArmId,
                    SubjectId,
                    StudId,
                    SessionId,
                    TermId,
                    processop,
                    username,
                    accesskey
                }
                this.onloader = 'flex'
                this.$store.dispatch('getstudentbyarm', userdata)
                    .then(resp => {
                        this.studentitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },

    },
    mounted() {
        this.getregsession();
        this.getconduct();
        this.getothersgrade();
    }
};
</script>

<style>
</style>
