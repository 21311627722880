export default {
  data() {
    return {
      accessDetails: null,
    };
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    }
  },
}