
export default {
    methods: {       
          RandomStringNumber(stringLength) {
            let result = "";
            const alphaNumericCharacters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            const alphabetsLength = alphaNumericCharacters.length;
            for (let i = 0; i < stringLength; i++) {
              result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
            }
            return result;
          },
          RandomString(stringLength) {
            let result = "";
            const alphaNumericCharacters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            const alphabetsLength = alphaNumericCharacters.length;
            for (let i = 0; i < stringLength; i++) {
              result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
            }
            return result;
          },
          RandomStringUpper(stringLength) {
            let result = "";
            const alphaNumericCharacters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const alphabetsLength = alphaNumericCharacters.length;
            for (let i = 0; i < stringLength; i++) {
              result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
            }
            return result;
          },
          RandomStringLower(stringLength) {
            let result = "";
            const alphaNumericCharacters =
              "abcdefghijklmnopqrstuvwxyz";
            const alphabetsLength = alphaNumericCharacters.length;
            for (let i = 0; i < stringLength; i++) {
              result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
            }
            return result;
          },
          RandomNumber(stringLength) {
            let result = "";
            const alphaNumericCharacters =
              "0123456789";
            const alphabetsLength = alphaNumericCharacters.length;
            for (let i = 0; i < stringLength; i++) {
              result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
            }
            return result;
          },
    },    
  }