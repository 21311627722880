<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard
      </router-link>
      <router-link to="/school/student-registration" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Student
      </router-link>
      <span class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Note on Student</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card">
                        <div class="card-body">
                          <div v-if="studentitems" class="bg-secondary m-2 p-2">
                            <img
                              v-if="studentitems.Passport"
                              :src="studentitems.Passport"
                              class="rounded"
                              alt=""
                              height="100"
                            />
                            <img
                              v-else
                              src="/img/brand/loguser.png"
                              class="rounded"
                              alt=""
                              height="50"
                            />
                            <h2>
                              <small>{{ studentitems.StudentName }}</small>
                            </h2>
                            <h6>
                              <small
                                >{{ studentitems.AdmNo }} |
                                {{ studentitems.Sch_Class }} |
                                {{ studentitems.ClassOption }} |
                                {{ studentitems.SectionName }}</small
                              >
                            </h6>
                          </div>
                          <div class="form-group">
                            <label>Heading</label>
                            <ckeditor
                              v-model="NoteHeadingval"
                              :editor="editor"
                              :config="editorConfig"
                            >
                            </ckeditor>
                          </div>
                          <div class="form-group">
                            <label>Detail</label>
                            <ckeditor
                              v-model="NoteBodyval"
                              :editor="editor"
                              :config="editorConfig"
                            >
                            </ckeditor>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="table-responsive mt-2">
                <div v-for="item in noteonstudentitem" :key="item.Id">
                  <div class="alert alert-warning">
                    <h1 class="text-center text-capitalize">
                      <b class="mr-3 badge badge-dark">{{
                        item.DateCreated | formatDate
                      }}</b>
                    </h1>
                    <div v-html="item.Noteheading"></div>

                    <div v-html="item.Notebody"></div>
                    <div class="text-center">
                      <button
                        class="btn btn-secondary btn-sm"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </div>
                  </div>
                  <div class="text-center">
                    <i class="fa fa-arrow-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "classattendance",
  mixins: [CheckApiResponse, CheckStaffUser, CkEditorUploadAdapter],
  components: {
    PageSpinner,
  },
  data() {
    return {
      accessDetails:null,
      dataid: 1,
      StudIdval: localStorage.getItem("StudentId"),
      NoteHeadingval: "",
      NoteBodyval: "",
      studentitems: null,
      noteonstudentitem: null,
      process: "N",
      onloader: "none",
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },
  methods: {
    edititem: function (Id) {
      this.dataid = Id;
      let id = Id;
      let idd = this.StudIdval;
      let searchitem = null;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        id,
        idd,
        searchitem,
        username,
        accesskey,
      };
      this.process = "U";
      this.NoteBodyval = "";
      this.NotHeadingval = "";
      this.onloader = "flex";
      this.$store
        .dispatch("getnoteonstudentbyid", userdata)
        .then((resp) => {
          this.NoteHeadingval = resp.Noteheading;
          this.NoteBodyval = resp.Notebody;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let Id = this.dataid;
      let StudId = this.StudIdval;
      let NoteHeading = this.NoteHeadingval;
      let NoteBody = this.NoteBodyval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        NoteHeading,
        NoteBody,
        StudId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processnoteonstudent", userdata)
        .then((resp) => {
          this.noteonstudentitem = resp;
          this.process = "N";
          this.NoteBodyval = "";
          this.NoteHeadingval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getstudent() {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentbyid", this.StudIdval)
        .then((resp) => {
          this.studentitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getnoteonstudent() {
      this.onloader = "flex";
      this.$store
        .dispatch("getnoteonstudent", this.StudIdval)
        .then((resp) => {
          this.noteonstudentitem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getstudent();
      this.getnoteonstudent();
    }    
  },
};
</script>

<style>
</style>
