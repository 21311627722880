<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link><i class="ni ni-bold-right text-white"></i>
            <router-link to="/schsitemenu" class="text-white">
                <i class="ni ni-badge"></i> Website Setup
            </router-link>
            <span class="text-white"><i class="ni ni-bold-right"></i>Header Banner</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">

            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-body">
                                    <div class="form-horizontal">
                                        <form role="form" @submit.prevent="submitdata">
                                            <div class="card bg-gradient-success">
                                                <div class="card-body">
                                                    <div class="form-group">
                                                        <label class="text-white">Text Over</label>
                                                        <ckeditor v-model="TextOverval"
                                                            :editorUrl="$store.state.appdatas.editorUrl"></ckeditor>
                                                    </div>
                                                    <div class="alert alert-success">
                                                        <p>It is advisable to use 2700 X 1300 dimension and small size
                                                            file lesser than 500kb</p>
                                                    </div>
                                                    <div class="form-group">
                                                        <img v-if="uploadedimage" height="100" :src="uploadedimage">
                                                        <img v-else height="100" src="img/brand/empty.png">
                                                        <input type="file" id="file" ref="file"
                                                            v-on:change="handleFileUpload()"> <br> <button
                                                            class="btn btn-outline-secondary"
                                                            @click.prevent="submitFile()"><i
                                                                class="ni ni-cloud-upload-96"></i>Upload</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mb-3">
                                                <button type="submit" class="btn btn-primary my-4">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="mt-2">
                                <div v-for="item in newsitem" :key="item.Id">
                                    <div class="alert alert-secondary">
                                        <img class="img-fluid"
                                            :src="$store.state.auth.baseurl + item.ImagePath.substr(1)" alt="">
                                        <p class="text-center" v-html="item.TextOver">
                                        </p>
                                        <div class="text-center">
                                            <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)"><i
                                                    class="fa fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
            <!-- End charts-->
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import PageSpinner from "@/components/PageSpinner";
import swal from 'sweetalert'
export default {
    name: 'headerbanner',
    components: {
        PageSpinner,
    },
    data() {
        return {
            accessDetails: null,
            Idval: 1,
            ImagePathval: '',
            TextOverval: '',
            uploadedimage: '',
            newsitem: null,
            searchval: null,
            process: 'N',
            onloader: 'none'
        }
    },

    methods: {
        edititem: function (Id) {
            this.Idval = Id;
            this.process = 'U';
            this.onloader = 'flex'
            this.$store.dispatch('getwebsiteheaderbyid', Id)
                .then(resp => {
                    this.TextOverval = resp.TextOver;
                    this.ImagePathval = resp.ImagePath;
                    this.uploadedimage = this.$store.state.auth.baseurl + resp.ImagePath.substr(1);
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let Id = this.Idval;
            let TextOver = this.TextOverval;
            let ImagePath = this.ImagePathval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                TextOver,
                ImagePath,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('processwebsiteheader', userdata)
                .then((resp) => {
                    this.newsitem = resp;
                    this.process = 'N';
                    this.TextOverval = '';
                    this.uploadedimage = '';
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getwebsiteheader: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getwebsiteheader')
                .then((resp) => {
                    this.newsitem = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },

        handleFileUpload: function () {
            this.file = this.$refs.file.files[0];
        },
        submitFile: function () {
            this.onloader = 'flex'
            var form = new FormData();
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let formalurl = this.ImgPathval;
            let doctype = 'image';
            form.append('username', username)
            form.append('accesskey', accesskey)
            form.append('formalurl', formalurl)
            form.append('doctype', doctype)
            form.append('file', this.file)
            this.$store.dispatch('uploadfile', form)
                .then((resp) => {
                    this.ImagePathval = resp;
                    this.uploadedimage = this.$store.state.auth.baseurl + resp.substr(1)
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Uploaded successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        } else {
            this.getwebsiteheader();
        }
    }
};
</script>

<style></style>
