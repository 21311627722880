<template>
<div class="row" v-if="accessDetails">
    <div class="col-xs-3 m-2 shrink">
        <router-link to="/admin/school-registrations">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-building"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">School</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
        <router-link to="school-subject-registrations">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-ruler-pencil"></i>
                    </div>
                    <h5 class="card-title text-muted text-white mb-0">School Subject</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink" v-if="accessDetails.isSuperAdmin">
        <router-link to="/admin/staff-registration">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="fa fa-users"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">Staff</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink" v-if="accessDetails.isSuperAdmin">
        <router-link to="/admin/sms-subscriptions-setup">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">SMS</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink" v-if="accessDetails.isAffliateMarketer == false">
        <router-link to="/admin/eduschool-cbt-exam">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-tv-2"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">CBT</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink" v-if="accessDetails.isAffliateMarketer == false">
        <router-link to="/admin/news-setup">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-world"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">News</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink" v-if="accessDetails.isAffliateMarketer == false">
        <router-link to="/admin/community-setup">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-world"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">Community</h5>
                </div>
            </div>
        </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink" v-if="accessDetails.isAffliateMarketer == false">
        <router-link to="/admin/privacy-setup">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-tag"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">Policy</h5>

                </div>
            </div>
        </router-link>
    </div>

    <div class="col-xs-3 m-2 shrink">
        <router-link to="/studreport">
            <div class="card bg-gradient-success">
                <div class="card-body text-center">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                        <i class="ni ni-email-83"></i>
                    </div>
                    <h5 class="card-title text-muted mb-0 text-white">Message</h5>

                </div>
            </div>
        </router-link>
    </div>

</div>
</template>
<script>
export default {   
    data() {
    return {     
      onloader: "none",
      accessDetails:null,
    };
  },  
  mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
    }, 
}
</script>