<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
       Dashboard</router-link
      >
      <router-link to="/school/library" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Library
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i>Issue Library Book</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary">
                <h3 class=" card-header">Registration</h3>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form">
                      <div class="form-group">
                        <label>Borrower</label>
                        <input
                          v-model="searchUsersval"
                          @keyup="openUsersItem"                         
                          class="form-control"
                          placeholder="Search Here..."
                          required
                        />
                        <div
                          class="table-responsive mt-2"
                          v-if="IsUsersItemOpen"
                        >
                          <table
                            class="table table-bordered table-striped table-hover"
                          >
                            <tbody>
                              <tr
                                v-for="item in searchedUsersResultQuery"
                                :key="item.Id"
                              >
                                <td>
                                  <button
                                    class="text-left btn btn-block"
                                    v-on:click.prevent="selectUser(item.Id)"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <img
                                          v-if="item.Passport"
                                          :src="
                                            $store.state.auth.baseurl +
                                              item.Passport.substr(1)
                                          "
                                          class=" rounded-circle"
                                          alt=""
                                          height="30"
                                        />
                                        <img
                                          v-else
                                          src="img/brand/loguser.png"
                                          class=" rounded-circle"
                                          alt=""
                                          height="30"
                                        />
                                      </div>
                                      <div class="col">
                                        {{ item.FullName }} <br />
                                        ({{ item.StatusName }})
                                      </div>
                                    </div>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-group" v-if="BorrowedByUserIdval">
                        <label>Search and select Book</label>
                        <input
                          v-model="searchBookval"
                          @keyup="openBookItem"                         
                          class="form-control"
                          placeholder="Search Here..."
                          required
                        />
                        <div
                          class="table-responsive mt-2"
                          v-if="IsBookItemOpen"
                        >
                          <table
                            class="table table-light table-bordered table-striped table-hover"
                          >
                            <tbody>
                              <tr
                                v-for="item in searchedBookResultQuery"
                                :key="item.BookId"
                              >
                                <td>
                                  <button
                                    class="text-left btn btn-block"
                                    v-on:click.prevent="selectBook(item.BookId)"
                                  >
                                    {{ item.BookTitle }} <br />
                                       <span> ({{ item.QtyRemain }}) Available</span>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-group" v-if="BookIdval">
                        <label>Date Issue</label>
                        <input
                          type="date"
                          v-model="DateIssueval"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group" v-if="DateIssueval">
                        <label>Due Date</label>
                        <input
                          type="date"
                          v-model="DateDueval"
                          class="form-control"
                          required
                        />
                      </div>
                      <div
                        class="text-center card-footer mb-3"
                        v-if="DateDueval"
                      >
                        <base-button
                          type="success"
                          v-on:click.prevent="submitdata()"
                          >Submit</base-button
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="justify-content-end form-inline mt-3">
                <base-input
                  class="input-group-alternative"
                  placeholder="Search here..."
                  addon-left-icon="fa fa-search"
                  v-model="searchval"
                >
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table
                  class="table table-dark table-bordered table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th>Book</th>
                      <th>Book Nnumber</th>
                      <th>Borrowed By</th>
                      <th>Date Issue</th>
                      <th>Date Due</th>
                      <th>Return Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in searchedResultQuery" :key="item.Id">
                      <td>{{ item.BookTitle }}</td>
                      <td>{{ item.BookNo }}</td>
                      <td>{{ item.BorrowedByUser }}</td>
                      <td>{{ item.DateIssue | formatDate }}</td>
                      <td>{{ item.DateDue | formatDate }}</td>
                      <td>{{ item.ReturnDate | formatDate }}</td>
                      <td>
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import LibraryBookMixin from "@/mixins/LibraryBook.mixin";
import SchoolUsersMixin from "@/mixins/SchoolUsers.mixin";
import DateFormaterMixin from "@/mixins/DateFormater.mixin";
import libraryBookStockSummaryMixin from "@/mixins/libraryBookStockSummary.mixin";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "LibraryBookIssue",
  mixins: [
    LibraryBookMixin,
    SchoolUsersMixin,
    DateFormaterMixin,
    libraryBookStockSummaryMixin
  ],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      BorrowedByUserIdval: "",
      BookIdval: 0,
      DateIssueval: "",
      DateDueval: "",
      searchUsersval: "",
      searchBookval: "",
      searchval: "",
      LibraryBookItems: null,
      SchoolUsersItems: null,
      LibraryBookStockSummaryItems: null,
      LibraryBookIssueItems: null,
      IsUsersItemOpen: false,
      IsBookItemOpen: false,
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedUsersResultQuery() {
      if (this.searchUsersval) {
        return this.SchoolUsersItems.filter(item => {
          return this.searchUsersval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.FullName.toLowerCase().includes(v) ||
                item.StatusName.toLowerCase().includes(v)
            );
        });
      } else {
        return this.SchoolUsersItems;
      }
    },
    searchedBookResultQuery() {
      if (this.searchBookval) {
        return this.LibraryBookStockSummaryItems.filter(item => {
          return this.searchBookval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.BookTitle.toLowerCase().includes(v) 
            );
        });
      } else {
        return this.LibraryBookStockSummaryItems;
      }
    },
    searchedResultQuery() {
      if (this.searchval) {
        return this.LibraryBookIssueItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.BookNo.toLowerCase().includes(v) ||
                item.BookTitle.toLowerCase().includes(v) ||
                item.BorrowedByUser.toLowerCase().includes(v)
            );
        });
      } else {
        return this.LibraryBookIssueItems;
      }
    }
  },
  methods: {
    openBookItem() {
       this.IsBookItemOpen = false;
      if(this.searchBookval){
         this.IsBookItemOpen = true;
      }   
    },
    openUsersItem() {
       this.IsUsersItemOpen = false;
      if(this.searchUsersval){
         this.IsUsersItemOpen = true;
      }     
    },
    selectBook(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBook/GetByIdLibraryBook", Id)
        .then(resp => {
          this.BookIdval = Id;
          this.searchBookval = resp.BookTitle;
          this.IsBookItemOpen = false;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    selectUser(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("GetUserById", Id)
        .then(resp => {
          this.BorrowedByUserIdval = Id;
          this.searchUsersval = resp.FullName;
          this.IsUsersItemOpen = false;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    edititem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookIssue/GetByIdLibraryBookIssue", Id)
        .then(resp => {
          this.Idval = Id;
          this.BorrowedByUserIdval = resp.BorrowedByUserId;
          this.BookIdval = resp.BookId;
          this.DateIssueval = this.frontEndDateFormat(resp.DateIssue);
          this.DateDueval = this.frontEndDateFormat(resp.DateDue);
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let BorrowedByUserId = this.BorrowedByUserIdval;
      let BookId = this.BookIdval;
      let DateIssue = this.DateIssueval;
      let DateDue = this.DateDueval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        BorrowedByUserId,
        BookId,
        DateIssue,
        DateDue,
        processop,
        username,
        accesskey
      };     
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookIssue/ProcessLibraryBookIssue", userdata)
        .then(resp => {         
          this.LibraryBookIssueItems = resp;
          this.searchBookval = "";
          this.searchUsersval = "";
          this.BorrowedByUserIdval = 0;
          this.BookIdval = 0;
          this.DateIssueval = null;
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetLibraryBookIssue() {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryBookIssue/GetLibraryBookIssue")
        .then(resp => {
          this.LibraryBookIssueItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        }  else {
      this.GetLibraryBookIssue();
    }
  }
};
</script>

<style></style>
