<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <router-link to="/school/notifications" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Notification</router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i>News</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3" v-for="item in news" :key="item.Id">
                  <button
                    class="btn btn-secondary"
                    v-on:click="readnews(item.NewsHeading)"
                  >
                    <div>
                      <img
                        v-if="item.ImagePath"
                        :src="
                          $store.state.auth.baseurl + item.ImagePath.substr(1)
                        "
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="text-left">
                      <h1>{{ item.NewsHeading }}</h1>
                      <div v-html="item.Newspreview"></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "news",
  components: {
    PageSpinner,
  },
  data() {
    return {
      accessDetails: null,
      news: null,
      onloader: "none",
    };
  },

  methods: {
      readnews: function (NewsHeading) {
      localStorage.setItem("NewsHeading", NewsHeading);
      this.$router.push("/readschnews");
    },
    getnews: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewssch")
        .then((resp) => {
          this.news = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getnews();
    }
  },
};
</script>

<style></style>
