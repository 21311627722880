<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/school/finance" class="text-white">
        Finance
      </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> School Bill Setup</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select
                                  v-model="SessionIdval"
                                  class="form-control"
                                  @change="getregterm()"
                                >
                                  <option value=""></option>
                                  <option
                                    v-for="item in Sessionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="SessionIdval">
                              <div class="form-group">
                                <label class="text-white">Term</label>
                                <select
                                  v-model="TermIdval"
                                  class="form-control"
                                  @change="getclass()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in termitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="TermIdval">
                              <div class="form-group">
                                <label class="text-white">Class</label>
                                <select
                                  v-model="ClassIdval"
                                  class="form-control"
                                  @change="getoption()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in classitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="ClassIdval">
                              <div class="form-group">
                                <label class="text-white">Option</label>
                                <select
                                  v-model="OptionIdval"
                                  class="form-control"
                                  @change="getallschoolbilldetails()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in optionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ClassOption }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6" v-if="OptionIdval">
                              <div class="form-group">
                                <label class="text-white">Bill Name</label>
                                <select
                                  v-model="BillNameIdval"
                                  class="form-control"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in billNameItems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.BillName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="BillNameIdval">
                              <div class="form-group">
                                <label class="text-white">Bill Amount</label>
                                <input
                                  v-model="BillAmountval"
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div class="col-md-6" v-if="BillNameIdval">
                              <div class="form-group">
                                <label class="text-white mr-4">Is Optional</label>
                                <input type="checkbox" v-model="IsOptionval" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3" v-if="BillNameIdval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <span class=" badge badge-primary" v-if="sumResultItem">Total Amount = {{
                sumResultItem
              }}</span>
              <div class="table-responsive mt-2" v-if="resultItems">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Bill Name</th>
                      <th>BillAmount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultItems" :key="item.Id">
                      <td>{{ item.BillName }}</td>
                      <td>{{ item.BillAmount }}</td>
                      <td>
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "SchooBillDetails",
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      Idval: 1,
      ClassIdval: "",
      OptionIdval: "",
      SessionIdval: "",
      TermIdval: "",
      BillNameIdval: "",
      IsOptionval: false,
      BillAmountval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      billNameItems: null,
      sumResultItem: null,
      resultItems: null,
      process: "N",
      onloader: "none",
    };
  },

  methods: {
    edititem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getbyidschoolbilldetails", Id)
        .then((resp) => {          
          this.Idval = Id;
          this.ClassIdval = resp.ClassId;
          this.getoption();
          this.OptionIdval = resp.ClassOptionId;
          this.SessionIdval = resp.SessionId;
          this.getregterm();
          this.TermIdval = resp.TermId;
          this.BillNameIdval = resp.BillNameId;
          this.IsOptionval = resp.IsOption;
          this.BillAmountval = resp.BillAmount;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function() {
      let Id = this.Idval;
      let BillNameId = this.BillNameIdval;
      let ClassOptionId = this.OptionIdval;
      let BillAmount = this.BillAmountval;
      let IsOption = this.IsOptionval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        BillNameId,
        ClassOptionId,
        BillAmount,
        IsOption,
        SessionId,
        TermId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processschoolbilldetails", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.getsumschoolbilldetails();
          this.BillNameIdval=0;
          this.BillAmountval='';
          this.IsOptionval=false;
          this.process='N';
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getallschoolbilldetails: function() {
      if (this.OptionIdval) {
        let ClassOptionId = this.OptionIdval;
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let userdata = {
          ClassOptionId,
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getallschoolbilldetails", userdata)
          .then((resp) => {
            this.resultItems = resp;
            this.getsumschoolbilldetails();
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getsumschoolbilldetails: function() {
      if (this.OptionIdval) {
        let ClassOptionId = this.OptionIdval;
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let userdata = {
          ClassOptionId,
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getsumschoolbilldetails", userdata)
          .then((resp) => {
            this.sumResultItem = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getregsession: function() {      
      this.termitems = null;      
      this.Sessionitems = null;      
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm: function() {     
      this.termitems = null;      
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclass: function() {
      this.classitems = null;
      this.optionitems = null;
      this.ClassIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoption: function() {
      this.optionitems = null;
      this.OptionIdval = 0;
      this.onloader = "flex";
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getallschoolbill: function() {
      this.$store
        .dispatch("getallschoolbill")
        .then((resp) => {
          this.billNameItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getregsession();
      this.getallschoolbill();
    }
  },
};
</script>

<style></style>
