<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white"> Dashboard</router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>News Setup</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <modal :show.sync="modals.newModal" modal-classes="modal-dialog-centered modal-xl">
                        <div class="card bg-secondary border-0 mb-0">
                            <div class="card-body">
                                <div class="card border-0 mb-0">
                                    <div class="card-body">
                                        <div class="form-horizontal">
                                            <form role="form">
                                                <div class="row">
                                                    <div class="col-md-6" v-if="newsCategoryIdval">
                                                        <div class="form-group" v-if="newsCategoryIdval != 1">
                                                            <label>School Type</label>
                                                            <select v-model="schoolTypeIdval" class="form-control"
                                                                @change="GetSchoolsForPortal()">
                                                                <option value="0">- Select School Type -
                                                                </option>
                                                                <option v-for="item in schoolTypeItems"
                                                                    :key="item.schoolTypeId"
                                                                    v-bind:value="item.schoolTypeId">
                                                                    {{ item.schoolTypeName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="schoolTypeIdval">
                                                        <div class="form-group">
                                                            <label>School</label>
                                                            <select v-model="schoolIdval" class="form-control">
                                                                <option value="0"></option>
                                                                <option v-for="item in schoolItems" :key="item.schoolId"
                                                                    v-bind:value="item.schoolId">
                                                                    {{ item.schoolName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="schoolTypeIdval">
                                                        <div class="form-group">
                                                            <label>Is It Sportlight</label>
                                                            <select v-model="isSpotlightval" class="form-control">
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12" v-if="newsCategoryIdval">
                                                        <div class="form-group">
                                                            <label>Title</label>
                                                            <quillEditor v-model="newsTitleval" ref="newsTitleval"
                                                                :options="editorOption">

                                                            </quillEditor>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12" v-if="newsTitleval">
                                                        <div class="form-group">
                                                            <label>Short Details</label>
                                                            <quillEditor v-model="newsDetailsShortval"
                                                                ref="newsDetailsShortval" :options="editorOption">

                                                            </quillEditor>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12" v-if="newsDetailsShortval">
                                                        <div class="form-group">
                                                            <label>Full Details</label>
                                                            <quillEditor v-model="newsDetailsval"
                                                                ref="newsDetailsShortval" :options="editorOption">

                                                            </quillEditor>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 pb-2" v-if="newsDetailsval">
                                                        <div class="row">
                                                            <div class="col">
                                                                <img v-if="newsImagePathval" :src="newsImagePathval"
                                                                    class="img-fluid" />
                                                                <img v-else src="/img/brand/empty.png"
                                                                    class="img-fluid" />
                                                            </div>
                                                            <div class="col">
                                                                <ImageUploader
                                                                    @emitOutImgPathval="UpdateNewsImagePathval" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template slot="footer">
                            <base-button type="default" @click="modals.newModal = false">Cancel
                            </base-button>
                            <base-button v-if="newsImagePathval" type="success" @click.prevent="submitdata()">Submit
                            </base-button>
                        </template>
                    </modal>
                    <div class="card">
                        <div class="card-body">
                            <div class="justify-content-end form-inline">
                                <button class="btn btn-success" v-on:click="openNew()">
                                    <i class="fa fa-edit"></i> New
                                </button>
                                <select v-model="newsCategoryIdval" class="form-control" @change="GetNewsForPortal">
                                    <option value="0">-Select Category-</option>
                                    <option v-for="item in newsCategoryItems" :key="item.newsCategoryId"
                                        v-bind:value="item.newsCategoryId">
                                        {{ item.newsCategoryName }}
                                    </option>
                                </select>
                                <base-input class="input-group-alternative" placeholder="Search here..."
                                    addon-left-icon="fa fa-search" v-model="searchValueval">
                                </base-input>
                            </div>
                            <div class="row" v-if="newsItems">
                                <div class="col-md-4" v-for="item in newsItems" :key="item.newsId">
                                    <div class="card mt-2">
                                        <div class="card-body bg-secondary">
                                            <div class="card">
                                                <img :src="item.newsImagePath
            " class="card-img-top" alt="...">
                                                <div class="card-body">
                                                    <h5 class="card-title" v-html="item.newsTitle"></h5>
                                                    <div class="card-text" v-html="item.newsDetailsShort"></div>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <button class="btn btn-secondary btn-sm"
                                                        v-on:click="edititem(item.newsId)">
                                                        <i class="fa fa-edit"></i> Edit
                                                    </button>
                                                    <button class="btn btn-danger btn-sm"
                                                        v-on:click="edititem(item.newsId)">
                                                        <i class="fa fa-times"></i> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-if="newsItems">
                                <select v-model="pageSizeval" @change="GetNewsForPortal" class="btn btn-dark btn-sm">
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <button class="btn-success m-1" @click="prevPage"
                                    :disabled="pageNumberval === 1">Previous</button>
                                <span v-for="pageNumber in paginationNumbers" :key="pageNumber">
                                    <button class="btn-success m-1" v-if="pageNumber === '...'" disabled>...</button>
                                    <button class="btn-success m-1" v-else @click="goToPage(pageNumber)"
                                        :class="{ 'active': pageNumber === pageNumberval }">{{ pageNumber
                                        }}</button>
                                </span>
                                <button class="btn-success m-1" @click="nextPage"
                                    :disabled="pageNumberval === totalPages">Next</button>
                            </div>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->
        </div>
    </div>
</template>

<script>
import moment from "moment";
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import PageSpinner from "@/components/PageSpinner";
import ValidateAccessToken from "@/mixins/ValidateAccessToken.mixins";
import Alert from "@/mixins/Alert.mixins"
import katex from 'katex';
import 'katex/dist/katex.min.css';
import ImageUploader from "@/components/ImageUploader"
import modal from '@/components/Modal'
window.katex = katex;
export default {
    name: "questionheader",
    mixins: [ValidateAccessToken, Alert],
    components: {
        PageSpinner,
        quillEditor,
        ImageUploader,
        modal
    },
    data() {
        return {
            accessDetails: null,
            newsIdval: 0,
            newsCategoryIdval: 0,
            schoolTypeIdval: 0,
            schoolIdval: 0,
            newsTitleval: "",
            newsDetailsval: "",
            newsDetailsShortval: "",
            newsImagePathval: null,
            isSpotlightval: false,
            searchValueval: "All",
            pageNumberval: 1,
            pageSizeval: 10,
            totalRecordsval: 0,
            newsCategoryItems: null,
            schoolTypeItems: null,
            schoolItems: null,
            newsItems: null,
            isModified: false,
            onloader: "none",
            editorOption: {
                debug: 'info',
                placeholder: 'Type in here',
                readonlly: true,
                theme: 'snow'
            },
            modals: {
                newModal: false
            }
        };
    },
    methods: {
        openNew() {
            this.isModified = false;
            this.modals.newModal = true
        },
        resetInput() {
            this.schoolTypeIdval = 0;
            this.schoolIdval = 0;
            this.newsTitleval = "";
            this.newsDetailsShortval = "";
            this.newsDetailsval = "";
            this.newsImagePathval = null;
            this.isSpotlightval = false;
        },
        edititem(Id) {
            this.resetInput();
            this.onloader = "flex";
            this.$store
                .dispatch("NewsSetup/GetNewsByIdForPortal", Id)
                .then((resp) => {
                    if (resp.responseCode == "00") {
                        this.newsIdval = resp.data.newsId;
                        this.newsCategoryIdval = resp.data.newsCategoryId;
                        this.schoolTypeIdval = resp.data.schoolTypeId;
                        this.schoolIdval = resp.data.schoolId;
                        this.newsTitleval = resp.data.newsTitle;
                        this.isSpotlightval = resp.data.isSpotlight;
                        this.newsDetailsShortval = resp.data.newsDetailsShort;
                        this.newsDetailsval = resp.data.newsDetails;
                        this.newsImagePathval = resp.data.newsImagePath;
                        this.isModified = true;
                        this.modals.newModal = true
                    }
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.ShowAlertError(err.response.data, '');
                });
        },
        submitdata() {
            let newsId = this.newsIdval;
            let newsCategoryId = this.newsCategoryIdval;
            let schoolId = this.schoolIdval;
            let newsTitle = this.newsTitleval;
            let newsDetails = this.newsDetailsval;
            let newsDetailsShort = this.newsDetailsShortval;
            let newsImagePath = this.newsImagePathval;
            let isSpotlight = Boolean(this.isSpotlightval);
            let userdata = {};
            this.onloader = "flex";
            let operationVal = "";
            if (this.isModified == false) {
                operationVal = "NewsSetup/CreateNews";
                userdata = {
                    newsCategoryId,
                    schoolId,
                    newsTitle,
                    newsDetails,
                    newsDetailsShort,
                    newsImagePath,
                    isSpotlight,
                }
            } else {
                operationVal = "NewsSetup/UpdateNews";
                userdata = {
                    newsId,
                    newsCategoryId,
                    schoolId,
                    newsTitle,
                    newsDetails,
                    newsDetailsShort,
                    newsImagePath,
                    isSpotlight,
                }
            }
            this.$store
                .dispatch(operationVal, userdata)
                .then((resp) => {
                    this.onloader = "none";
                    if (resp.responseCode == '00') {
                        this.GetNewsForPortal();
                        this.resetInput();
                        this.modals.newModal = false;
                        this.ShowAlertSuccess('Submitted successfully', '');
                    } else {
                        this.ShowAlertError(resp.responseMessage, '');
                    }

                })
                .catch((err) => {
                    this.onloader = "none";
                    this.ShowAlertError(err.response.data, '');
                });
        },
        UpdateNewsImagePathval(data) {
            this.newsImagePathval = data;
        },
        GetNewsCategoriesForPortal() {
            this.onloader = "flex";
            this.$store
                .dispatch("NewsSetup/GetNewsCategoriesForPortal")
                .then((resp) => {
                    this.newsCategoryItems = resp.data;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.ShowAlertError(err.response.data, '');
                });
        },
        GetSchoolTypeForPortal() {
            this.onloader = "flex";
            this.$store
                .dispatch("SchoolType/GetSchoolTypeForPortal")
                .then((resp) => {
                    this.schoolTypeItems = resp.data;
                    this.onloader = "none";
                })
                .catch((err) => {
                    console.log(err);
                    this.onloader = "none";
                });
        },
        GetSchoolsForPortal() {
            let SchoolTypeId = this.schoolTypeIdval;
            if (SchoolTypeId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("School/GetSchoolsForPortal", SchoolTypeId)
                    .then((resp) => {
                        this.schoolItems = resp.data;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.ShowAlertError(err.response.data, '');
                    });
            }
        },
        GetNewsForPortal() {
            let NewsCategoryId = this.newsCategoryIdval;
            if (NewsCategoryId > 0) {
                this.onloader = "flex";
                let SearchValue = this.searchValueval;
                let PageNumber = this.pageNumberval;
                let PageSize = this.pageSizeval;
                const userData = {
                    SearchValue,
                    PageNumber,
                    PageSize,
                    NewsCategoryId
                }
                this.$store
                    .dispatch("NewsSetup/GetNewsForPortal", userData)
                    .then((resp) => {
                        this.newsItems = resp.data;
                        this.totalRecordsval = resp.totalRecords;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.ShowAlertError(err.response.data, '');
                    });
            }
        },
        async goToPage(pageNumber) {
            if (pageNumber !== '...') {
                this.pageNumberval = pageNumber;
                this.GetNewsForPortal();
            }
        },
        nextPage() {
            if (this.pageNumberval < this.totalPages) {
                this.pageNumberval++;
                this.GetNewsForPortal();
            }
        },
        prevPage() {
            if (this.pageNumberval > 1) {
                this.pageNumberval--;
                this.GetNewsForPortal();
            }
        },

        frontEndDateFormat(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },

    },
    computed: {

        totalPages() {
            // Assuming totalPages is fetched from the server
            return this.totalRecordsval;
        },
        paginationNumbers() {
            const paginationRange = 1; // Adjust as needed
            const startPage = Math.max(1, this.pageNumberval - paginationRange);
            const endPage = Math.min(this.totalPages, this.pageNumberval + paginationRange);

            const paginationNumbers = [];
            for (let i = startPage; i <= endPage; i++) {
                paginationNumbers.push(i);
            }

            if (startPage > 1) {
                paginationNumbers.unshift('...');
            }
            if (endPage < this.totalPages) {
                paginationNumbers.push('...');
            }

            return paginationNumbers;
        },
    },
    mounted() {
        const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
        if (
            userDetails.isSuperAdmin == true ||
            userDetails.isAdmin == true ||
            userDetails.isSupportAdmin == true
        ) {
            this.GetNewsCategoriesForPortal();
            this.GetSchoolTypeForPortal();
        } else {
            this.$store.dispatch("auth/Logout");
            this.$router.push("/login");
        }
    },
};
</script>

<style></style>