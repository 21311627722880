<template>
  <div>
   <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" v-if="accessDetails">
      <!-- Card stats -->
      <div class="pb-6">
        <div class="row">
          <div class="col-md-8">
            <h4 class="text-white">Welcome {{ userName }}!</h4>
            <div v-if="accessDetails.isStudentGuardian != 8">
              <small class="text-white"
                ><i class="fa fa-phone"></i> {{ userPhoneNo }} ||
                <i class="fa fa-envelope"></i> {{ userEmailAddress }}</small
              >
              <!-- <h4
                v-if="$store.state.schooldata.schoolinfo.StatusId != 10"
                class="text-white pt-2"
              >
                {{ currentterm.SchSession }} - {{ currentterm.SchTerm }}
              </h4> -->
              <h4 class="text-white">{{ currentterm.Org_Name }}</h4>
            </div>
          </div>
          <div class="col-md-4 text-center">
            <img v-if="schlogo" :src="schlogo" alt="" height="100" />
            <img v-else src="img/brand/nologo.jpg" alt="" height="100" /><br />
            <h3 class="badge badge-dark text-white">
              SMS Balance : {{ smsbalance }}
            </h3>
          </div>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <Staffmenu> </Staffmenu>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Daily News</h6>
                <div>
                  <div
                    class="mx-3 my-5"
                    v-for="item in newsItem"
                    :key="item.Id"
                  >
                    <button
                      class="btn btn-secondary"
                      v-on:click="readnews(item.NewsHeading)"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <img
                            v-if="item.ImagePath"
                            :src="
                              $store.state.auth.baseurl +
                              item.ImagePath.substr(1)
                            "
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div class="col-md-8 text-left">
                          <h6>{{ item.NewsHeading }}</h6>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div class="text-center mb-3" v-if="newsItem">
                    <router-link to="/moreschnews" class="btn btn-secondary">
                      Read More News <i class="fa fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import Staffmenu from "../Dashboardmenu/Staffmenu"
import PageSpinner from "@/components/PageSpinner"
export default {
  components: {
    Staffmenu,
    PageSpinner
  },
  props: {
    userName: String,
    userPhoneNo: String,
    userEmailAddress: String,
  },
  data() {
    return {
      currentterm: {},
      accessDetails:null,
      newsItem: null,
      schlogo: "",
      ExpiredDate: "",
      smsbalance: null,
      onloader: "none",
    };
  },
  methods: {
    getcurrentterm() {
      this.accessDetails = this.$store.getters['auth/_user'];
      if (this.accessDetails.isSchoolStudent == true || this.accessDetails.isStudentGuardian == true || this.accessDetails.isSchoolStaff == true) {
        this.onloader = "flex";
        this.$store
          .dispatch("getcurrentterm")
          .then((resp) => {
            this.currentterm = resp;
             this.schlogo =resp.Logo;
             if(resp.Logo==null){
              this.schlogo = 'img/brand/empty.png';
            }
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("auth/Logout");
            this.$router.push("/login");
          });
      }
    },
    getsmsbalance() {
      this.onloader = "flex";
      this.$store
        .dispatch("getsmsbalance")
        .then((resp) => {
          this.smsbalance = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("auth/Logout");
          this.$router.push("/login");
        });
    },
    getschooldetails() {
      this.onloader = "flex";
      this.$store
        .dispatch("getschooldetails")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("auth/Logout");
          this.$router.push("/login");
        });
    },
    readnews(NewsHeading) {
      localStorage.setItem("NewsHeading", NewsHeading);
      this.$router.push("/readschnews");
    },
    getnews() {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsschtop10")
        .then((resp) => {
          this.newsItem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("auth/Logout");
          this.$router.push("/login");
        });
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    this.getcurrentterm();
    this.getsmsbalance();
    this.getschooldetails();
    this.getnews();
  },
};
</script>

<style></style>
