<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard
      </router-link>
      <router-link to="/school/student-registration" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Student
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i>Student Promotion</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="justify-content-end form-inline">
            <router-link to="/school/student-registration" class="btn btn-default">
              <i class="fa fa-users"></i> Back to Student</router-link
            >
          </div>
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card bg-gradient-success">
                        <h3 class="card-header">From</h3>
                        <div class="card-body">
                          <div class="form-group">
                            <label class="text-white">Class</label>
                            <select
                              v-model="ClassIdval"
                              class="form-control"
                              @change="getoption()"
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in classitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.Sch_Class }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Option</label>
                            <select
                              v-model="OptionIdval"
                              class="form-control"
                              @change="getarm()"
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in optionitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.ClassOption }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Arm</label>
                            <select
                              v-model="ArmIdval"
                              class="form-control"
                              @change="getstudentbyarm()"
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in armitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.SectionName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-gradient-success">
                        <h3 class="card-header">To</h3>
                        <div class="card-body">
                          <div class="form-group">
                            <label class="text-white">Class</label>
                            <select
                              v-model="ToClassIdval"
                              class="form-control"
                              @change="togetoption()"
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in schclass"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.Sch_Class }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Option</label>
                            <select
                              v-model="ToOptionIdval"
                              class="form-control"
                              @change="togetarm()"
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in tooptionitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.ClassOption }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Arm</label>
                            <select v-model="ToArmIdval" class="form-control">
                              <option value="0"></option>
                              <option
                                v-for="item in toarmitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.SectionName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ToArmIdval">
                    <div class="justify-content-end form-inline my-3">
                      <base-input
                        class="input-group-alternative"
                        placeholder="Search here..."
                        addon-left-icon="fa fa-search"
                        v-model="searchval"
                      >
                      </base-input>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-dark">
                        <thead>
                          <th></th>
                          <th>Student Name</th>
                          <th>Admission Number</th>
                          <th></th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in studentitems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            <td>
                              <img
                                v-if="item.Passport"
                                :src="item.Passport"
                                class="rounded"
                                alt=""
                                height="50"
                              />
                              <img
                                v-else
                                src="img/brand/loguser.png"
                                class="rounded"
                                alt=""
                                height="50"
                              />
                            </td>
                            <td>{{ item.StudentName }}</td>
                            <td>{{ item.AdmNo }}</td>
                            <td>
                              <button
                                class="btn btn-secondary btn-sm my-2"
                                v-on:click="promoteStudent(item.Id)"
                              >
                                Promote
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
export default {
  name: "studentpromotion",
  components: {
    PageSpinner,
  },
  mixins: [CheckApiResponse, CheckStaffUser],
  data() {
    return {
      accessDetails:null,
      dataid: 1,
      ClassIdval: "",
      OptionIdval: "",
      ArmIdval: "",
      StudIdval: 0,
      ToClassIdval: "",
      ToOptionIdval: "",
      ToArmIdval: "",
      classitems: null,
      schclass: null,
      optionitems: null,
      armitems: null,
      tooptionitems: null,
      toarmitems: null,
      studentitems: null,
      onloader: "none",
    };
  },

  methods: {
    promoteStudent(StudIdval) {
      let Id = 1;
      let StudId = StudIdval;
      let ClassId = this.ToClassIdval;
      let OptionId = this.ToOptionIdval;
      let ArmId = this.ToArmIdval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ClassId,
        OptionId,
        ArmId,
        StudId,
        username,
        accesskey,
      };
      console.log(userdata);
      this.onloader = "flex";
      this.$store
        .dispatch("promotion/Promotestudent", userdata)
        .then(() => {
          this.getstudentbyarm();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Promoted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getclass() {
      this.classitems = null;
      this.studentitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.schclass = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getclassinclassassigned() {
      this.classitems = null;
      this.studentitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoption() {
      this.studentitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.OptionIdval = 0;
      this.onloader = "flex";
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getarm() {
      this.studentitems = null;
      this.armitems = null;
      this.ArmIdval = 0;
      this.onloader = "flex";
      let OptionId = this.OptionIdval;
      if (OptionId > 0) {
        this.$store
          .dispatch("getarm", OptionId)
          .then((resp) => {
            this.armitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getstudentbyarm() {
      this.StudIdval = 0;
      let Id = 1;
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let ArmId = this.ArmIdval;
      let SubjectId = 1;
      let StudId = 1;
      let SessionId = 1;
      let TermId = 1;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      if (ArmId > 0) {
        let userdata = {
          Id,
          ClassId,
          OptionId,
          ArmId,
          SubjectId,
          StudId,
          SessionId,
          TermId,
          processop,
          username,
          accesskey,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getstudentbyarm", userdata)
          .then((resp) => {
            this.studentitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    togetoption() {
      this.tooptionitems = null;
      this.toarmitems = null;
      this.ToOptionIdval = 0;
      this.onloader = "flex";
      let ToClassId = this.ToClassIdval;
      if (ToClassId > 0) {
        this.$store
          .dispatch("getoption", ToClassId)
          .then((resp) => {
            this.tooptionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    togetarm() {
      this.toarmitems = null;
      this.ToArmIdval = 0;
      this.onloader = "flex";
      let ToOptionId = this.ToOptionIdval;
      if (ToOptionId > 0) {
        this.$store
          .dispatch("getarm", ToOptionId)
          .then((resp) => {
            this.toarmitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.getclassinclassassigned();
    this.getclass();
  },
};
</script>

<style>
</style>
