<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/school/student-registration" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Student
      </router-link>
      <span class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Student Graduation</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="justify-content-end form-inline">
            <router-link to="/school/student-registration" class="btn btn-default">
              <i class="fa fa-users"></i> Back to Student</router-link
            >
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select
                                  v-model="SessionIdval"
                                  class="form-control"
                                  @change="getregterm()"
                                >
                                  <option value=""></option>
                                  <option
                                    v-for="item in Sessionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Term</label>
                                <select
                                  v-model="TermIdval"
                                  class="form-control"
                                  @change="getalumnibysession()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in termitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Class</label>
                                <select
                                  v-model="ClassIdval"
                                  class="form-control"
                                  @change="getoptioninclassassigned()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in classitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Option</label>
                                <select
                                  v-model="OptionIdval"
                                  class="form-control"
                                  @change="getarminclassassigned()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in optionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ClassOption }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Arm</label>
                                <select
                                  v-model="ArmIdval"
                                  class="form-control"
                                  @change="getstudentbyarm()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in armitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SectionName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Student</label>
                                <select
                                  v-model="StudIdval"
                                  class="form-control"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in studentitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.StudentName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="alumnis" class="table-responsive mt-2">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Admission Number</th>
                      <th>Student Name</th>
                      <th>Session</th>
                      <th>Term</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in alumnis" :key="item.Id">
                      <td>{{ item.AdmNo }}</td>
                      <td>{{ item.StudentName }}</td>
                      <td>{{ item.SchSession }}</td>
                      <td>{{ item.SchoolTerm }}</td>
                      <td> <button class="btn btn-secondary btn-sm" v-on:click="ReversestudentGraduation(item.Id)"><i class="fa fa-backward"></i> Revert</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
export default {
  name: "graduation",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner,
  },
  data() {
    return {
      accessDetails:null,
      dataid: 1,
      SessionIdval: "",
      TermIdval: "",
      ClassIdval: "",
      OptionIdval: "",
      ArmIdval: "",
      StudIdval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      armitems: null,
      studentitems: null,
      alumnis: null,
      process: "N",
      onloader: "none",
    };
  },
  methods: {
    submitdata() {
      let StudId = this.StudIdval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        StudId,
        SessionId,
        TermId,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("graduatestudent", userdata)
        .then((resp) => {
          this.alumnis = resp;
          this.studentitems = null;
          this.ArmIdval = 0;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    ReversestudentGraduation(StudentId) {     
      this.onloader = "flex";
      this.$store
        .dispatch("ReversestudentGraduation", StudentId)
        .then((resp) => {
          this.alumnis = resp;         
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Reverted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getalumnibysession() {
      let id = this.SessionIdval;
      let idd = this.TermIdval;
      let searchitem = null;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        id,
        idd,
        searchitem,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getalumnibysession", userdata)
        .then((resp) => {
          this.alumnis = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregsession() {
      this.termitems = null;
      this.Sessionitems = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm() {
      this.studentitems = null;
      this.termitems = null;
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclassinclassassigned() {
      this.classitems = null;
      this.studentitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.ClassIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoptioninclassassigned() {
      this.studentitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.OptionIdval = 0;
      this.onloader = "flex";
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getarminclassassigned() {
      this.studentitems = null;
      this.armitems = null;
      this.ArmIdval = 0;
      this.onloader = "flex";
      let OptionId = this.OptionIdval;
      if (OptionId > 0) {
        this.$store
          .dispatch("getarm", OptionId)
          .then((resp) => {
            this.armitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getstudentbyarm() {
      this.studentitems = null;
      this.StudIdval = 0;
      let Id = 1;
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let ArmId = this.ArmIdval;
      let SubjectId = 1;
      let StudId = 1;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      if (ArmId > 0) {
        let userdata = {
          Id,
          ClassId,
          OptionId,
          ArmId,
          SubjectId,
          StudId,
          SessionId,
          TermId,
          processop,
          username,
          accesskey,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getstudentbyarm", userdata)
          .then((resp) => {
            this.studentitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getregsession();
      this.getclassinclassassigned();
    } 

    
  },
};
</script>

<style>
</style>
