<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link>
            <router-link to="/school/academic" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i>Academics
            </router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Class Note</span>
        </base-header>
        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div class="card bg-secondary">
                                <div class="card-body">
                                    <modal :show.sync="modals.viewModal" modal-classes="modal-dialog-centered modal-xl">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <div v-html="ClassNoteval"></div>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.viewModal = false">Close
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.approveModal" modal-classes="modal-dialog-centered">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <h3 class="text-center text-muted">Are you sure you want to approve this
                                                    lesson
                                                    Plan</h3>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.approveModal = false">No
                                            </base-button>

                                            <base-button type="success" @click.prevent="ApproveClassNote()">Yes
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.disapproveModal" modal-classes="modal-dialog-centered">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <label>Reason to disapprove the lesson Plan</label>
                                                <ckeditor v-model="DisapprovedCommentval" :editor="editor"
                                                    :config="editorConfig">
                                                </ckeditor>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.disapproveModal = false">Cancel
                                            </base-button>

                                            <base-button type="success" @click.prevent="DisapproveClassNote()">
                                                Disapprove
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.deleteModal" modal-classes="modal-dialog-centered">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <label>Reason to delete the lesson Plan</label>
                                                <ckeditor v-model="DeletedCommentval" :editor="editor"
                                                    :config="editorConfig">
                                                </ckeditor>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.deleteModal = false">Cancel
                                            </base-button>

                                            <base-button type="success" @click.prevent="DeleteClassNote()">
                                                Delete
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.newModal" modal-classes="modal-dialog-centered modal-xl">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <form role="form" @submit.prevent="submitdata">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label>Term</label>
                                                                <select v-model="TermIdval" class="form-control">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in termitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.SchTerm }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group" v-if="TermIdval">
                                                                <label>Class</label>
                                                                <select v-model="ClassIdval" class="form-control"
                                                                    @change="GetSubjectByClass()">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in classitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.Sch_Class }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group" v-if="ClassIdval">
                                                                <label>Subject</label>
                                                                <select v-model="SubjectIdval" class="form-control">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in subjectitems"
                                                                        :key="item.SubjectByClassId"
                                                                        v-bind:value="item.SubjectByClassId">
                                                                        {{ item.SchSubject }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" v-if="SubjectIdval">
                                                        <label>Topic</label>
                                                        <ckeditor v-model="ClassNoteTopicval" :editor="editor"
                                                            :config="editorConfig">
                                                        </ckeditor>
                                                    </div>
                                                    <div class="form-group" v-if="ClassNoteTopicval">
                                                        <label>Class Note</label>
                                                        <ckeditor v-model="ClassNoteDetailsval" :editor="editor"
                                                            :config="editorConfig">
                                                        </ckeditor>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.newModal = false">Cancel
                                            </base-button>
                                            <base-button v-if="ClassNoteDetailsval" type="success"
                                                @click.prevent="submitdata()">Submit
                                            </base-button>
                                        </template>
                                    </modal>
                                    <div class="justify-content-end form-inline">
                                        <button class="btn btn-success" v-on:click="openNew()">
                                            <i class="fa fa-edit"></i> New
                                        </button>
                                        <select v-model="TermIdval" class="btn btn-dark bg-dark form-control"
                                            @change="GetClassNotes()">
                                            <option value="0">Select Term</option>
                                            <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                                {{ item.SchTerm }}
                                            </option>
                                        </select>
                                        <select v-if="TermIdval" v-model="filterval"
                                            class="btn btn-dark bg-dark form-control" @change="filterResult()">
                                            <option value="0">- Filter By -</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Approved</option>
                                            <option value="3">Disapproved</option>
                                            <option value="4">Deleted</option>
                                            <option value="5">All</option>
                                        </select>

                                        <base-input v-if="TermIdval" class="input-group-alternative"
                                            placeholder="Search here..." addon-left-icon="fa fa-search"
                                            v-model="searchval">
                                        </base-input>
                                    </div>
                                    <div class="table-responsive mt-2">
                                        <table class="
                        table
                        table-dark
                        table-bordered
                        table-striped
                        table-hover
                      ">
                                            <thead>
                                                <tr>
                                                    <th>Lesson Plan</th>
                                                    <th>Subject</th>
                                                    <th>Class</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="searchedResultQuery">
                                                <tr v-for="item in searchedResultQuery" :key="item.Id">
                                                    <td v-html="item.ClassNoteTopic"></td>
                                                    <td>{{ item.SubjectName }}</td>
                                                    <td>{{ item.Sch_Class }}</td>
                                                    <td>
                                                        <button class="btn btn-secondary btn-sm"
                                                            v-on:click="GetByIdClassNote(item.ClassNoteId)">
                                                            <i class="fa fa-pen"></i> Edit
                                                        </button>

                                                        <button v-if="item.IsDisapproved != true"
                                                            class="btn btn-secondary btn-sm"
                                                            v-on:click="openDisapprove(item.ClassNoteId)">
                                                            <i class="fa fa-times"></i> Disapprove
                                                        </button>
                                                        <button v-if="item.IsApproved != true"
                                                            class="btn btn-secondary btn-sm"
                                                            v-on:click="openApprove(item.ClassNoteId)">
                                                            <i class="fa fa-check"></i> Approve
                                                        </button>
                                                        <button v-if="item.IsApproved == true"
                                                            class="btn btn-danger btn-sm"
                                                            v-on:click="openDelete(item.ClassNoteId)">
                                                            <i class="fa fa-times"></i> Delete
                                                        </button>
                                                        <button class="btn btn-secondary btn-sm"
                                                            v-on:click="openView(item.ClassNoteDetails)">
                                                            <i class="fa fa-eye"></i> View
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
            <!-- End charts-->
        </div>
    </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import ValidateAccessToken from "@/mixins/ValidateAccessToken.mixins";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import modal from '@/components/Modal'
export default {
    name: "ClassNote",
    mixins: [ValidateAccessToken, CheckStaffUser, CkEditorUploadAdapter],
    components: {
        PageSpinner, modal,
    },
    data() {
        return {
            accessDetails:null,
            ClassNoteIdval: 0,
            TermIdval: 0,
            DisapprovedCommentval: '',
            DeletedCommentval:'',
            ClassNoteval: '',
            ClassIdval: "",
            SubjectIdval: "",
            ClassNoteTopicval: "",
            ClassNoteDetailsval: "",
            IsModifiedval: false,
            termitems: null,
            subjectitems: null,
            classitems: null,
            resultItem: null,
            searchval: "",
            filterval: 0,
            onloader: "none",
            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [this.uploadPlugin],
            },
            modals: {
                approveModal: false,
                disapproveModal: false,
                deleteModal: false,
                viewModal: false,
                newModal: false
            }
        };
    },
    computed: {
        searchedResultQuery() {
            if (this.searchval) {
                return this.resultItem.filter((item) => {
                    return this.searchval
                        .toLowerCase()
                        .split(" ")
                        .every(
                            (v) =>
                                item.ClassNoteTopic.toLowerCase().includes(v) ||
                                item.SubjectName.toLowerCase().includes(v) ||
                                item.Sch_Class.toLowerCase().includes(v)
                        );
                });
            } else {
                return this.resultItem;
            }
        },
    },
    methods: {
        openNew() {
            this.modals.newModal = true
        },
        openDisapprove(ClassNoteId) {
            this.ClassNoteIdval = ClassNoteId
            this.modals.disapproveModal = true
        },
        openApprove(ClassNoteId) {
            this.ClassNoteIdval = ClassNoteId
            this.modals.approveModal = true
        },
        openDelete(ClassNoteId) {
            this.ClassNoteIdval = ClassNoteId
            this.modals.deleteModal = true
        },
        openView(ClassNote) {
            this.ClassNoteval = ClassNote
            this.modals.viewModal = true
        },
        filterResult() {
            if (this.filterval == 1) {
                this.GetClassNotePending()
            } else if (this.filterval == 2) {
                this.GetClassNoteApproved()
            } else if (this.filterval == 3) {
                this.GetClassNoteDisApproved()
            } else if (this.filterval == 4) {
                this.GetClasssNotesDeleted()
            } else if (this.filterval == 5) {
                this.GetClassNotes()
            }
        },
        ApproveClassNote() {
            this.onloader = "flex";
            this.$store
                .dispatch("ApproveClassNote", this.ClassNoteIdval)
                .then((resp) => {
                    this.modals.approveModal = false
                    this.GetClassNoteApproved()
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        DisapproveClassNote() {
            let ClassNoteId = this.ClassNoteIdval
            let Comment = this.DisapprovedCommentval
            let userdata = { ClassNoteId, Comment }
            this.onloader = "flex";
            this.$store
                .dispatch("DisapproveClassNote", userdata)
                .then((resp) => {
                    this.modals.disapproveModal = false
                    this.GetClassNoteDisApproved()
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        DeleteClassNote() {
            let ClassNoteId = this.ClassNoteIdval
            let Comment = this.DisapprovedCommentval
            let userdata = { ClassNoteId, Comment }
            this.onloader = "flex";
            this.$store
                .dispatch("DeleteClassNote", userdata)
                .then((resp) => {
                    this.modals.disapproveModal = false
                    this.GetClassNoteDeleted()
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetByIdClassNote(Id) {
            this.onloader = "flex";
            this.$store
                .dispatch("GetByIdClassNote", Id)
                .then((resp) => {
                    this.ClassNoteIdval = Id;
                    this.ClassIdval = resp.ClassId;
                    this.GetSubjectByClass();
                    this.SubjectIdval = resp.SubjectByClassId;
                    this.ClassNoteDetailsval = resp.ClassNoteDetails;
                    this.ClassNoteTopicval = resp.ClassNoteTopic;
                    this.IsModifiedval = true;
                    this.onloader = "none";
                    this.openNew();
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetClassNotes() {
            this.onloader = "flex";
            this.$store
                .dispatch("GetClassNotes", this.TermIdval)
                .then((resp) => {
                    this.resultItem = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetClassNotePending() {
            this.onloader = "flex";
            this.$store
                .dispatch("GetClassNotesPending", this.TermIdval)
                .then((resp) => {
                    this.resultItem = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetClassNoteApproved() {
            this.onloader = "flex";
            this.$store
                .dispatch("GetClassNotesApproved", this.TermIdval)
                .then((resp) => {
                    this.resultItem = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetClassNoteDisApproved() {
            this.onloader = "flex";
            this.$store
                .dispatch("GetClassNotesDisApproved", this.TermIdval)
                .then((resp) => {
                    this.resultItem = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetClasssNoteDeleted() {
            this.onloader = "flex";
            this.$store
                .dispatch("GetClasssNotesDeleted", this.TermIdval)
                .then((resp) => {
                    this.resultItem = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getterm() {
            this.termitems = null;
            this.resultItem = null;
            this.TermIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("getterm")
                .then((resp) => {
                    this.termitems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getclassassigned() {
            this.classitems = null;
            this.subjectitems = null;
            this.ClassIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("getclassinsubjectassigned")
                .then((resp) => {
                    this.classitems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetSubjectByClass() {
            this.subjectitems = null;
            this.SubjectIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("GetSubjectByClass", this.ClassIdval)
                .then((resp) => {
                    this.subjectitems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        submitdata() {
            let TermId = this.TermIdval;
            let ClassNoteId = this.ClassNoteIdval;
            let SubjectByClassId = this.SubjectIdval;
            let ClassNoteTopic = this.ClassNoteTopicval;
            let ClassNoteDetails = this.ClassNoteDetailsval;
            let IsModified = this.IsModifiedval
            let Username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                TermId,
                ClassNoteId,
                SubjectByClassId,
                ClassNoteTopic,
                ClassNoteDetails,
                IsModified,
                Username,
                accesskey,
            };
            this.onloader = "flex";
            this.$store
                .dispatch("ProcessClassNote", userdata)
                .then((resp) => {
                    this.modals.newModal = false;
                    this.ClassIdval = 0;
                    this.SubjectIdval = 0;
                    this.ClassNoteTopicval = "";
                    this.ClassNoteDetailsval = "";
                    this.IsModifiedval = false;
                    this.onloader = "none";
                    this.GetClassNotes();

                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        frontEndDateFormat(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },       
    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        } else {
            this.getterm();
            this.getclassassigned();
        }
    },
};
</script>

<style></style>