<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Student Archievement</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div v-for="item in studentarchievementitem" :key="item.Id">
                                <div class="alert alert-warning">
                                    <h1 class="text-center text-capitalize">
                                        <b class=" mr-3 badge badge-dark">{{ item.DateCreated | formatDate }}</b>
                                    </h1>
                                    <div v-html="item.Heading"></div>
                                    <img height="100" :src="$store.state.auth.baseurl + item.ImgPath.substr(1)" alt="">
                                    <div v-html="item.Detail"></div>

                                </div>
                                <div class="text-center">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->

        </div>

    </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'studentarchievement',
    components: {
        PageSpinner
    },
    data() {
        return {
            accessDetails: null,
            dataid: 1,
            StudIdval: localStorage.getItem('StudId') || '',
            studentarchievementitem: null,
            onloader: 'none'
        }
    },
    methods: {

        getstudentarchievement: function () {
            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getstudentarchievement', StudId)
                    .then((resp) => {
                        this.studentarchievementitem = resp;
                        this.onloader = 'none'
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStudent == true || this.accessDetails.isStudentGuardian == true) {
            this.getstudentarchievement();            
        } else {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        }

    }
};
</script>

<style></style>
