var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.accessDetails)?_c('div',[(_vm.accessDetails.isSchoolStaff == true)?_c('Staffportal',{attrs:{"userName":_vm.accessDetails.fullName,"userPhoneNo":_vm.accessDetails.phoneNumber,"userEmailAddress":_vm.accessDetails.emailAddress}}):_vm._e(),(
      _vm.accessDetails.isSchoolStudent == true ||
        _vm.accessDetails.isStudentGuardian == true
    )?_c('Studentportal',{attrs:{"userName":_vm.accessDetails.fullName,"userPhoneNo":_vm.accessDetails.phoneNumber,"userEmailAddress":_vm.accessDetails.emailAddress}}):_vm._e(),(
      _vm.accessDetails.isSuperAdmin == true ||
        _vm.accessDetails.isAdmin == true ||
        _vm.accessDetails.isSupportAdmin == true ||
         _vm.accessDetails.isAffliateMarketer == true
    )?_c('Adminportal',{attrs:{"userName":_vm.accessDetails.fullName,"userPhoneNo":_vm.accessDetails.phoneNumber,"userEmailAddress":_vm.accessDetails.emailAddress}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }