<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard
      </router-link>
      <router-link to="/school/staff-registration" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Staffs
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i>Deleted Staff(s)
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="justify-content-end form-inline">
          <router-link to="/staff" class="btn btn-default">
            <i class="fa fa-users"></i> Back to Staffs</router-link
          >
          <base-input
            class="input-group-alternative"
            placeholder="Search here..."
            addon-left-icon="fa fa-search"
            v-model="searchval"
          >
          </base-input>
        </div>
        <div class="row">
          <div
            class="col-md-6 col"
            v-for="item in searchedResultQuery"
            :key="item.StaffId"
          >
            <div
              class="card animate__animated animate__flipInX text-center m-2"
            >
              <div class="card-body bg-success shrink">
                <div class="row">
                  <div class="col-md-4">
                    <img
                      v-if="item.Passport"
                      :src="item.Passport"
                      class="rounded"
                      alt=""
                      height="50"
                    />
                    <img
                      v-else
                      src="img/brand/loguser.png"
                      class="rounded"
                      alt=""
                      height="50"
                    />
                    <h5 class="text-white">
                      <small>{{ item.StaffName }}</small>
                    </h5>
                    <h6 class="text-white">
                      <small
                        >{{ item.SeviceNo }} | {{ item.EmailAddress }} |
                        {{ item.PhoneNumber }}</small
                      >
                    </h6>
                    <div class="mb-3">
                      <button
                        v-if="item.IsDeleted"
                        class="btn btn-default btn-sm"
                        v-on:click="deletestaff(item.StaffId)"
                      >
                        <i class="fa fa-trash-restore"></i> Restore
                      </button>
                    </div>
                  </div>
                  <div class="col-md-8 text-left">
                    <div class="card mb-3">
                      <div class="card-body">
                        <h6>CLASS(ES)</h6>
                        <div class="row">
                          <div
                            class="col"
                            v-for="itemClass in item.StaffClass"
                            :key="itemClass.StaffClassId"
                          >
                            <div class="bg-secondary m-2 p-2">
                              <span class="small">
                                <small
                                  >{{ itemClass.ClassName }} -
                                  {{ itemClass.SectionName }}</small
                                >
                                <button
                                  class="btn btn-outline-danger btn-sm"
                                  v-on:click="
                                    deleteclassteacher(itemClass.StaffClassId)
                                  "
                                >
                                  <i class="fa fa-trash"></i></button
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h6>SUBJECT(S)</h6>
                        <div class="row">
                          <div
                            class="col"
                            v-for="itemSubject in item.StaffSubject"
                            :key="itemSubject.StaffSubjectId"
                          >
                            <div class="bg-secondary m-2 p-2">
                              <span class="small">
                                <small>{{ itemSubject.SubjectName }}</small>
                                <button
                                  class="btn btn-outline-danger btn-sm"
                                  v-on:click="
                                    deletesubjectteacher(
                                      itemSubject.StaffSubjectId
                                    )
                                  "
                                >
                                  <i class="fa fa-trash"></i></button
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
export default {
  name: "staff",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner,
  },
  data() {
    return {
      modals: {
        modal0: false,
      },
      dataid: 1,
      staffs: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.staffs.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.SeviceNo.toLowerCase().includes(v) ||
                item.StaffName.toLowerCase().includes(v) ||
                item.EmailAddress.toLowerCase().includes(v) ||
                item.PhoneNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.staffs;
      }
    },
  },
  methods: {
    deletestaff(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletestaff", Id)
        .then(() => {
          this.getstaffs();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Processed successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    async getstaffs() {
      this.onloader = "flex";
      await this.$store
        .dispatch("GetDeletedStaffs")
        .then((resp) => {
          this.staffs = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    async deleteclassteacher(Id) {
      this.onloader = "flex";
      await this.$store
        .dispatch("deleteclassteacher", Id)
        .then(() => {
          this.getstaffs();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    deletesubjectteacher(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletesubjectteacher", Id)
        .then(() => {
          this.getstaffs();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
  },
  mounted() {
    this.getstaffs();
  },
};
</script>

<style>
</style>
