<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/school/environment-settings" class="text-white">
         <i class="fa fa-ellipsis-v px-2"></i>Settings
      </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Term Close/Open</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div v-if="openform">
          <div class="card bg-secondary mb-0">
             <div class="card-header">
                <h3 class="card-title">Registration</h3>
              </div>
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Session</label>
                        <select
                          v-model="session"
                          class="form-control"
                          :disabled="inputdisable"
                        >
                        <option value="0">- Select Session -</option>
                          <option
                            v-for="item in SessionItems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.SchSession }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
                     <div class="col-md-4">
                      <div class="form-group">
                        <label>Select Term</label>
                        <select
                          v-model="term"
                          class="form-control"                         
                        >
                        <option value="0">- Select Term -</option>
                        <option
                            v-for="item in schterm"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.SchTerm }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Next Term will open on (Mid Term)</label>
                        <base-input
                          type="date"
                          v-model="nextmidtermbegin"
                        ></base-input>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>No. of time school open (Mid Term)</label>
                        <input
                          type="number"
                          v-model.number="nooftimemidtermopen"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Next Term will open on (Full Term)</label>
                        <input
                          type="date"
                          v-model="nexttermbegin"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>No. of time school open (Full Term)</label>
                        <input
                          type="number"
                          v-model.number="nooftimeopen"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <base-checkbox class="mb-3" v-model="iscurrent"
                          >Is Current Term
                        </base-checkbox>
                      </div>
                    </div>

                  </div>
                  <div class="text-center mb-3">
                    <button 
                      type="button"
                      @click.stop="closeform()"
                      class="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>         
         
           <div class="justify-content-end form-inline">  
              <button
            type="button"
            @click.prevent="newterm"
            class="btn btn-default"
          >
            <i class="ni ni-fat-add"></i>Add New Term
          </button>            
                  <base-input
              class="input-group-alternative"
              placeholder="Search here..."            
              addon-left-icon="fa fa-search"
              v-model="searchval"
            >
            </base-input>          
           </div>
         <div class="table-responsive mt-2">
              <table
                class="table table-dark table-bordered table-striped table-hover"
              >
              <thead>
                <tr>
                  <th></th>
                  <th>Is Current</th>                 
                  <th>Term</th>
                  <th>Open (Mid Term)</th>
                  <th>Next Mid Term Begin</th>
                  <th>Open (Full Term)</th>
                  <th>Next Full Term Begin</th>                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in searchedResultQuery" :key="item.Id">
                  <td>
                    <button
                      class="btn btn-secondary btn-sm"
                      v-on:click="edititem(item.Id)"
                    >
                      <i class="fa fa-edit"></i> Edit
                    </button>
                  </td>
                  <td>{{ item.IsCurrent }}</td>
                  <td>{{ item.SchTerm }} - {{ item.SchSession }}</td>                 
                  <td>{{ item.MidTermOpenNo }}</td>
                  <td>{{ item.NextMidTermBeging | formatDate }}</td>
                  <td>{{ item.FullTermOpenNo }}</td>  
                  <td>{{ item.NextTermBeging | formatDate }}</td>               
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import swal from "sweetalert"
import SessionMixin from "@/mixins/Session.mixin"
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "term",
   mixins: [SessionMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      session: 0,
      term: 0,
      nextmidtermbegin: null,
      nooftimemidtermopen: null,
      nexttermbegin: null,
      nooftimeopen: null,
      iscurrent: false,
      examscore: "",
      cascore: "",
      midtermscore: null,
      topicscore: null,
      registerdterm: null,
      SessionItems: null,
      schterm: null,
      process: "N",
      rowitem: [],
      openform: false,
      inputdisable: false,
      searchval:'',
      operationval:0,
      onloader: "none",
    };
  },
computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.registerdterm.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.SchSession.toLowerCase().includes(v) ||
                item.SchTerm.toLowerCase().includes(v) 
            );
        });
      } else {
        return this.registerdterm;
      }
    }
  },
  methods: {
    newterm: function () {
      this.nextmidtermbegin = null;
      this.nooftimemidtermopen = null;
      this.nexttermbegin = null;
      this.nooftimeopen = "";
      this.iscurrent = false;
      this.examscore = "";
      this.cascore = "";
      this.midtermscore = "";
      this.topicscore = "";
      this.openform = true;
      this.process = "N";
      this.inputdisable = false;
    },
    closeform: function () {
      this.nextmidtermbegin = null;
      this.nooftimemidtermopen = null;
      this.nexttermbegin = null;
      this.nooftimeopen = "";
      this.iscurrent = false;
      this.examscore = "";
      this.cascore = "";
      this.midtermscore = "";
      this.topicscore = "";
      this.openform = true;
      this.openform = false;
      this.process = "N";
      this.inputdisable = false;
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getregisteredtermbyid", Id)
        .then((resp) => {
          this.session = resp.SessionId;
          this.term = resp.TermId;
          if (resp.NextMidTermBeging !== null) {
            this.nextmidtermbegin = this.frontEndDateFormat(
              resp.NextMidTermBeging
            );
          }
          if (resp.NextTermBeging !== null) {
            this.nexttermbegin = this.frontEndDateFormat(resp.NextTermBeging);
          }
          this.nooftimemidtermopen = resp.MidTermOpenNo;
          this.nooftimeopen = resp.FullTermOpenNo;
          this.iscurrent = resp.IsCurrent;
          this.examscore = resp.ExamScore;
          this.cascore = resp.CAScore;
          this.midtermscore = resp.MidTermScore;
          this.topicscore = resp.TopicScore;
          this.openform = true;
          this.openform = true;
          this.inputdisable = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          if (
            err.response.data === "Access Expired" ||
            err.response.data === "Invalid Credential"
          ) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          }
        });
    },
    submitdata: function () {
      let sessionid = this.session;
      let termid = this.term;
      let midtermnexttermnegin = null;
      if (this.midtermnexttermnegin != "") {
        midtermnexttermnegin = this.nextmidtermbegin;
      }
      let NextTermBegin = null;
      if (this.nexttermbegin != "") {
        NextTermBegin = this.nexttermbegin;
      }
      let midtermnoschopen = null;
      if (this.nooftimemidtermopen != "") {
        midtermnoschopen = this.nooftimemidtermopen;
      }
      let NoofTimeSchOpen = null;
      if (this.nooftimeopen != "") {
        NoofTimeSchOpen = this.nooftimeopen;
      }
      let IsCurrent = this.iscurrent;
      let ExamScore = this.examscore;
      let CAScore = this.cascore;
      let MidtermScore = this.midtermscore;
      let TopicScore = this.topicscore;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let userData={ sessionid,
          termid,
          midtermnexttermnegin,
          midtermnoschopen,
          NextTermBegin,
          NoofTimeSchOpen,
          ExamScore,
          CAScore,
          MidtermScore,
          TopicScore,
          IsCurrent,
          processop,
          username,
          accesskey}
          console.log(userData)
      this.$store
        .dispatch("termregistration", userData)
        .then((resp) => {
          this.registerdterm = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregisteredterm: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getregisteredterm")
        .then((resp) => {
          this.registerdterm = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },    
    getterm: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getterm")
        .then((resp) => {
          this.schterm = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
    if (
      userDetails.isSchoolStaff == false
    ) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    } else {
      this.getregisteredterm();     
      this.getterm();
    }
  },
};
</script>

<style></style>
