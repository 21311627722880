<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard
            </router-link>
            <router-link to="/school/staff-registration" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i>Staff
            </router-link>
            <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Subject Teacher</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">

            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-body">
                                    <div class="form-horizontal">
                                        <form role="form" @submit.prevent="submitdata">
                                            <div class="card">
                                                <div class="card-body">
                                                    <img v-if="staffPassport" :src="staffPassport" class="rounded"
                                                        alt="" height="50" />
                                                    <img v-else src="img/brand/loguser.png" class="rounded" alt=""
                                                        height="50" />
                                                    <h5>
                                                        <small>{{ staffName }}</small>
                                                    </h5>
                                                    <div class="form-group">
                                                        <select v-model="ClassIdval" class="form-control dropdown"
                                                            @change="getoption()">
                                                            <option value="0">Select Class</option>
                                                            <option v-for="item in schclass" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.Sch_Class }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" v-if="ClassIdval">
                                                        <select v-model="OptionIdval" class="form-control"
                                                            @change="getschsubject()">
                                                            <option value="0">Select Option</option>
                                                            <option v-for="item in optionitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.ClassOption }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" v-if="OptionIdval">
                                                        <select v-model="SubjectIdval" class="form-control">
                                                            <option value="0">Select Subject</option>
                                                            <option v-for="item in subjectitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SchSubject }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mb-3" v-if="SubjectIdval">
                                                <button type="submit" class="btn btn-primary my-4">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="justify-content-end form-inline">
                                <base-input class="input-group-alternative" placeholder="Search here..."
                                    addon-left-icon="fa fa-search" v-model="searchval">
                                </base-input>
                            </div>
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Subject</th>
                                            <th>Option</th>
                                            <th>Class</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in searchedResultQuery" :key="item.Id">
                                            <td><button class="btn btn-danger btn-sm"
                                                    v-on:click="deletesubjectteacher(item.Id)"><i
                                                        class="fa fa-times"></i></button></td>
                                            <td>{{ item.SubjectName }}</td>
                                            <td>{{ item.OptionName }}</td>
                                            <td>{{ item.ClassName }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'subjectteacher',
    components: {
        PageSpinner
    },
    data() {
        return {
            accessDetails:null,
            dataid: 1,
            ClassIdval: 0,
            OptionIdval: 0,
            SubjectIdval: 0,
            StaffIdval: localStorage.getItem("StaffId"),
            optionitems: null,
            subjectitems: null,
            subjectteacheritem: null,
            schclass: null,
            staffs: null,
            staffName: "",
            staffPassport: "",
            searchval: '',
            process: 'N',
            onloader: 'none'
        }
    },
    computed: {
        searchedResultQuery() {
            if (this.searchval) {
                return this.subjectteacheritem.filter((item) => {
                    return this.searchval
                        .toLowerCase()
                        .split(" ")
                        .every(
                            (v) =>
                                item.SubjectName.toLowerCase().includes(v) ||
                                item.OptionName.toLowerCase().includes(v) ||
                                item.ClassName.toLowerCase().includes(v)
                        );
                });
            } else {
                return this.subjectteacheritem;
            }
        },
    },
    methods: {
        deletesubjectteacher(Id) {
            this.onloader = 'flex'
            let OptionId = this.OptionIdval;
            this.$store.dispatch('deletesubjectteacher', {
                Id,
                OptionId
            })
                .then(() => {
                    this.getstaffs;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata() {
            let StaffId = this.StaffIdval;
            let SubjectId = this.SubjectIdval;
            let OptionId = this.OptionIdval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StaffId,
                SubjectId,
                OptionId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('processsubjectteacher', userdata)
                .then(() => {
                    this.getstaffs();
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getclass() {
            this.classitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.classteacheritem = null;
            this.onloader = 'flex'
            this.$store.dispatch('getclass')
                .then(resp => {
                    this.schclass = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getoption() {

            this.optionitems = null;
            this.armitems = null;
            this.classteacheritem = null;
            this.OptionIdval = 0;
            this.onloader = 'flex'
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.$store.dispatch('getoption', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getschsubject() {
            this.subjectitems = null;
            this.classteacheritem = null;
            this.SubjectIdval = 0;
            this.onloader = 'flex'
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let SchsubId = 1;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                ClassId,
                SchsubId,
                OptionId,
                processop,
                username,
                accesskey
            }

            if (OptionId > 0) {
                this.$store.dispatch('getschsubject', userdata)
                    .then(resp => {
                        this.subjectitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none';
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getstaffs() {
            this.onloader = "flex";
            this.$store
                .dispatch("getstaffbyid", this.StaffIdval)
                .then((resp) => {
                    this.staffs = resp;
                    this.staffPassport = resp.Passport
                    this.staffName = resp.StaffName
                    this.subjectteacheritem = resp.StaffSubject
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        frontEndDateFormat(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat(date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse(resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
        } else {
            if (localStorage.getItem("StaffId") == null || localStorage.getItem("StaffId") == undefined) {
                this.$router.push("/staff");
            } else {
                this.getstaffs();
                this.getclass();
            }
        }

    }
};
</script>

<style></style>
