<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white"> Dashboard</router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Community</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <modal :show.sync="modals.newModal" modal-classes="modal-dialog-centered modal-l">
                        <div class="card bg-secondary border-0 mb-0">
                            <div class="card-body">
                                <div class="card border-0 mb-0">
                                    <div class="card-body">
                                        <div class="form-horizontal">
                                            <form role="form">
                                                <div class="form-group">
                                                    <label>Community Name</label>
                                                    <input v-model="communityNameval" class="form-control" />
                                                </div>
                                                <div class="form-group" v-if="communityNameval">
                                                    <label>Community Details</label>
                                                    <quillEditor v-model="communityDetailsval" ref="newsTitleval"
                                                        :options="editorOption">

                                                    </quillEditor>
                                                </div>
                                                <div class="row" v-if="communityDetailsval">
                                                    <div class="col">
                                                        <img v-if="imagePathval" :src="imagePathval"
                                                            class="img-fluid" />
                                                        <img v-else src="img/brand/empty.png" class="img-fluid" />
                                                    </div>
                                                    <div class="col">
                                                        <ImageUploader @emitOutImgPathval="UpdatecommunityLogoval" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template slot="footer">
                            <base-button type="default" @click="modals.newModal = false">Cancel
                            </base-button>
                            <base-button v-if="communityLogoval" type="success" @click.prevent="SubmitData()">Submit
                            </base-button>
                        </template>
                    </modal>
                    <div class="card">
                        <div class="card-body">
                            <div class="justify-content-end form-inline">
                                <button class="btn btn-success" v-on:click="openNew()">
                                    <i class="fa fa-edit"></i> New
                                </button>
                                <base-input class="input-group-alternative" placeholder="Search here..."
                                    addon-left-icon="fa fa-search" v-model="searchValueval">
                                </base-input>
                            </div>
                            <div class="row" v-if="communityItems">
                                <div class="col-md-4" v-for="item in communityItems" :key="item.communityId">
                                    <div class="card mt-2">
                                        <div class="card-body bg-secondary">
                                            <div class="card">
                                                <img :src="item.communityLogo
            " class="card-img-top" alt="...">
                                                <div class="card-body">
                                                    <h5 class="card-title" v-html="item.communityName"></h5>
                                                    <div class="card-text" v-html="item.communityDetails"></div>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <button class="btn btn-secondary btn-sm"
                                                        v-on:click="edititem(item.communityId)">
                                                        <i class="fa fa-edit"></i> Edit
                                                    </button>
                                                    <button class="btn btn-danger btn-sm"
                                                        v-on:click="edititem(item.communityId)">
                                                        <i class="fa fa-times"></i> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import PageSpinner from "@/components/PageSpinner";
import ValidateAccessToken from "@/mixins/ValidateAccessToken.mixins";
import Alert from "@/mixins/Alert.mixins"
import ImageUploader from "@/components/ImageUploader"
import modal from '@/components/Modal'

export default {
    name: "Community",
    mixins: [ValidateAccessToken, Alert],
    components: {
        PageSpinner,
        quillEditor,
        ImageUploader,
        modal
    },
    data() {
        return {
            accessDetails: null,
            communityIdval: 0,
            communityNameval: '',
            communityDetailsval: '',
            communityLogoval: '',
            imagePathval: '/img/brand/empty.png',
            searchValueval: "All",
            pageNumberval: 1,
            pageSizeval: 10,
            totalRecordsval: 0,
            communityItems: null,
            isModified: false,
            onloader: "none",
            editorOption: {
                debug: 'info',
                placeholder: 'Type in here',
                readonlly: true,
                theme: 'snow'
            },
            modals: {
                newModal: false
            }
        };
    },
    methods: {
        openNew() {
            this.isModified = false;
            this.modals.newModal = true
        },
        resetInput() {
            this.communityIdval = 0;
            this.communityNameval = '';
            this.communityDetailsval = "";
            this.communityLogoval = null;
            this.imagePathval='/img/brand/empty.png';
        },
        edititem(Id) {
            this.resetInput();
            this.onloader = "flex";
            this.$store
                .dispatch("Community/GetCommunityById", Id)
                .then((resp) => {                   
                    if (resp.responseCode == "00") {
                        this.communityIdval = resp.data.communityId;
                        this.communityNameval = resp.data.communityName;
                        this.communityDetailsval = resp.data.communityDetails;
                        this.communityLogoval = resp.data.communityLogo;
                        this.imagePathval=resp.data.communityLogo;
                        this.isModified = true;
                        this.modals.newModal = true
                    }
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.ShowAlertError(err.response.data, '');
                });
        },
        SubmitData() {
            let communityId = this.communityIdval;
            let communityName = this.communityNameval;
            let communityDetails = this.communityDetailsval;
            let communityLogo = this.communityLogoval;
            this.modals.newModal = false;
            let userdata = {};
            this.onloader = "flex";
            let operationVal = "";
            if (this.isModified == false) {
                operationVal = "Community/CreateCommunity";
                userdata = {
                    communityName,
                    communityDetails,
                    communityLogo,
                }
            } else {
                operationVal = "Community/UpdateCommunity";
                userdata = {
                    communityId,
                    communityName,
                    communityDetails,
                    communityLogo,
                }
            }
            this.$store
                .dispatch(operationVal, userdata)
                .then((resp) => {                  
                    this.onloader = "none";
                    if (resp.responseCode == '00') {
                        this.GetCommunities();
                        this.resetInput();
                        this.modals.newModal = false;
                        this.ShowAlertSuccess('Submitted successfully', '');
                    } else {
                        this.ShowAlertError(resp.responseMessage, '');
                    }

                })
                .catch((err) => {
                    this.onloader = "none";
                    this.ShowAlertError(err.response.data, '');
                });
        },
        UpdatecommunityLogoval(data) {
            this.communityLogoval = data;
            this.imagePathval=data;
        },
        GetCommunities() {
            this.onloader = "flex";
            this.$store
                .dispatch("Community/GetCommunities")
                .then((resp) => {                  
                    this.communityItems = resp.data;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.ShowAlertError(err.response.data, '');
                });
        },
       
        frontEndDateFormat(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },

    },
    mounted() {
        const userDetails = this.accessDetails = this.$store.getters['auth/_user'];
        if (
            userDetails.isSuperAdmin == true ||
            userDetails.isAdmin == true ||
            userDetails.isSupportAdmin == true
        ) {
            this.GetCommunities();
        } else {
            this.$store.dispatch("auth/Logout");
            this.$router.push("/login");
        }
    },
};
</script>

<style></style>