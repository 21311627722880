<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <div class="row">
      <div class="col-lg-8 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-4">
        <div class="px-5 py-5 text-white bg-dark" v-if="isSuccessful == false">
          <div class="text-center text-muted my-3">
            <p class="mt-3"> Enter your username to retrieve your password</p>
          </div>
          <form role="form" @submit.prevent="submitdetails">
            <base-input class="input-group-alternative mb-3" placeholder="Username" addon-left-icon="ni ni ni-single-02"
              v-model="username">
            </base-input>

            <div class="text-center">
              <button type="submit" class="my-4 btn btn-primary" :disabled="$store.state.appdatas.disenableifNotOnline">
                Submit <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
          <div class="text-center pt-2">
            <router-link to="/login" class="text-primary"><small>Back to Login</small></router-link>
          </div>
        </div>
        <div class="px-5 py-5 text-white bg-success" v-else>
          <div class="text-center text-white my-3">
            <p class="mt-3">Your password has been reset successfully</p>
          </div>
          <div class="text-center pt-2">
            <router-link to="/login" class="btn btn-outline-dark"><i class="fa fa-arrow-left"></i> Back to Login</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PageSpinner from "@/components/PageSpinner"
import RandomWord from "@/mixins/random-word-generator.mixin"
import Alert from "@/mixins/Alert.mixins"
export default {
  name: "Retrieve-password",
  mixins: [RandomWord, Alert],
  components: { PageSpinner },
  data() {
    return {
      username: "",
      errormsg: "",
      isSuccessful: false,
      onloader: "none",
    };
  },
  methods: {
    submitdetails: function () {
      let Username = `${this.RandomStringNumber(30)}${this.$store.state.auth.UsPad}${btoa(this.username)}`;     
      this.onloader = "flex";
      
      this.$store
        .dispatch("auth/RetrievePassword", Username)
        .then((resp) => {
          this.onloader = "none";
          if (resp.responseCode == '00') {
            this.isSuccessful = true
            this.ShowAlertSuccess(resp.responseMessage, '');
          } else {
            this.ShowAlertError(resp.responseMessage, '');
          }
        })
        .catch((err) => {
          this.onloader = "none";
          this.ShowAlertError(err.response.data, '');
        });
    },
  },
};
</script>

<style></style>
