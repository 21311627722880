<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
       Dashboard</router-link><i class="ni ni-bold-right text-white"></i>
      <router-link to="/student/exam" class="text-white">
       CBT
      </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i>CBT Question
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h2><i class="fa fa-tv"></i> {{ SubjectNameval }}</h2>
                      <h1 class="h1">
                        <span class="badge badge-danger">{{ ExamTimeval }}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div>
                    <div>
                      <div v-if="StartExam == false" class="pt-3 text-center">
                        <button class="btn btn-dark" @click.prevent="startcbtpractice">
                          Start Exam
                        </button>
                      </div>
                      <div v-if="StartExam" class="pt-3">
                        <h1 class="text-center">
                          <b class="badge badge-dark">Question {{ CurrentSNo }}</b>
                        </h1>
                        <div class="card">
                          <div class="card-body bg-secondary">
                            <div class="pt-2 text-dark" v-html="CurrentQuestion"></div>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-body bg-secondary">
                            <b class="badge badge-primary"><input type="checkbox" class="mr-3 display-1"
                                @change="submitquestionansweredA()" v-model="ChkOptionA" />A.
                            </b>
                            <span v-html="CurrentOptionA" class="display-1"></span>
                            <hr />
                            <b class="badge badge-primary">
                              <input type="checkbox" class="mr-3 display-1" @change="submitquestionansweredB()"
                                v-model="ChkOptionB" />B.
                            </b>
                            <span v-html="CurrentOptionB" class="display-1"></span>
                            <hr />
                            <b class="badge badge-primary">
                              <input type="checkbox" class="mr-3 display-1" @change="submitquestionansweredC()"
                                v-model="ChkOptionC" />C.
                            </b>
                            <span v-html="CurrentOptionC" class="display-1"></span>
                            <hr />
                            <b class="badge badge-primary"><input type="checkbox" class="mr-3 display-1"
                                @change="submitquestionansweredD()" v-model="ChkOptionD" />D.
                            </b>
                            <span v-html="CurrentOptionD" class="display-1"></span>
                            <hr />
                          </div>
                        </div>
                        <div>
                          <div class="p-3 text-center">
                            <button v-if="backbtn" @click.prevent="prevqtn" class="btn btn-default">
                              <i class="fa fa-arrow-left"></i> Previous
                            </button>

                            <button class="btn btn-default" @click.prevent="submitqtn">
                              <i class="ni ni-cloud-upload-96"></i> Submit
                            </button>
                            <button v-if="nextbtn" class="btn btn-default" @click.prevent="nextqtn">
                              Next <i class="fa fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "exam",
  components: {
    PageSpinner
  },
  data() {
    return {
      accessDetails: null,
      dataid: 1,
      ExamCodeval: localStorage.getItem("ExamCode") || "",
      CbtPracticeIdVal: "",
      ExamPeriodval: "",
      Timerval: "",
      NoofQuestiontoDisplayval: "",
      SubjectNameval: "",
      ExamTimeval: "",
      CurrentQuestion: "",
      CurrentSNo: "",
      CurrentQuestionId: "",
      CurrentOptionA: "",
      CurrentOptionAId: "",
      CurrentOptionB: "",
      CurrentOptionBId: "",
      CurrentOptionC: "",
      CurrentOptionCId: "",
      CurrentOptionD: "",
      CurrentOptionDId: "",
      SelectedAnswerId: "",
      ChkOptionA: false,
      ChkOptionB: false,
      ChkOptionC: false,
      ChkOptionD: false,
      IsClosedval: false,
      ClosedDateval: null,
      QuestionSelectedId: "",
      Openquestion: false,
      StartExam: false,
      Question: null,
      nextbtn: true,
      backbtn: false,
      alertmsg: false,
      process: "N",
      onloader: "none",
    };
  },
  methods: {
    nextqtn() {
      let SNo = this.CurrentSNo;
      SNo++;
      let MaxNo = this.NoofQuestiontoDisplayval;
      if (SNo > MaxNo) {
        SNo = MaxNo;
      }
      if (SNo == MaxNo) {
        this.nextbtn = false;
        this.backbtn = true;
      }
      if (SNo >= 1 && SNo < MaxNo) {
        if (SNo == 1) {
          this.nextbtn = true;
          this.backbtn = false;
        } else {
          this.nextbtn = true;
          this.backbtn = true;
        }
      }
      this.CurrentSNo = SNo;
      this.getpracticequestionforcbtbyid(SNo);
    },
    prevqtn() {
      let SNo = this.CurrentSNo;
      SNo--;
      let MaxNo = this.NoofQuestiontoDisplayval;
      if (SNo < 1) {
        SNo = 1;
      }
      if (SNo == MaxNo) {
        this.nextbtn = false;
        this.backbtn = true;
      }
      if (SNo >= 1 && SNo <= MaxNo) {
        if (SNo == 1) {
          this.nextbtn = true;
          this.backbtn = false;
        } else {
          this.nextbtn = true;
          this.backbtn = true;
        }
        this.CurrentSNo = SNo;
        this.getpracticequestionforcbtbyid(SNo);
      }
    },
    submitqtn() {
      if (this.Timerval > 0) {
        swal({
          title: "Confirmation",
          text: "Are you sure, you want to submit now?",
          type: "warning",
          buttons: true,
          dangerMode: true,
        }).then((result) => {
          if (result) {
            this.endcbtpractice();
            this.StartExam = false;
            this.Question = null;
            this.Timerval = 0;
            this.$router.push("/cbtresultsummary");
          }
        });
      } else {
        this.endcbtpractice();
        this.StartExam = false;
        this.Question = null;
        this.Timerval = 0;
        this.$router.push("/cbtresultsummary");
      }
    },
    getcbtpractice: function () {
      let id = 1;
      let searchitem = this.ExamCodeval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userData = {
        id,
        searchitem,
        username,
        accesskey,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("getcbtpractice", userData)
        .then((resp) => {
          this.CbtPracticeIdVal = resp.Id;
          this.ExamPeriodval = this.Timerval = resp.ExamTime;
          this.ExamTimeval = this.formattime(this.ExamPeriodval);
          this.NoofQuestiontoDisplayval = resp.QtnNo;
          this.SubjectNameval = resp.ExamSubject;
          this.SchSessionval = resp.SchSession;
          this.SchoolTermval = resp.SchoolTerm;
          this.onloader = "none";
        })
        .catch(() => {
          this.onloader = "none";
          this.$router.push("/cbtprep");
        });
    },
    startcbtpractice: function () {
      let ExamCode = this.ExamCodeval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        ExamCode,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("startcbtpractice", userdata)
        .then(() => {
          this.getpracticequestionforcbt();
          this.StartExam = true;
          this.examtimer();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    endcbtpractice: function () {
      let ExamCode = this.ExamCodeval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        ExamCode,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("endcbtpractice", userdata)
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getpracticequestionforcbt: function () {
      let Id = 1;
      let ExamCode = this.ExamCodeval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ExamCode,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getpracticequestionforcbt", userdata)
        .then((resp) => {
          if (resp != null) {
            console.log(resp);
            this.Question = resp;
            this.getpracticequestionforcbtbyid(1);
          }
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getpracticequestionforcbtbyid: function (Id) {
      this.ChkOptionA = false;
      this.ChkOptionB = false;
      this.ChkOptionC = false;
      this.ChkOptionD = false;
      let ExamCode = this.ExamCodeval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ExamCode,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getpracticequestionforcbtbyid", userdata)
        .then((resp) => {
          if (resp != null) {
            this.QuestionSelectedId = resp.Id;
            this.CurrentQuestionId = resp.QuestionId;
            this.CurrentQuestion = resp.Question;
            this.CurrentImage = resp.ImagePath;
            this.CurrentOptionA = resp.OptionA;
            this.CurrentOptionAId = resp.OptionAId;
            this.CurrentOptionB = resp.OptionB;
            this.CurrentOptionBId = resp.OptionBId;
            this.CurrentOptionC = resp.OptionC;
            this.CurrentOptionCId = resp.OptionCId;
            this.CurrentOptionD = resp.OptionD;
            this.CurrentOptionDId = resp.OptionDId;
            this.CurrentSNo = resp.SNo;
            this.SelectedAnswerId = resp.SelectedAnswer;
            if (this.SelectedAnswerId != null) {
              if (this.CurrentOptionAId == this.SelectedAnswerId) {
                this.ChkOptionA = true;
                this.ChkOptionB = false;
                this.ChkOptionC = false;
                this.ChkOptionD = false;
              }
              if (this.CurrentOptionBId == this.SelectedAnswerId) {
                this.ChkOptionA = false;
                this.ChkOptionB = true;
                this.ChkOptionC = false;
                this.ChkOptionD = false;
              }
              if (this.CurrentOptionCId == this.SelectedAnswerId) {
                this.ChkOptionA = false;
                this.ChkOptionB = false;
                this.ChkOptionC = true;
                this.ChkOptionD = false;
              }
              if (this.CurrentOptionDId == this.SelectedAnswerId) {
                this.ChkOptionA = false;
                this.ChkOptionB = false;
                this.ChkOptionC = false;
                this.ChkOptionD = true;
              }
            }
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitquestionansweredA: function () {
      let AnswerId = 0;
      if (this.ChkOptionA == true) {
        AnswerId = this.CurrentOptionAId;
        this.ChkOptionB = false;
        this.ChkOptionC = false;
        this.ChkOptionD = false;
      }
      let Id = this.QuestionSelectedId;
      let ExamCode = this.ExamCodeval;
      let QuestionId = this.CurrentQuestionId;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ExamCode,
        QuestionId,
        AnswerId,
        username,
        accesskey,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("submitquestionanswered", userdata)
        .then(() => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          let SNo = this.CurrentSNo;
          this.getpracticequestionforcbtbyid(SNo);
          this.onloader = "none";
        })
        .catch((err) => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitquestionansweredB: function () {
      let AnswerId = 0;
      if (this.ChkOptionB == true) {
        AnswerId = this.CurrentOptionBId;
        this.ChkOptionA = false;
        this.ChkOptionC = false;
        this.ChkOptionD = false;
      }
      let Id = this.QuestionSelectedId;
      let ExamCode = this.ExamCodeval;
      let QuestionId = this.CurrentQuestionId;
      let TimeSpent = this.ExamPeriodval - this.Timerval;
      if (TimeSpent < 1) {
        TimeSpent = 1;
      }
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ExamCode,
        QuestionId,
        AnswerId,
        username,
        accesskey,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("submitquestionanswered", userdata)
        .then(() => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          let SNo = this.CurrentSNo;
          this.getpracticequestionforcbtbyid(SNo);
          this.onloader = "none";
        })
        .catch((err) => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitquestionansweredC: function () {
      let AnswerId = 0;
      if (this.ChkOptionC == true) {
        AnswerId = this.CurrentOptionCId;
        this.ChkOptionB = false;
        this.ChkOptionA = false;
        this.ChkOptionD = false;
      }
      let Id = this.QuestionSelectedId;
      let ExamCode = this.ExamCodeval;
      let QuestionId = this.CurrentQuestionId;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ExamCode,
        QuestionId,
        AnswerId,
        username,
        accesskey,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("submitquestionanswered", userdata)
        .then(() => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          let SNo = this.CurrentSNo;
          this.getpracticequestionforcbtbyid(SNo);
          this.onloader = "none";
        })
        .catch((err) => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitquestionansweredD: function () {
      let AnswerId = 0;
      if (this.ChkOptionD == true) {
        AnswerId = this.CurrentOptionDId;
        this.ChkOptionB = false;
        this.ChkOptionC = false;
        this.ChkOptionA = false;
      }
      let Id = this.QuestionSelectedId;
      let ExamCode = this.ExamCodeval;
      let QuestionId = this.CurrentQuestionId;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ExamCode,
        QuestionId,
        AnswerId,
        username,
        accesskey,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("submitquestionanswered", userdata)
        .then(() => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          let SNo = this.CurrentSNo;
          this.getpracticequestionforcbtbyid(SNo);
          this.onloader = "none";
        })
        .catch((err) => {
          this.ChkOptionA = false;
          this.ChkOptionB = false;
          this.ChkOptionC = false;
          this.ChkOptionD = false;
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    formattime: function (time) {
      let hrs = ~~(time / 3600);
      let mins = ~~((time % 3600) / 60);
      let secs = ~~time % 60;
      let ret = "";
      if (hrs > 0) {
        ret += "" + hrs + " Hrs : " + (mins < 10 ? "0" : "");
      }
      ret += "" + mins + " Min : " + (secs < 10 ? "0" : "");
      ret += "" + secs + " Sec";
      return ret;
    },
    examtimer: function () {
      this.Timerval = this.ExamPeriodval;
      if (this.StartExam == false) {
        this.Timerval = 0;
      }
      var timer = null;
      clearInterval(timer);
      timer = setInterval(() => {
        if (this.Timerval > 0) {
          this.Timerval--;
          this.ExamTimeval = this.formattime(this.Timerval);
        } else {
          this.submitqtn();
          clearInterval(timer);
        }
      }, 1000);
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStudent == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getcbtpractice();
    }
  },
};
</script>

<style></style>
